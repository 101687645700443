import { Chains } from "src/config";
const TesAirdrop = require("./zkSyncAbis/TesAirdrop.json");
const TinyAirdrop = require("./zkSyncAbis/TinyAirdrop.json");

export const ZkSyncAbiConfig = {
  //空投领取
  TinyAirdrop: {
    abi: TinyAirdrop,
    address: {
      280: "0x47f9e9de86D49EE19f921a332bED69a39E69b140",
      324: "0x81785D37E02A1866704f60021558e0fE63d035C1",

      // 0x81785D37E02A1866704f60021558e0fE63d035C1
    },
  },
  //申购领取
  TesAirdrop: {
    abi: TesAirdrop,
    address: {
      280: "0x5EbcD3e5Ab7D4D59A21E611ca811679Ac103fE15",
      324: "0x01f12206706D6a93ae469fBfcEEE3C78e5A5dA08",
    },
  },
};

export const getZkSyncContractInfo = (
  chainId: Chains.zkSyncTestnet | Chains.zkSync
) => {
  return {
    TinyAirdrop: {
      abi: ZkSyncAbiConfig.TinyAirdrop.abi,
      address: ZkSyncAbiConfig.TinyAirdrop.address[chainId],
    },
    TesAirdrop: {
      abi: ZkSyncAbiConfig.TesAirdrop.abi,
      address: ZkSyncAbiConfig.TesAirdrop.address[chainId],
    },
  };
};
