import { BigNumber } from "ethers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useTiny from "src/hooks/useTiny";
import useTx from "src/hooks/useTx";
import { bn0 } from "src/utils/constant";
import { getDisplayBalance, tokenFloor } from "src/utils/str";
import useWhiteUser from "./useWhiteUser";
import useWhiteUserAmount from "./useWhiteUserAmount";

function useAirDrop() {
  const { account, tiny } = useTiny();
  const TinyAirdrop = tiny?.contracts.TinyAirdrop;
  const { handleTransactionReceipt, handleTxConfirm } = useTx();
  const { t } = useTranslation();
  const [userTeraAmountCanClaim, setUserTeraAmountCanClaim] =
    useState<BigNumber>(bn0);

  const whiteUser = useWhiteUser();
  const whiteUserAmount = useWhiteUserAmount();

  const { data: userAmounts, refetch: userAmountsRefetch } = useQuery<
    [BigNumber, BigNumber] | undefined
  >(
    ["AIRDROP_USER_Amounts", account],
    async ({ signal }) => {
      const userAmounts: [BigNumber, BigNumber] =
        await TinyAirdrop?.getAirdrops();
      return userAmounts;
    },
    {
      refetchInterval: 60_000,
      enabled: typeof account === "string",
    }
  );

  const handleCliamTera = useCallback(
    ({
      loadingStart,
      cb,
      loadingEnd,
    }: {
      cb?: () => void;
      loadingStart: () => void;
      loadingEnd: () => void;
    }) => {
      if (account) {
        handleTransactionReceipt(tiny?.claimAirdrop(), t("claim tera"))
          ?.then((tx) => {
            if (tx) {
              loadingStart();
              handleTxConfirm(tx.hash)
                .then(() => {
                  userAmountsRefetch();
                  cb && cb();
                })
                .finally(loadingEnd);
            }
          })
          .catch((err) => {});
      }
    },
    [
      account,
      handleTransactionReceipt,
      handleTxConfirm,
      userAmountsRefetch,
      t,
      tiny,
    ]
  );

  useEffect(() => {
    if (userAmounts && !userAmounts[0].isZero()) {
      setUserTeraAmountCanClaim(userAmounts[0].mul(4).div(10));
    }
  }, [userAmounts]);

  const userClaimed = useMemo(() => {
    if (whiteUser && userAmounts) {
      const canClaimTesAmount = userAmounts
        ? tokenFloor(Number(getDisplayBalance(userAmounts[0], 18)), 2)
        : 0;
      return canClaimTesAmount === 0;
    } else {
      return false;
    }
  }, [userAmounts, whiteUser]);

  console.log({
    whiteUserAmount,
    userClaimed,
    userAmounts,
    userTeraAmount: userAmounts
      ? tokenFloor(Number(getDisplayBalance(userAmounts[0], 18)), 2)
      : 0,
    userRuneAmount: userAmounts
      ? tokenFloor(Number(getDisplayBalance(userAmounts[1], 18)), 2)
      : 0,
    userTeraAmountCanClaim,
  });

  return {
    whiteUserAmount,
    userClaimed,
    handleCliamTera,
    userAmounts,
    userTeraAmount: userAmounts
      ? tokenFloor(Number(getDisplayBalance(userAmounts[0], 18)), 2)
      : 0,
    userRuneAmount: userAmounts
      ? tokenFloor(Number(getDisplayBalance(userAmounts[1], 18)), 2)
      : 0,
    userTeraAmountCanClaim,
    userClaimAmount: tokenFloor(
      Number(getDisplayBalance(userTeraAmountCanClaim, 18)),
      2
    ),
  };
}

export default useAirDrop;
