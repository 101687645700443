import { detect } from "detect-browser";
import { Chains } from "src/config";
import { getBackEndUrl, static_api_base } from "src/utils/constant";

const browser = detect();

export function requestBridge<T = any>(
  path: string,
  options?: RequestInit
): Promise<T> {
  const url = `https://test-bridge-view.tinyworlds.io/${path}`;
  return fetch(url, options).then((res) => res.json());
}

export function request<T = any>(
  path: string,
  chainId: Chains,
  options?: RequestInit
): Promise<T> {
  return fetch(getBackEndUrl(chainId) + path, options).then((res) =>
    res.json()
  );
}

export function fileFetch(
  path: string,
  chainId: Chains,
  options?: RequestInit
) {
  return fetch(getBackEndUrl(chainId) + static_api_base + path, options);
  // return fetch(`https://api.tinyworlds.io` + static_api_base + path, options);
}

export interface ErrorBody {
  account: string | null | undefined;
  error: string | null | undefined;
  type: "tx" | "components" | "api";
  [key: string]: string | number | null | undefined;
}
//account,error,'api',[apiAddress]

interface ErrorBodyEx extends ErrorBody {
  chainId?: Chains;
}

export function logErrorToService(body: ErrorBodyEx) {
  if (process.env.NODE_ENV !== "production") {
    return console.log("logErrorToService body:", body);
  }
  const account = window?.ethereum?.selectedAddress;
  const common: any = {
    pathname: window.location.pathname,
    os: browser?.os,
    browsername: browser?.name,
  };
  if (!body.account) {
    common.account = account;
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (body.chainId) {
    return request("/api/dev/error", body.chainId, {
      headers,
      body: JSON.stringify(Object.assign({}, body, common)),
      method: "POST",
    });
  }
}

export function getLoginMessage(account: string, chainId: Chains) {
  return fetch(
    getBackEndUrl(chainId) + `/api/login/message?address=${account}`
  ).then((res) => {
    if (res.status == 200) {
      return res.text();
    } else {
      console.error(res.statusText);
    }
  });
}
