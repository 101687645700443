import homeBg from "src/images/home-bg.png";
import homeBgMobile from "src/images/home-bg-m.png";
import logo from "src/images/logo.png";
import logoPre from "src/images/logo-pre.png";

import runePre from "src/images/Token/Rune-pre.png";
import rune from "src/images/Token/Rune.png";
import teraPre from "src/images/Token/TERA-pre.png";
import tera from "src/images/Token/TERA.png";
import runeSealed from "src/images/presale-market/rune-card.png";

import treeHomeMPre from "src/images/tree-home-m-pre.png";
import treeHomeM from "src/images/tree-home-m.png";
import treeHomePre from "src/images/tree-home-pre.png";
import treeHome from "src/images/tree-home.png";

import iconBack from "src/images/back.png";

const iconTera = "//images.tinyworlds.io/tokens/TES.png";

const iconTINC = "//images.tinyworlds.io/tokens/TINC.png";

export {
  homeBg,
  logo,
  logoPre,
  iconTera,
  homeBgMobile,
  iconTINC,
  runePre,
  rune,
  teraPre,
  tera,
  runeSealed,
  treeHomeMPre,
  treeHomeM,
  treeHomePre,
  treeHome,
  iconBack,
};
