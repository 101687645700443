import { useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  homeBg,
  homeBgMobile,
  teraPre,
  tera,
  treeHomeMPre,
  treeHomeM,
  treeHomePre,
  treeHome,
} from "./imgs";
import Logo from "./Logo";
import ActivityCountDown from "./components/ActivityCountDown";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { TinyImg } from "src/components/TinyComponents";
import ActivityBody from "./components/ActivityBody";
import useIsMobile from "src/hooks/useIsMobile";
import { ActivityKeys, ActivitySetting } from "./components/Activitys/config";
import Purchase from "./components/Activitys/Purchase";
import usePurchase from "./components/Activitys/Purchase/hooks/usePurchase";
import Airdrop from "./components/Activitys/Airdrop";
import { useTranslation } from "react-i18next";
import Stars from "src/components/SpineStars/Stars";
import Firefly1 from "src/components/SpineStars/Firefly1";
import Firefly2 from "src/components/SpineStars/Firefly2";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import HomeBack from "./components/HomeBack";
import { airdropDate } from "./components/Activitys/Airdrop/hooks/WhiteUser";

function Home() {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const [selectActivity, setSelectActivity] = useState<ActivityKeys>(
    ActivityKeys.Airdrop
  );

  const { buyEndTime, buyStartTime, activeEnd, claimOpenTime } = usePurchase();

  // console.log({
  //   buyEndTime,
  //   buyStartTime,
  //   activeEnd,
  //   claimOpenTime,
  // });

  const activeStart = buyStartTime
    ? Math.floor(Date.now() / 1000) > buyStartTime
    : false;

  const activitySetting: {
    [key in ActivityKeys]: ActivitySetting;
  } = useMemo(() => {
    return {
      // purchase: {
      //   key: ActivityKeys.Purchase,
      //   title: t("Subscription TES"),
      //   countDownTitle: t(
      //     // activeEnd ? "Claim Subscription Time" : "Remaining Subscription Time"
      //     activeStart
      //       ? activeEnd
      //         ? "Claim Subscription Time"
      //         : // "Remaining Subscription Time"
      //           "Remaining Subscription Time"
      //       : "Starts Subscription Time"
      //   ),
      //   // countDown: activeEnd ? claimOpenTime : buyEndTime,
      //   countDown: activeStart
      //     ? activeEnd
      //       ? claimOpenTime
      //       : // buyEndTime
      //         buyEndTime
      //     : buyStartTime,
      //   icon: tera,
      //   iconPre: teraPre,
      //   children: <Purchase></Purchase>,
      //   endDate: buyEndTime,
      //   startDate: buyStartTime,
      //   isZkSyncTime: true,
      //   // isZkSyncTime: false,
      // },
      airdrop: {
        key: ActivityKeys.Airdrop,
        title: t("TES Airdrop"),
        countDownTitle: t(
          Math.floor(Date.now() / 1000) > airdropDate
            ? // ? "Airdrop Claim"
              "Airdrop Eligibility Check"
            : "Airdrop Eligibility Check"
        ),
        countDown: airdropDate,
        icon: tera,
        iconPre: teraPre,
        children: <Airdrop></Airdrop>,
        endDate: undefined,
        startDate: airdropDate,
      },
    };
  }, [activeEnd, activeStart, buyEndTime, buyStartTime, claimOpenTime, t]);

  const { title, countDown, countDownTitle, icon, iconPre, isZkSyncTime } =
    useMemo(() => {
      return activitySetting[selectActivity];
    }, [activitySetting, selectActivity]);

  return (
    <StyledContainer>
      <Firefly1></Firefly1>
      <Firefly2></Firefly2>
      <Stars></Stars>
      <Logo />
      <HomeBack />

      <StyledImg
        src={isMobile ? treeHomeM : treeHome}
        preSrc={isMobile ? treeHomeMPre : treeHomePre}
      ></StyledImg>
      <StyledHomeContainer>
        <StyledContainerHeader>{title}</StyledContainerHeader>
        <StyledActivityCountDown>
          {icon && iconPre && (
            <StyledActivityImg src={icon} preSrc={iconPre}></StyledActivityImg>
          )}
          <StyledActivityTitle>{countDownTitle}</StyledActivityTitle>
          <ActivityCountDown
            isZkSyncTime={isZkSyncTime}
            countDown={countDown}
          ></ActivityCountDown>
        </StyledActivityCountDown>
        
        <StyledActivityContainer>
          <ActivityBody
            setSelectActivity={setSelectActivity}
            activitySetting={activitySetting}
          />
        </StyledActivityContainer>
      </StyledHomeContainer>
    </StyledContainer>
  );
}

export default Home;

const StyledActivityImg = styled(TinyImg)`
  height: 16.66vw;
  margin-top: 0.833vw;
  position: relative;

  img {
    height: 28.3vw;
    position: absolute;
    width: 28.3vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 40vw;

    img {
      top: 50%;
      width: 72vw;
      height: 72vw;
    }
  }
`;

const StyledActivityContainer = styled.div`
  margin-top: 3.54vw;
  width: 36.65vw;
  max-width: 36.65vw;
  min-width: 36.65vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
    min-width: calc(100% - 32px);
    margin: 16px auto 0;
  }
`;

const StyledActivityTitle = styled.span`
  ${FontSizeConfig.lx}
  color: #FFFFFF;
  margin-bottom: 0.625vw;
  letter-spacing: 1px;
  font-weight: bold;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin-bottom: 12px;
  }
`;

const StyledActivityCountDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin-top: 0;
  }
`;

const StyledContainerHeader = styled.div`
  ${FontSizeConfig.xl}
  color: #ffffff;
  text-shadow: 2px 3px 5px #000000;
  margin-top: 4.2vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    ${FontSizeConfig.x}
    margin-top: 7vh;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(${homeBg});
  background-size: 100%;
  background-repeat: no-repeat;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    background-image: url(${homeBgMobile});
    background-size: 250%;
    background-repeat: no-repeat;
    background-position: top;
    background-position-y: -30%;
  }
`;

const StyledHomeContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  flex-direction: column;
  z-index: 9;
`;

const StyledImg = styled(TinyImg)`
  width: 100%;
  z-index: 1;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    img {
      width: 120%;
      height: auto;
      position: absolute;
      left: 50%;
      top: 0%;
      transform: translate(-50%, 58%);
    }
  }
`;
