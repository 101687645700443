import React, {
  createContext,
  useEffect,
  useCallback,
  useState,
  PropsWithChildren,
} from "react";
import { ethers } from "ethers";
import styled from "styled-components";

import { useWeb3React } from "@web3-react/core";
import { useInitTiny } from "src/hooks/useTiny";

import Web3 from "web3";
import {
  ConnectorNames,
  defalutConnectors,
  getConnectorsByName,
} from "src/wallet";
import switchChain from "src/utils/switchChain";
import { Chains, Configuration, getChains } from "src/config";
import { TinyEx } from "src/tiny/zkSyncMethods";
import { noFunc } from "src/utils/constant";

export interface TinyContext {
  tiny: TinyEx | undefined;
  config: Configuration | undefined;
  logout: () => void;

  signedStr: string | null;

  account: string | null | undefined;
  isUnlocked: boolean | undefined;

  nowChainId: Chains | undefined;
  setChainId: React.Dispatch<React.SetStateAction<Chains | undefined>>;

  defaultProvider: ethers.providers.Web3Provider | undefined;

  web3: Web3 | undefined;
}

export const Context = createContext<TinyContext>({
  tiny: undefined,
  logout: noFunc,
  signedStr: null,
  nowChainId: undefined,
  setChainId: noFunc,
  config: undefined,
  isUnlocked: undefined,
  account: undefined,
  defaultProvider: undefined,
  web3: undefined,
});

export const TinyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { library, chainId, account, deactivate, connector, activate } =
    useWeb3React();
  const [loaded, setLoaded] = useState(false);

  const [nowChainId, setChainId] = useState<Chains | undefined>(chainId);

  useEffect(() => {
    if (chainId && !loaded && !nowChainId) {
      setChainId(chainId);
      setLoaded(true);
    }
  }, [chainId, loaded, nowChainId]);

  const { tiny, config, defaultProvider, isUnlocked, web3 } = useInitTiny(
    chainId,
    account || undefined
  );

  // const [tincBalance, freshTINCBalance] = useTokenBalance(TINC, account);
  // const [busdBalance, freshBUSDBalance] = useTokenBalance(BUSD, account);

  // const [bnbBalance, refreshBNBBalance] = useBNBBalance(account);

  // const startFreshTINCBalance = useLimitedInterval(freshTINCBalance);

  const [signedStr, setSignStr] = useState<string | null>(null);

  const [activatingConnector, setActivatingConnector] = useState<any>();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    const connectorId = localStorage.getItem("connectorId");
    const connectorsByName = getConnectorsByName();

    let currentConnector: any = null;
    let connectorDefalut: (typeof defalutConnectors)[number];

    if (connectorId && connectorId in ConnectorNames) {
      const connector = connectorsByName[connectorId as ConnectorNames];
      currentConnector = connector;
      if (connectorId && connectorId in defalutConnectors) {
        connectorDefalut = connectorId as (typeof defalutConnectors)[number];
      }
    } else if (window.ethereum) {
      currentConnector = connectorsByName[ConnectorNames.Injected];
      connectorDefalut = ConnectorNames.Injected;
    }

    if ((activatingConnector || currentConnector) && !account) {
      activate(currentConnector, (error) => {
        if (error) {
          if (
            error.message.includes("Unsupported chain id") &&
            connectorDefalut
          ) {
            switchChain(connectorDefalut, getChains('bsc'));
            setChainId(getChains('bsc'));
          }
          setActivatingConnector(undefined);
        } else {
          setActivatingConnector(currentConnector);
        }
      });
    }
  }, [account, activate, activatingConnector, chainId]);

  // useEffect(() => {
  //   if (!account && activatingConnector) {
  //     activate(activatingConnector, (error) => {});
  //   }
  // }, [account, activate, activatingConnector]);

  const logout = useCallback(() => {
    deactivate();
    tiny?.logout();
    localStorage.removeItem("connectorId");
  }, [deactivate, tiny]);

  return (
    <Context.Provider
      value={{
        tiny,
        logout,
        signedStr,
        nowChainId,
        setChainId,
        config,
        account: account || undefined,
        defaultProvider,
        isUnlocked,
        web3,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: 3rem;
  `};
`;

const StyledModalBackdrop = styled.div`
  background-color: #00000088;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;
