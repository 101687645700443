import { AbstractConnectorArguments, ConnectorUpdate } from "@web3-react/types";
import { AbstractConnector } from "@web3-react/abstract-connector";
import warning from "tiny-warning";

import { SendReturnResult, SendReturn, Send, SendOld } from "./types";

const __DEV__ = process.env.NODE_ENV !== "production";

function parseSendReturn(sendReturn: SendReturnResult | SendReturn): any {
  return sendReturn.hasOwnProperty("result") ? sendReturn.result : sendReturn;
}

type walletNames = "$onekey" | "trustwallet";

class NoEthereumProviderError extends Error {
  public constructor(symbol: walletNames) {
    super();
    this.name = this.constructor.name;
    this.message =
      "No Ethereum provider was found on window." + symbol + ".ethereum.";
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = "The user rejected the request.";
  }
}

export class InjectedCommonConnector extends AbstractConnector {
  symbol: walletNames;

  constructor(kwargs: AbstractConnectorArguments, symbol: walletNames) {
    super(kwargs);

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this);
    this.handleChainChanged = this.handleChainChanged.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.symbol = symbol;
  }

  private handleChainChanged(chainId: string | number): void {
    if (__DEV__) {
      console.log("Handling 'chainChanged' event with payload", chainId);
    }
    this.emitUpdate({ chainId, provider: window[this.symbol]?.ethereum });
  }

  private handleAccountsChanged(accounts: string[]): void {
    if (__DEV__) {
      console.log("Handling 'accountsChanged' event with payload", accounts);
    }
    if (accounts.length === 0) {
      this.emitDeactivate();
    } else {
      this.emitUpdate({ account: accounts[0] });
    }
  }

  private handleClose(code: number, reason: string): void {
    if (__DEV__) {
      console.log("Handling 'close' event with payload", code, reason);
    }
    this.emitDeactivate();
  }

  private handleNetworkChanged(networkId: string | number): void {
    if (__DEV__) {
      console.log("Handling 'networkChanged' event with payload", networkId);
    }
    this.emitUpdate({
      chainId: networkId,
      provider: window[this.symbol]?.ethereum,
    });
  }

  public async activate(): Promise<ConnectorUpdate> {
    const providerVar = window[this.symbol];

    if (!providerVar || !providerVar.ethereum) {
      throw new NoEthereumProviderError(this.symbol);
    }

    if (providerVar.ethereum.on) {
      providerVar.ethereum.on("chainChanged", this.handleChainChanged);
      providerVar.ethereum.on("accountsChanged", this.handleAccountsChanged);
      providerVar.ethereum.on("close", this.handleClose);
      providerVar.ethereum.on("networkChanged", this.handleNetworkChanged);
    }

    if ((providerVar.ethereum as any).isMetaMask) {
      (providerVar.ethereum as any).autoRefreshOnNetworkChange = false;
    }

    // try to activate + get account via eth_requestAccounts
    let account;
    try {
      account = await (providerVar.ethereum.send as Send)(
        "eth_requestAccounts"
      ).then((sendReturn) => parseSendReturn(sendReturn)[0]);
    } catch (error) {
      if ((error as any).code === 4001) {
        throw new UserRejectedRequestError();
      }
      warning(
        false,
        "eth_requestAccounts was unsuccessful, falling back to enable"
      );
    }

    // if unsuccessful, try enable
    if (!account) {
      // if enable is successful but doesn't return accounts, fall back to getAccount (not happy i have to do this...)
      account = await providerVar.ethereum
        .enable()
        .then((sendReturn) => sendReturn && parseSendReturn(sendReturn)[0]);
    }

    return {
      provider: providerVar.ethereum,
      ...(account ? { account } : {}),
    };
  }

  public async getProvider(): Promise<any> {
    return window[this.symbol]?.ethereum;
  }

  public async getChainId(): Promise<number | string> {
    const providerVar = window[this.symbol];

    if (!providerVar || !providerVar.ethereum) {
      throw new NoEthereumProviderError(this.symbol);
    }

    let chainId;
    try {
      chainId = await (providerVar.ethereum.send as Send)("eth_chainId").then(
        parseSendReturn
      );
    } catch {
      warning(
        false,
        "eth_chainId was unsuccessful, falling back to net_version"
      );
    }

    if (!chainId) {
      try {
        chainId = await (providerVar.ethereum.send as Send)("net_version").then(
          parseSendReturn
        );
      } catch {
        warning(
          false,
          "net_version was unsuccessful, falling back to net version v2"
        );
      }
    }

    if (!chainId) {
      try {
        chainId = parseSendReturn(
          (providerVar.ethereum.send as SendOld)({
            method: "net_version",
          })
        );
      } catch {
        warning(
          false,
          "net_version v2 was unsuccessful, falling back to manual matches and static properties"
        );
      }
    }

    if (!chainId) {
      if ((providerVar.ethereum as any).isDapper) {
        chainId = parseSendReturn(
          (providerVar.ethereum as any).cachedResults.net_version
        );
      } else {
        chainId =
          (providerVar.ethereum as any).chainId ||
          (providerVar.ethereum as any).netVersion ||
          (providerVar.ethereum as any).networkVersion ||
          (providerVar.ethereum as any)._chainId;
      }
    }

    return chainId;
  }

  public async getAccount(): Promise<null | string> {
    const providerVar = window[this.symbol];

    if (!providerVar || !providerVar.ethereum) {
      throw new NoEthereumProviderError(this.symbol);
    }

    let account;
    try {
      account = await (providerVar.ethereum.send as Send)("eth_accounts").then(
        (sendReturn) => parseSendReturn(sendReturn)[0]
      );
    } catch {
      warning(false, "eth_accounts was unsuccessful, falling back to enable");
    }

    if (!account) {
      try {
        account = await providerVar.ethereum
          .enable()
          .then((sendReturn) => parseSendReturn(sendReturn)[0]);
      } catch {
        warning(
          false,
          "enable was unsuccessful, falling back to eth_accounts v2"
        );
      }
    }

    if (!account) {
      account = parseSendReturn(
        (providerVar.ethereum.send as SendOld)({
          method: "eth_accounts",
        })
      )[0];
    }

    return account;
  }

  public deactivate() {
    const providerVar = window[this.symbol];

    if (
      providerVar &&
      providerVar.ethereum &&
      providerVar.ethereum.removeListener
    ) {
      providerVar.ethereum.removeListener(
        "chainChanged",
        this.handleChainChanged
      );
      providerVar.ethereum.removeListener(
        "accountsChanged",
        this.handleAccountsChanged
      );
      providerVar.ethereum.removeListener("close", this.handleClose);
      providerVar.ethereum.removeListener(
        "networkChanged",
        this.handleNetworkChanged
      );
    }
  }

  public async isAuthorized(): Promise<boolean> {
    const providerVar = window[this.symbol];

    if (!providerVar || !providerVar.ethereum) {
      return false;
    }

    try {
      return await (providerVar.ethereum.send as Send)("eth_accounts").then(
        (sendReturn) => {
          if (parseSendReturn(sendReturn).length > 0) {
            return true;
          } else {
            return false;
          }
        }
      );
    } catch {
      return false;
    }
  }
}
