import React, { useEffect, useState } from "react";
import { TinyButton, TinyImg } from "src/components/TinyComponents";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { styled } from "styled-components";
import TinyLoginButton from "../../TinyLoginButton";
import { iconTera } from "src/views/Home/imgs";
import useTiny from "src/hooks/useTiny";
import { Chains, getChains } from "src/config";
import useAirDrop from "./hooks/useAirDrop";
import { useTranslation } from "react-i18next";
import useWhiteUser from "./hooks/useWhiteUser";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import useActivityNotStart from "src/hooks/useActivityNotStart";
import { airdropDate } from "./hooks/WhiteUser";

function Claim() {
  const { chainId, account, logout, isMobile } = useTiny();
  const [claimedLoading, setClaimedLoading] = useState(false);

  const {
    handleCliamTera,
    userRuneAmount,
    whiteUserAmount: userClaimedAmount,
    userTeraAmount,
    userClaimAmount,
    userClaimed,
  } = useAirDrop();

  const { t } = useTranslation();

  const whiteUser = useWhiteUser();
  const activityNotStart = useActivityNotStart();

  return (
    <StyledContainer>
      <StyledContainerLeft>
        <StyledTokenBox>
          <StyledTokenImg src={iconTera} preSrc={iconTera}></StyledTokenImg>
          <StyledTokenDesc>
            <p>1,000,000 TES</p>
            <span>{t("50% of Initial Supply")}</span>
          </StyledTokenDesc>
        </StyledTokenBox>
      </StyledContainerLeft>
      <StyledContainerRight>
        {userClaimed ? (
          <>
            <StyledDetailsBox>
              <label>{t("You have claimed")}</label>
              <StyledClaimed>
                <img src={iconTera} alt="TES" />
                <span>{userClaimedAmount}</span>
              </StyledClaimed>
            </StyledDetailsBox>
          </>
        ) : (
          <>
            {account && account !== "" && chainId === getChains("zkSync") ? (
              whiteUser ? (
                <StyledDetailsBox>
                  <p>{t("You will receive")}</p>
                  <StyledBoxBottom>
                    <label>
                      <img src={iconTera} alt="TES" />
                      <span>{userTeraAmount}</span>
                    </label>
                    {!activityNotStart && (
                      <TinyButton
                        disabled={
                          userTeraAmount === 0 ||
                          !account ||
                          Math.floor(Date.now() / 1000) < airdropDate
                        }
                        type="primary"
                        loading={claimedLoading}
                        onClick={() => {
                          handleCliamTera({
                            loadingStart: () => {
                              setClaimedLoading(true);
                            },
                            cb: () => {},
                            loadingEnd: () => {
                              setClaimedLoading(false);
                            },
                          });
                        }}
                      >
                        {t("Claim")}
                      </TinyButton>
                    )}
                  </StyledBoxBottom>
                </StyledDetailsBox>
              ) : (
                <StyledDetailsBox>
                  <label>
                    {t("Sorry, looks like this wallet isn’t eligible.")}
                  </label>
                  <StyledButtons>
                    <TinyButton
                      style={{
                        minWidth: isMobile ? "8.5rem" : "7.08vw",
                      }}
                      onClick={logout}
                    >
                      {t("Back")}
                    </TinyButton>
                    <TinyButton
                      style={{
                        minWidth: isMobile ? "8.5rem" : "7.08vw",
                      }}
                      type="primary"
                      onClick={() => window.open("https://app.tinyworlds.io/")}
                    >
                      {t("See our Journey")}
                    </TinyButton>
                  </StyledButtons>
                </StyledDetailsBox>
              )
            ) : (
              <TinyLoginButton
                style={{
                  width: isMobile ? "100%" : "11.67vw",
                }}
                chaiId={getChains("zkSync")}
              />
            )}
          </>
        )}
      </StyledContainerRight>
    </StyledContainer>
  );
}

export default Claim;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.833vw;
  margin-top: 0.5vw;
  width: 100%;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    gap: 16px;
    margin-top: 10px;
  }
`;

const StyledClaimed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${FontSizeConfig.l}
  color: #FFFFFF;
  margin: 0 auto;

  img {
    display: block;
    height: 0.833vw;

    margin-right: 0.21vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    img {
      height: 16px;

      margin-right: 4px;
    }
  }
`;

const StyledBoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.42vw;

  p {
    color: #959595;
    ${FontSizeConfig.m}
    margin-bottom: 0.42vw;
  }

  label {
    padding: 0.42vw;
    background: #201f22;
    border-radius: 0.312vw;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      display: block;
      height: 0.833vw;

      margin-right: 0.21vw;
    }

    span {
      color: #ffffff;
      ${FontSizeConfig.l}
    }
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    gap: 8px;

    p {
      margin-bottom: 8px;
    }
    label {
      padding: 8px;
      border-radius: 6px;
      img {
        display: block;
        height: 16px;

        margin-right: 4px;
      }
    }
  }
`;

const StyledDetailsBox = styled.div`
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  p {
    padding-left: 0.42vw;
    ${FontSizeConfig.l}
  }

  label {
    color: #959595;
    ${FontSizeConfig.m}
    margin: 0 auto;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    p {
      padding-left: 8px;
    }
  }
`;

const StyledTokenDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    ${FontSizeConfig.lx}
    color: #FFFFFF;
    margin: 0;
    text-align: left;
  }

  span {
    ${FontSizeConfig.m}
    color: #959595;
    text-align: left;
  }
`;

const StyledTokenImg = styled(TinyImg)`
  padding: 0.312vw;
  border: 1px solid #9162af;
  background: #211c2b;
  border-radius: 0.312vw;

  img {
    width: 2.67vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding: 6px;
    border-radius: 6px;

    img {
      width: 51px;
    }
  }
`;

const StyledTokenBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.42vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    justify-content: flex-start;
    gap: 8px;
  }
`;

const StyledContainerRight = styled.div`
  flex: 1;
  background: #2c2b2e;
  border-radius: 0.312vw;
  padding: 0.833vw;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 100%;
    gap: 8px;
    background: none;
    padding-top: 0;
    padding: 0;
    border-radius: 6px;
  }
`;

const StyledContainerLeft = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  min-width: 10vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 100%;
    gap: 16px;
    padding: 0;
    border-radius: 6px;
    box-sizing: border-box;
    min-width: 12rem;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.833vw;
  height: 6.67vw;
  background: rgb(0 0 0 / 40%);
  padding: 0.833vw;
  border-radius: 0.312vw;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    flex-direction: column;
    height: auto;
    background: #2c2b2e;
    gap: 16px;
    border-radius: 6px;
    padding: 16px;
  }
`;
