import { Chains } from "src/config";

const multicallAbi = require("./bscAbis/multicall.json");
const BuyTera = require("./bscAbis/BuyTera.json");

// sousChef: {
//   56: "0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95",
//   97: "0xeBcc46C801284c92AF72afA7840296C022d78c11",
// },
export const BscAbiConfig = {
  BuyTera: {
    abi: BuyTera,
    address: {
      56: "0xd8F0c7ca7089832f5D24541823C09Dedbe9525dc",
      97: "0x6d5F719f2820C6eB3Ee7ef38062868e6215FDfcf",
    },
  },

  multicall: {
    abi: multicallAbi,
    address: {
      56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
      97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
    },
  },
};

export const getBscContractInfo = (chainId: Chains.bsc | Chains.bscTestnet) => {
  return {
    BuyTera: {
      abi: BscAbiConfig.BuyTera.abi,
      address: BscAbiConfig.BuyTera.address[chainId],
    },
    multicall: {
      abi: BscAbiConfig.multicall.abi,
      address: BscAbiConfig.multicall.address[chainId],
    },
  };
};
