import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";

import { shortenAddress } from "src/utils/index";
import { Chains } from "src/config";
import switchChain from "src/utils/switchChain";
import useModal from "src/hooks/useModal";
import WalletProviderModal from "src/components/WalletProviderModal";
import LogoutModal from "src/components/LogoutModal";
import useIsMobile from "src/hooks/useIsMobile";

import di3 from "src/images/di_3.png";

import Popover from "src/components/CommonPopover";
import WalletDetail from "src/components/LogoutModal/WalletDetail";
import DownIcon from "src/components/Svg/DownIcon";
import {
  ConnectorNames,
  getConnectorsByName,
  findConnectorId,
} from "src/wallet";
import useTiny from "src/hooks/useTiny";
import { TinyButton } from "src/components/TinyComponents";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface Props {
  selectChaiId: Chains | undefined;
  style?: CSSProperties;
  disabled?: boolean;
}

function WalletButton({
  selectChaiId,
  style,
  disabled: disabledConnect,
}: Props) {
  const { chainId, connector, activate } = useWeb3React();

  const {
    config,
    chainId: nowChainId,
    account,
    setChainId,
  } = useTiny();
  // const activating = currentConnector === activatingConnector;
  // console.log('chainId', chainId);

  const isNetworkCorrect = nowChainId === chainId;

  // 体力恢复道具价格
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [showPersonCenter, setShowPersonCenter] = useState(false);

  const [showLogout] = useModal(
    <LogoutModal setShowPersonCenter={setShowPersonCenter} />,
    [setShowPersonCenter]
  );

  const changeNetwork = useCallback(() => {
    const connectorId = findConnectorId({
      connector,
    });
    // console.log('switchChain connector:', connector);
    if (
      connectorId === ConnectorNames.Injected ||
      (connectorId === ConnectorNames.WalletLink && typeof chainId === "number")
    ) {
      // TODO 限制用户切换网络只能切换到 2个网络  Chains
      switchChain(connectorId, chainId as Chains);
    } else {
      showLogout();
    }
  }, [chainId, connector, showLogout]);

  const [showWalletList] = useModal(<WalletProviderModal />);

  const handlerConnect = useCallback(() => {
    if (selectChaiId && chainId) {
      if (selectChaiId === chainId) {
        showWalletList();
      } else {
        const connectorId = findConnectorId({
          connector,
        });
        if (
          connectorId === ConnectorNames.Injected ||
          connectorId === ConnectorNames.WalletLink
        ) {
          // TODO 限制用户切换网络只能切换到 2个网络  Chains
          switchChain(connectorId, selectChaiId, () => {
            setChainId(selectChaiId);
            showWalletList();
          });
        }
      }
    } else {
      showWalletList();
    }
  }, [
    chainId,
    connector,
    selectChaiId,
    setChainId,
    showWalletList,
  ]);

  return (
    <>
      {!account || (selectChaiId !== chainId && account) ? (
        <TinyButton
          disabled={disabledConnect}
          style={style}
          type={"primary"}
          onClick={handlerConnect}
        >
          {t("Connect")}
          {/* {status === "connecting" && "..."} */}
        </TinyButton>
      ) : isNetworkCorrect ? (
        isMobile ? (
          <>
            <StyledAccount
              onClick={() => {
                // setShowPersonCenter(true);
                showLogout();
              }}
            >
              {shortenAddress(account, 2)}
              <DownIcon
                isRotate={true}
                //  isRotate={showPersonCenter}
                className="ml-4"
                style={{ height: 8 }}
              />
            </StyledAccount>
          </>
        ) : (
          <StyledAccount>{shortenAddress(account)}</StyledAccount>
        )
      ) : (
        <TinyButton type={"primary"} onClick={changeNetwork}>
          {t("NetworkError")}
        </TinyButton>
      )}
    </>
  );
}

export default WalletButton;

const StyledBorderButton = styled.button`
  box-sizing: border-box;
  padding: 0.208vw 0.625vw 0.208vw 0.42vw;
  height: 2vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  font-weight: 600;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding: 4px;
    height: 2.4rem;
    border-radius: 1.2rem;
  }
`;

const StyledAccount = styled(StyledBorderButton)`
  padding: 0.2vw 0.833vw;
  background-color: #f18f24;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  white-space: nowrap;

  display: flex;
  align-items: center;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding: 0.25rem 1rem;
  }
`;
