const toastConfig = {
  style: {
    borderRadius: "10px",
    background: "#514B45",
    color: "#FDCE4F",
  },
};

/* 
              toast.error(t(`${res!.errorCode as string}`), {
                ...toastErrorConfig,
                id,
              });
*/

export default toastConfig;
