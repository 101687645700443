import React, { createRef, useEffect, useRef } from "react";
import * as PIXI from "pixi.js";
import "pixi-spine";
import { Spine } from "pixi-spine";
import { styled } from "styled-components";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

let divRef = createRef<HTMLDivElement>();

const PixiSpineComponent = () => {
  useEffect(() => {
    // 创建Pixi应用
    const app = new PIXI.Application({
      height: window.innerWidth / 1.78,
      width: window.innerWidth,
      transparent: true,
    });

    // 将Pixi应用添加到DOM中
    document.getElementById("app")?.appendChild(app.view);

    // 加载Spine动画资源
    app.loader
      .add("starts", "/pixi-spine/subscription/TERA_Subscription.json")
      .load((loader, res) => {
        // 创建Spine动画实例
        if (divRef.current && res.starts && res.starts.spineData) {
          const spineAnimation = new Spine(res.starts?.spineData);
          const width = app.screen.width / 2;
          const height = app.screen.height / 2;
          spineAnimation.x = width;
          spineAnimation.y = height * 1.2;
          spineAnimation.zIndex = 2;
          spineAnimation.scale.set(window.innerWidth / 1920);
          spineAnimation.state.setAnimation(2, "stars2", true);

          spineAnimation.skeleton.setSkinByName(`default`);

          app.stage.addChild(spineAnimation);
        }
      })
      .onComplete.add(() => {
        if (divRef.current?.innerHTML) {
          divRef.current.innerHTML = "";
        }
        divRef.current?.appendChild(app.view);
        app.start();
      });

    // 清理函数，在组件卸载时销毁Pixi应用
    return () => {
      app.destroy();
    };
  }, []);

  return <StyledSpineBox ref={divRef} />;
};

export default PixiSpineComponent;

const StyledSpineBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56.18vw;
  z-index: 0;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    display: none;
  }
`;
