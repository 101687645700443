import React from "react";

const isProduction = process.env.NODE_ENV === "production";

const testUrl = "";
// const testUrl =
//   "https://app-test.tinyworlds.io/cdn-cgi/image/width=660,height=330,onerror=redirect";

export interface Options {
  width?: number;
  w?: number;
  height?: number;
  h?: number;
  dpr?: number;
  fit?: "scale-down" | "contain" | "cover" | "crop";
  quality?: number; // 1-100
  format?: "auto" | "WebP" | "AVIF";
  onerror?: "redirect";
}

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  options: Options;
}

export function getStrFromOptions(options: Options) {
  const _options = Object.assign({ onerror: "redirect" }, options);
  const keys = Object.keys(_options);
  return keys
    .map((key) => {
      const value = _options[key as keyof Options];
      return key + "=" + value;
    })
    .join(",");
}

export default function ResizedImage(props: Props) {
  const { options, src, alt, ...rest } = props;
  const optionStr = getStrFromOptions(options);
  return (
    <img
      src={(isProduction ? `/cdn-cgi/image/${optionStr}` : testUrl) + props.src}
      alt={alt}
      {...rest}
    />
  );
}
