import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import clsx from "clsx";

import Modal, { ModalProps } from "../Modal";

import txLoadingPng from "src/images/tx/loading.png";
import confirmPng from "src/images/tx/confirm.png";
import errorPng from "src/images/tx/error.png";
import walkPng from "src/images/tx/walk.png";
import { TinyButton } from "../TinyComponents";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { useSpring } from "react-spring";
import "./index.css";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import useIsMobile from "src/hooks/useIsMobile";

export type txStatus = "pending" | "error" | "confirm" | "cancel" | "nodeError";

interface Props extends ModalProps {
  status?: txStatus;
  title?: string;
  message?: string;
}
export default function TxConfirmModal(props: Props) {
  const { onDismiss, status, title, message } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [obj, setObj] = useState({
    color: "#FFFFFF",
    imgTip: (
      <div>
        <WalkImg src={walkPng} alt="loading" />
        <StyledAnimationBox>
          <StyledAnimationDiv1></StyledAnimationDiv1>
          <StyledAnimationDiv2></StyledAnimationDiv2>
          <StyledAnimationDiv3></StyledAnimationDiv3>
        </StyledAnimationBox>
      </div>
    ),
    title: t("Confirm Transaction"),
    description1: t("Waiting for confirmation"),
  });

  useEffect(() => {
    switch (status) {
      case "error":
      case "nodeError":
        setObj((pre) => {
          return {
            ...pre,
            color: "#FFFFFF",
            imgTip: <img src={errorPng} alt="" />,
            description1: "",
            title: t("txFail"),
          };
        });
        // message ? message : pre.description1
        break;
      case "cancel":
        setObj((pre) => {
          return {
            ...pre,
            color: "#FFFFFF",
            imgTip: <img src={errorPng} alt="" />,
            description1: message ? message : t("Transaction rejected"),
          };
        });
        break;
      case "confirm":
        setObj((pre) => {
          return {
            ...pre,
            color: "#FFFFFF",
            imgTip: <img src={confirmPng} alt="" />,
            description1: t("Confirmed"),
          };
        });
        break;
      default:
        break;
    }
  }, [status, t, message]);

  return (
    <Modal
      contentClx="text-center"
      size="lite"
      // isConfirmModal={true}
      onDismiss={onDismiss}
      style={
        isMobile
          ? undefined
          : {
              maxWidth: "18vw",
            }
      }
      title={<StyledTitle color={obj.color}>{title || obj.title}</StyledTitle>}
    >
      <StyledContentBox>{obj.imgTip}</StyledContentBox>
      <StyledDescriptionBox
        className={clsx("special-ff mt-20", status !== "nodeError" && "rfs-13")}
      >
        {obj.description1}
      </StyledDescriptionBox>
      {status === "pending" ? (
        <StyledDescription>{t("ConfirmInWallet")}</StyledDescription>
      ) : (
        <div className="mb-20" />
      )}

      <div
        style={
          isMobile
            ? { margin: "16px auto 16px" }
            : { margin: "0.833vw auto 0.833vw" }
        }
        className={clsx(status === "pending" ? "hidden" : "")}
      >
        <TinyButton
          style={isMobile ? { minWidth: "120px" } : { minWidth: "6.25vw" }}
          type="primary"
          onClick={onDismiss}
        >
          {t("Close")}
        </TinyButton>
      </div>
    </Modal>
  );
}
const StyledAnimationBox = styled.div`
  margin: 0.833vw auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.42vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin: 16px auto;
    gap: 8px;
  }
`;

const StyledAnimationDiv = styled.div`
  background: #212022;
  width: 0.42vw;
  height: 0.42vw;
  border-radius: 0.21vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
`;

const StyledAnimationDiv1 = styled(StyledAnimationDiv)`
  animation: boxJump 3s infinite;
  animation-delay: 100ms;
`;
const StyledAnimationDiv2 = styled(StyledAnimationDiv)`
  animation: boxJump 3s infinite;
  animation-delay: 1100ms;
`;
const StyledAnimationDiv3 = styled(StyledAnimationDiv)`
  animation: boxJump 3s infinite;
  animation-delay: 2100ms;
`;

const StyledDescriptionBox = styled.div`
  margin: 0.42vw auto 0;
  color: #ffffff;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${FontSizeConfig.l}

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin: 8px auto 0;
  }
`;

const StyledTitle = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

const StyledContentBox = styled.div`
  position: relative;
  margin: 0.833vw auto 0;

  img {
    display: block;
    height: 5vw;
    width: auto;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    transform: scale(0.8);
    margin: 16px auto 0;

    img {
      display: block;
      height: 96px;
      width: auto;
    }
  }
`;

// Here we create a component that will rotate everything we pass in over two seconds
const RotateImg = styled.img`
  animation: rotate 2s linear infinite;
`;

const WalkImg = styled.img`
  left: 16%;
  top: 50%;
  width: auto;
  height: 5vw;
  position: relative;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 96px;
    width: auto;
  }
  /*  height={91} */
`;

const StyledDescription = styled.div`
  margin: 0 auto;
  color: #959595;
  ${FontSizeConfig.m}
`;
