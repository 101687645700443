import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Question,
  TinyCollapse,
} from "src/components/TinyComponents/TinyCollapse";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import { styled } from "styled-components";

function FAQ() {
  const { t } = useTranslation();
  const questions = useMemo((): Question[] => {
    return [
      {
        header: t("TES_AirDrop_FAQ_Q1"),
        context: t("TES_AirDrop_FAQ_A1"),
      },
      {
        header: t("TES_AirDrop_FAQ_Q2"),
        context: t("TES_AirDrop_FAQ_A2"),
      },
      {
        header: t("TES_AirDrop_FAQ_Q3"),
        context: t("TES_AirDrop_FAQ_A3"),
      },
      {
        header: t("TES_AirDrop_FAQ_Q4"),
        context: t("TES_AirDrop_FAQ_A4"),
      },
      {
        header: t("TES_AirDrop_FAQ_Q5"),
        context: (
          <StyledStarBox>
            {t("TES_AirDrop_FAQ_A5")}
            <ul>
              <li>{t("TES_AirDrop_FAQ_A5_star_1")}</li>
              <li>{t("TES_AirDrop_FAQ_A5_star_2")}</li>
            </ul>
          </StyledStarBox>
        ),
      },
      {
        header: t("TES_AirDrop_FAQ_Q6"),
        context: (
          <StyledLink
            href={t("$_a_TES_AirDrop_FAQ_A6") as string}
            target="_blank"
            rel="noreferrer"
          >
            {t("$_a_TES_AirDrop_FAQ_A6")}
          </StyledLink>
        ),
      },
    ];
  }, [t]);

  return (
    <StyledContainer>
      <StyledTitle>{t("TES_AirDrop_FAQ_title")}</StyledTitle>
      <TinyCollapse questions={questions}></TinyCollapse>
    </StyledContainer>
  );
}

export default FAQ;

const StyledLink = styled.a`
  display: block;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const StyledStarBox = styled.div`
  ul {
    padding: 0 0.833vw;
    margin-top: 0.42vw;
    margin-bottom: 0.42vw;
  }
  ul li {
    list-style-type: "* ";
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    ul {
      padding: 0 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

const StyledTitle = styled.div`
  ${FontSizeConfig.lx}
  font-weight: bold;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding-left: 10px;
  }
`;

const StyledContainer = styled.div`
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.667vw;
  width: 100%;
  padding: 0.833vw;
  box-sizing: border-box;

  padding-bottom: 4.165vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding: 16px 0 80px;
    gap: 12px;
  }
`;
