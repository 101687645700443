import React from "react";
import styled from "styled-components";
import { useActivePopups } from "../../state/application/hooks";
import PopupItem from "./PopupItem";
import { AutoColumn } from "./Column";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

const MobilePopupWrapper = styled.div<{ height: string | number }>`
  position: fixed;
  top: 2.5vw;
  right: 0.833vw;
  max-width: 100%;
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? "0.42vw auto 0;" : 0)};
  margin-bottom: ${({ height }) => (height ? "1.04vw" : 0)};
  z-index: 100;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    top: 48px;
    right: 16px;
    margin: ${({ height }) => (height ? "8px auto 0;" : 0)};
    margin-bottom: ${({ height }) => (height ? "20px" : 0)};
  }
`;

const MobilePopupInner = styled.div`
  height: 99%;
  /* overflow-x: auto;
  overflow-y: hidden; */
  display: flex;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FixedPopupColumn = styled(AutoColumn)`
  position: fixed;
  top: 3.328vw;
  margin-right: 1.25vw;
  right: 2.5vw;
  max-width: 18.46vw !important;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    top: 64px;
    margin-right: 24px;
    right: 3rem;
    max-width: 355px !important;
  }
`;

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups();

  return (
    <>
      <FixedPopupColumn gap="20px">
        {activePopups.map((item) => (
          <PopupItem
            key={item.key}
            content={item.content}
            popKey={item.key}
            removeAfterMs={item.removeAfterMs}
          />
        ))}
      </FixedPopupColumn>
      <MobilePopupWrapper height={activePopups?.length > 0 ? "fit-content" : 0}>
        <MobilePopupInner>
          {activePopups // reverse so new items up front
            .slice(0)
            .reverse()
            .map((item) => (
              <PopupItem
                key={item.key}
                content={item.content}
                popKey={item.key}
                removeAfterMs={item.removeAfterMs}
              />
            ))}
        </MobilePopupInner>
      </MobilePopupWrapper>
    </>
  );
}
