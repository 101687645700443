import React, { CSSProperties, PropsWithChildren } from "react";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import styled from "styled-components";

const ModalActions: React.FC<PropsWithChildren<{ style?: CSSProperties }>> = ({
  children,
  style,
}) => {
  const l = React.Children.toArray(children).length;
  return (
    <StyledModalActions style={style}>
      {React.Children.map(children, (child, i) => (
        <>
          <StyledModalAction>{child}</StyledModalAction>
          {i < l - 1 && <StyledSpacer />}
        </>
      ))}
    </StyledModalActions>
  );
};

const StyledModalActions = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 5vw;
  text-align: center;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 96px;
  }
`;

const StyledModalAction = styled.div`
  /* flex: 1; */
`;

const StyledSpacer = styled.div`
  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
  }
`;

export default ModalActions;
