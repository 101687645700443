import { MEDIA_WIDTHS } from "./styledConfig";

export const FontSizeConfig = {
  /**
   * @param 0.75rem 8px zoom 0.75
   * **/
  s: `
    // font-size: 0.75rem;
    // font-size: 12px;
    font-size: 0.625vw;
    zoom: 0.75;

    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 12px;
    }
    `,
  /**
   * @param 0.75rem 12px
   * **/
  m: `
    // font-size: 0.75rem;
    // font-size: 12px;
    font-size: 0.625vw;

    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 12px;
    }
    `,
  /**
   * @param 0.875rem 14px
   * **/
  l: `
    // font-size: 0.875rem;
    // font-size: 14px;
    font-size: 0.73vw;

    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 14px;
    }
    `,
  /**
   * @param 1.125rem 18px
   * **/
  lx: `
    // font-size: 1.125rem;
    // font-size: 18px;
    font-size: 0.94vw;

    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 16px;
    }
  `,
  /**
   * @param 1.75rem 28px
   * **/
  x: `
    // font-size: 1.75rem;
    // font-size: 28px;
    font-size: 1.46vw;

    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 24px;
    }
  `,
  /**
   * @param 3.4375rem 55px
   * **/
  xl: `
    // font-size: 3.4375rem;
    // font-size: 55px;
    font-size: 2.86vw;
    
    @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
      font-size: 55px;
    }
  `,
};
