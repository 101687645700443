import { useMemo } from "react";
import useTiny from "./useTiny";
import { useQuery } from "react-query";
import { BigNumber, ethers } from "ethers";
import { getMulticallContract } from "src/tiny/utils";
import { Chains, externalTokenMap, providerMap } from "src/config";
import { testRPC } from "src/utils/provider";
import ERC20 from "src/tiny/ERC20";
import { getLayerMaps, useNowLayer } from "src/components/Layer/useLayer";

// TODO 修改成 一次发出多个合约请求
function useUserWallet() {
  const { tiny, defaultProvider, account, chainId } = useTiny();
  const privider = defaultProvider;

  const TINC = tiny?.externalTokens?.TINC;
  const brigeTINC = tiny?.externalTokens?.brigeTINC;
  const BUSD = tiny?.externalTokens.BUSD;

  const runeContract = tiny?.contracts?.TinyNFTRune;
  const runev2Contract = tiny?.contracts?.TinyRuneNFTV2;
  const multicallContract = getMulticallContract(tiny);

  // const [updateTinc, setUpdateTinc] = useState<BigNumber>();
  // const [updateBusd, setUpdateBusd] = useState<BigNumber>();
  // const [updateSpirit, setUpdateSpirit] = useState<BigNumber>();
  // const [updateBspirit, setUpdateBspirit] = useState<BigNumber>();

  const { data, refetch } = useQuery(
    ["USER_WALLET", { chainId }],
    async () => {
      try {
        if (account) {
          let tincBalance = await TINC?.balanceOf(account);
          let busdBalance = await BUSD?.balanceOf(account);
          let brigeTINCBalance = await brigeTINC?.balanceOf(account);

          // let spiritBalance: BigNumber = await runeContract.balanceOf(
          //   account,
          //   2
          // );
          // let lockSpiritBalance: BigNumber = await runeContract.balanceOf(
          //   account,
          //   3
          // );
          let bnbBalance = await privider?.getBalance(account);

          const calldata = [
            [
              runeContract?.address, // 要查询的合约的地址
              runeContract?.interface.encodeFunctionData("balanceOf", [
                account,
                1,
              ]),
            ],
            [
              runeContract?.address, // 要查询的合约的地址
              runeContract?.interface.encodeFunctionData("balanceOf", [
                account,
                2,
              ]),
            ],
            [
              runeContract?.address, // 要查询的合约的地址
              runeContract?.interface.encodeFunctionData("balanceOf", [
                account,
                3,
              ]),
            ],
            [
              runev2Contract?.address, // 要查询的合约的地址
              runev2Contract?.interface.encodeFunctionData("balanceOf", [
                account,
              ]),
            ],
          ];

          const calldataCheck = calldata.map((ele) => {
            return ele.find((arg) => !arg);
          });

          const { returnData }: { returnData: string[] | undefined } =
            calldataCheck.find((ele) => !ele)
              ? await multicallContract?.aggregate(calldata)
              : { returnData: undefined };

          const [redeemedRuneNum, spiritBalance, lockSpiritBalance, runev2Num] =
            returnData
              ? returnData.map((ele) => BigNumber.from(ele))
              : [undefined, undefined, undefined, undefined];

          return {
            tincBalance,
            brigeTINCBalance,
            busdBalance,
            spiritBalance,
            lockSpiritBalance,
            bnbBalance,
            runeBalance:
              redeemedRuneNum && runev2Num && redeemedRuneNum.add(runev2Num),
          };
        }
      } catch (error) {
        console.error({
          error,
        });
      }
    },
    {
      refetchInterval: 30_000,
      enabled: typeof account === "string",
    }
  );

  return {
    ...data,
    refetch,
  };
}

export const useOtherLayerTincAmount = () => {
  const { account, chainId, gameSymbol } = useTiny();

  const nowLayer = useNowLayer();
  const toBridgeLayer = useMemo(() => {
    return (
      chainId &&
      getLayerMaps(chainId).find((layer) => {
        return layer.chain !== nowLayer.chain;
      })
    );
  }, [chainId, nowLayer.chain]);

  return useQuery(
    [
      "OTHER_TINC_AMOUNT",
      {
        account,
        toBridgeLayer,
      },
    ],
    async () => {
      try {
        if (account) {
          const chainId = toBridgeLayer?.chain as Chains;

          const provider = await testRPC(providerMap[chainId])

          const defaultProvider = new ethers.providers.Web3Provider(
            provider as any,
            chainId
          );

          const TINCERC20 =
            chainId === 56 || chainId === 97
              ? new ERC20(
                  externalTokenMap[chainId].TINC,
                  defaultProvider,
                  gameSymbol,
                  18
                )
              : undefined;

          if (TINCERC20 && account) {
            return {
              tincBalance: await TINCERC20.balanceOf(account),
            };
          }
        }
      } catch (error) {}
    },
    {
      refetchInterval: 30_000,
      enabled: typeof account === "string",
    }
  );
};

export default useUserWallet;
