import { TabsProps } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import Purchase from "./Activitys/Purchase";
import { TinyTabs } from "src/components/TinyComponents";
import { ActivityKeys, ActivitySetting } from "./Activitys/config";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { useTranslation } from "react-i18next";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface Props {
  setSelectActivity: React.Dispatch<React.SetStateAction<ActivityKeys>>;
  activitySetting: { [key in ActivityKeys]: ActivitySetting };
}

const activityTimes = ["Not Started", "In Progress", "Ended"];

function ActivityBody({ setSelectActivity, activitySetting }: Props) {
  const { t } = useTranslation();

  const activityItems: TabsProps["items"] = useMemo(() => {
    // const date = Math.floor(Date.now() / 1000);
    const date = Math.floor(Date.now() / 1000);

    return Object.keys(activitySetting).map((ele) => {
      const objKey = ele as keyof typeof activitySetting;
      const { key, title, children, startDate, endDate } =
        activitySetting[objKey];

      let activityTime = 0;
      if (startDate) {
        if (date < startDate) {
          activityTime = 0;
        } else {
          if (endDate) {
            if (date > endDate) {
              activityTime = 2;
            } else {
              activityTime = 1;
            }
          } else {
            activityTime = 1;
          }
        }
      } else {
        activityTime = 0;
      }
      // startDate
      //   ? date < startDate
      //     ? activityTimes[0]
      //     : activityTimes[1]
      //   : activityTimes[0];

      return {
        key,
        label: (
          <StyledTabItem>
            <span>{title}</span>
            <label>{t(`${activityTimes[activityTime]}`)}</label>
          </StyledTabItem>
        ),
        children,
      };
    });
  }, [activitySetting, t]);

  return (
    <StyledContainer>
      <TinyTabs
        defaultActiveKey="1"
        onChange={(value) => {
          setSelectActivity(value as ActivityKeys);
        }}
        items={activityItems}
      ></TinyTabs>
    </StyledContainer>
  );
}

export default ActivityBody;

const StyledTabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    /* label {
      display: none;
    } */
  }
`;

const StyledContainer = styled.div``;
