import metaMask from "src/images/connect/new/Metamask.png";
import tokenpocket from "src/images/connect/new/TokenPocket.png";
import walletConnect from "src/images/connect/new/WalletConnect.png";
import trustWallet from "src/images/connect/new/TrustWallet.png";
import binanceWallet from "src/images/connect/new/BinanceWallet.png";
import coinbaseWallet from "src/images/connect/new/CoinbaseWallet.png";
import mathWallet from "src/images/connect/new/MathWallet.png";
import wombat from "src/images/connect/new/Wombat.png";
// import bitkeep from "src/images/connect/new/BitKeepWallet.png";
import braveLion from "src/images/connect/new/BraveWallet.png";
import OneKey from "src/images/connect/new/OnekeyWallet.png";
import OKX from "src/images/connect/new/okx_wallet.png";
import { ConnectorNames } from "src/wallet";
import MoreIcon from "../Svg/WalletSvg/MoreIcon";

export interface IWalletCard {
  src: string | JSX.Element;
  alt: string;
  connector: ConnectorNames;
}

export const moreCard: IWalletCard = {
  src: <MoreIcon />,
  alt: "MORE",
  connector: ConnectorNames.Injected,
};

export const WalletCardList: IWalletCard[] = [
  {
    src: metaMask,
    alt: "Metamask",
    connector: ConnectorNames.Injected,
  },
  {
    src: tokenpocket,
    alt: "TokenPocket",
    connector: ConnectorNames.Injected,
  },
  {
    src: binanceWallet,
    alt: "Binance Wallet",
    connector: ConnectorNames.bsc,
  },
  // {
  //   src: bitkeep,
  //   alt: "BitKeep Wallet",
  //   connector: ConnectorNames.BitKeep,
  // },
  {
    src: wombat,
    alt: "Wombat",
    connector: ConnectorNames.Injected,
  },
  {
    src: OKX,
    alt: "OKX Wallet",
    connector: ConnectorNames.okxwallet,
  },
  {
    src: braveLion,
    alt: "Brave Wallet",
    connector: ConnectorNames.Injected,
  },
  {
    src: OneKey,
    alt: "Onekey Wallet",
    connector: ConnectorNames.$onekey,
  },
  {
    src: walletConnect,
    alt: "Wallet Connect",
    connector: ConnectorNames.WalletConnect,
  },
  {
    src: trustWallet,
    alt: "Trust Wallet",
    connector: ConnectorNames.TrustWallet,
  },
  {
    src: coinbaseWallet,
    alt: "Coinbase Wallet",
    connector: ConnectorNames.WalletLink,
  },
  {
    src: mathWallet,
    alt: "Math Wallet",
    connector: ConnectorNames.Injected,
  },
];
