// shorten the checksummed version of the input address to have 0x + 4 characters at start and end

import { BigNumber, utils } from "ethers";

export function shortenAddress(
  address: string,
  startChars = 4,
  endChars = 4
): string {
  const parsed = utils.isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${address.substring(0, startChars + 2)}...${address.substring(
    42 - endChars
  )}`;
}

/**
 * @param {BigNumber} balance
 * @param {number} [decimals]
 * @returns
 */
export function getBalance(balance: BigNumber, decimals = 18): string {
  return balance.div(BigNumber.from(10).pow(decimals)).toString();
}

export const getDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 3,
  locale = false
) => {
  const number = getBalance(balance, decimals - fractionDigits);
  if (locale) {
    return (Number(number) / 10 ** fractionDigits).toLocaleString(undefined, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }
  return (Number(number) / 10 ** fractionDigits).toFixed(fractionDigits);
};

export const tokenFloor = (value: number, decimal = 3) => {
  const decimals = Math.pow(10, decimal);
  return Math.floor(value * decimals) / decimals;
};

export function getErrorMsgKey(message: string) {
  if (message.includes("transfer amount exceeds balance")) {
    return "error.Insufficient Balance";
  }
  if (message.includes("network error")) {
    return "NetworkError";
  }
  if (message.includes("defending")) {
    return "AttackFailureWarning";
  }
  return undefined;
}
