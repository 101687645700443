import clsx from "clsx";
import { CSSProperties, PropsWithChildren } from "react";

export default function SpecialFontText(
  props: PropsWithChildren<{ className?: string; style?: CSSProperties }>
) {
  const { className, children, style } = props;
  return (
    <span style={style} className={clsx(className, "special-ff")}>
      {children}
    </span>
  );
}
