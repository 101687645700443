import React from "react";
import useTiny from "src/hooks/useTiny";
import { whileUsers } from "./WhiteUser";

// const whileUsers = [
//   "0x19dBf7B58674594b7A0043bBDcED6E7C4fC59818",
// ] as const;

function useWhiteUser() {
  const { account } = useTiny();
  const whileUsersArr = Object.keys(whileUsers).map((key) => (key as string).toLowerCase());
  return !!(account && whileUsersArr.map(String).includes(account.toLowerCase()));
}

export default useWhiteUser;
