import useIsMobile from "src/hooks/useIsMobile";
import styled from "styled-components";
import Modal, { ModalProps } from "../Modal";
import WalletDetail from "./WalletDetail";
import React from "react";

interface Props extends ModalProps {
  setShowPersonCenter: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LogoutModal({ onDismiss, setShowPersonCenter }: Props) {
  const isMobile = useIsMobile();

  const handlerDismiss = () => {
    setShowPersonCenter(false);
    onDismiss && onDismiss();
  };

  return (
    <Modal
      size="sm"
      bgType={2}
      style={{
        ...(isMobile ? { maxWidth: 340 } : { maxWidth: '20.8vw' }),
      }}
      onDismiss={handlerDismiss}
    >
      <StyledContainer>
        <WalletDetail cb={handlerDismiss}></WalletDetail>
      </StyledContainer>
    </Modal>
  );
}

const StyledContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;
