import React from "react";
import { BaseSvgProps } from "./types";

interface Props extends BaseSvgProps {
  isRotate: boolean;
}

function DownIcon({ className, style, color, isRotate, onClick }: Props) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 8"
      className={className}
      style={{
        ...style,
        ...{ transform: isRotate ? "rotate(0deg)" : "rotate(180deg)" },
      }}
      fill={color ? color : "#fff"}
    >
      {/* <polygon points="14.79 16.45 28.59 1 1 1 14.79 16.45" /> */}
      <path
        d="M7,0,9.9,3.317,14,8H0Z"
        transform="translate(14 8) rotate(180)"
      />
    </svg>
  );
}

export default DownIcon;
