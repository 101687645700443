import React, { createRef, useEffect, useRef } from "react";
import * as PIXI from "pixi.js";
import "pixi-spine";
import { Spine } from "pixi-spine";
import { styled } from "styled-components";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

let divRef = createRef<HTMLDivElement>();

const PixiSpineComponent = () => {
  useEffect(() => {
    // 创建Pixi应用
    const app = new PIXI.Application({
      height: window.innerWidth / 1.78 / 2,
      width: window.innerWidth / 2,
      transparent: true,
    });

    // 将Pixi应用添加到DOM中
    document.getElementById("app")?.appendChild(app.view);

    // 加载Spine动画资源
    app.loader
      .add("starts", "/pixi-spine/subscription/TERA_Subscription.json")
      .load((loader, res) => {
        // 创建Spine动画实例
        if (divRef.current && res.starts && res.starts.spineData) {
          const width = app.screen.width / 2;
          const height = app.screen.height / 2;

          const spineAnimation2 = new Spine(res.starts?.spineData);
          spineAnimation2.x = width * 0.1;
          spineAnimation2.y = height * 1.8;
          spineAnimation2.zIndex = 2;
          spineAnimation2.scale.set(window.innerWidth / 1920);
          spineAnimation2.state.setAnimation(2, "stars1", true);
          spineAnimation2.skeleton.setSkinByName(`default`);

          app.stage.addChild(spineAnimation2);
        }
      })
      .onComplete.add(() => {
        if (divRef.current?.innerHTML) {
          divRef.current.innerHTML = "";
        }
        divRef.current?.appendChild(app.view);
        app.start();
      });

    // 清理函数，在组件卸载时销毁Pixi应用
    return () => {
      app.destroy();
    };
  }, []);

  return <StyledSpineBox ref={divRef} />;
};

export default PixiSpineComponent;

const StyledSpineBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50vw;
  height: 28.09vw;
  z-index: 2;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    display: none;
  }
`;
