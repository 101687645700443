import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNowLayer } from "src/components/Layer/useLayer";
import { TINY_FARM_HOME_URL } from "src/config";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import useTiny from "src/hooks/useTiny";
import { iconBack } from "src/views/Home/imgs";
import { styled } from "styled-components";

function HomeBack() {
  const { t } = useTranslation();

  const layer = useNowLayer();
  const { showAccount } = useTiny();

  const showHomeInfo = useMemo(() => {
    return layer.chain && showAccount;
  }, [layer.chain, showAccount]);

  return showHomeInfo ? (
    <StyledContainer
    // onClick={() => {
    //   window.location.replace(TINY_FARM_HOME_URL);
    // }}
    >
      <StyledLayerInfo>
        <img src={layer.icon} alt="back" />
        {/* <span>{t("Back")}</span> */}
        <span>{layer.text}</span>
      </StyledLayerInfo>

      <label>{showAccount}</label>
    </StyledContainer>
  ) : (
    <></>
  );
}

export default HomeBack;

const StyledLayerInfo = styled.div`
  display: flex;
  img {
    display: block;
    width: 1vw;
  }

  span {
    ${FontSizeConfig.l}
    color: #FFFFFF;
    margin-left: 0.208vw;
    font-family: EuclidSquare;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    img {
      display: block;
      width: 16px;
    }
  }
`;

const StyledContainer = styled.div`
  position: absolute;
  z-index: 10;
  right: 1.67vw;
  top: 1.67vw;
  display: flex;
  align-items: center;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  label {
    color: #959595;
    ${FontSizeConfig.m}
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    right: 16px;
    top: 16px;
  }
`;
