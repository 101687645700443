import React from "react";
import { styled } from "styled-components";
import { logo, logoPre } from "./imgs";
import { TinyImg } from "src/components/TinyComponents";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

function Logo() {
  return <StyledImg src={logo} preSrc={logoPre}></StyledImg>;
}

export default Logo;

const StyledImg = styled(TinyImg)`
  position: absolute;
  height: 5vw;
  width: 8.4vw;
  top: 1.67vw;
  left: 1.67vw;
  z-index: 9;

  img {
    height: 100%;
    width: auto;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    /* display: none; */
    height: 40px;
    width: 67px;
    top: 8px;
    left: 16px;
  }
`;
