import { UseTranslationResponse } from "react-i18next";

export function toDDHHMM({
  secs,
  t,
  isArr,
}: {
  secs: number | undefined;
  t?: UseTranslationResponse<any>["t"];
  isArr?: boolean;
}) {
  if (typeof secs === "number") {
    const calcSecs = secs + 59;
    var days = parseInt((Math.floor(calcSecs / 3600) / 24).toString());
    var hours = Math.floor(calcSecs / 3600) % 24;
    var minutes = Math.floor(calcSecs / 60) % 60;

    // var seconds =  Math.floor(secs / 3) % 3;

    // const renderData = [days, hours, seconds].map((v, index) => {
    const renderData = [days, hours, minutes].map((v, index) => {
      return (v < 10 ? "0" + v : v).toString() + (isArr ? "" : "");
    });

    return isArr ? renderData.join("").split("") : renderData.join(" ");
  } else {
    return isArr ? ["0", "0", "0", "0", "0", "0"] : t ? `` : `--d --h --m`;
  }
}
