// import { AuthereumConnector } from '@web3-react/authereum-connector'
// import { FortmaticConnector } from '@web3-react/fortmatic-connector'
// import { FrameConnector } from '@web3-react/frame-connector'
import { InjectedConnector } from "@web3-react/injected-connector";
// import { LatticeConnector } from '@web3-react/lattice-connector'
// import { LedgerConnector } from '@web3-react/ledger-connector'
// import { MagicConnector } from '@web3-react/magic-connector'
// import { NetworkConnector } from '@web3-react/network-connector'
// import { PortisConnector } from '@web3-react/portis-connector'
// import { TorusConnector } from '@web3-react/torus-connector'
// import { TrezorConnector } from '@web3-react/trezor-connector'
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { BitKeepConnector } from "bitKeep-connector";
import { InjectedCommonConnector } from "./injected-connector-base";
import { InjectedReplaceConnector } from "./injected-connector-replace";
import { Chains, Configuration, providerMap } from "src/config";
import { Chain } from "src/config";

// const POLLING_INTERVAL = 12000
// const RPC_URLS: { [chainId: number]: string } = {
//   1: process.env.RPC_URL_1 as string,
//   4: process.env.RPC_URL_4 as string
// }

export const getData = () => {
  const chains = Object.values(Chains)
    .map((ele) => {
      return ele;
    })
    .filter((ele) => {
      return typeof ele === "number";
    }) as Chains[];

  const injected = new InjectedConnector({
    supportedChainIds: chains,
  });

  const bsc = new BscConnector({
    supportedChainIds: chains,
  });

  const bitKeep = new BitKeepConnector({
    supportedChainIds: chains,
  });

  const onekey = new InjectedCommonConnector(
    {
      supportedChainIds: chains,
    },
    "$onekey"
  );

  const trustwallet = new InjectedCommonConnector(
    {
      supportedChainIds: chains,
    },
    "trustwallet"
  );

  const okxwallet = new InjectedReplaceConnector(
    {
      supportedChainIds: chains,
    },
    "okxwallet"
  );

  // const network = new NetworkConnector({
  //   urls: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
  //   defaultChainId: 1,
  // });

  const walletconnect = new WalletConnectConnector({
    rpc: {
      56: providerMap[56][0],
      97: providerMap[97][0],
    },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
  });

  const walletlink = new WalletLinkConnector({
    url: providerMap[56][0],
    appName: "Tiny Farm",
    supportedChainIds: chains,
  });

  return {
    injected,
    bsc,
    bitKeep,
    onekey,
    trustwallet,
    okxwallet,
    walletconnect,
    walletlink,
  };
};
