import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import useRouter from "./router/useRouter";
import TinyProvider from "./contexts/TinyProvider";
import { Web3ReactProvider } from "@web3-react/core";
import ModalsProvider from "./contexts/Modals";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./state";
import Updaters from "./state/Updaters";
import { useTranslation } from "react-i18next";
import useUrlState from "@ahooksjs/use-url-state";
import Popups from "./components/Popups";
import HomeLoading from "./views/components/HomeLoading";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

const configs: string[] = ["en", "zh"];

function App() {
  const routes = useRouter();
  const { i18n } = useTranslation();

  const [search, setSearch] = useUrlState<{ lang: string | undefined }>({
    lang: undefined,
  });

  useEffect(() => {
    const { lang } = search;

    if (i18n.language !== lang && configs.includes(lang)) {
      i18n.changeLanguage(lang);
    }
    // if(i18n.isInitialized){}
  }, [i18n, i18n.language, search]);

  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={(ethereum) => ethereum}>
        <QueryClientProvider client={queryClient}>
          <TinyProvider>
            <Toaster />
            <HomeLoading />
            <Updaters />
            <ModalsProvider>
              <Popups />
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </ModalsProvider>
          </TinyProvider>
        </QueryClientProvider>
      </Web3ReactProvider>
    </Provider>
  );
}

export default App;
