// import { ContractName, TokenStat, TreasuryAllocationTime } from "./types";
import { BigNumber, Contract, ethers, Overrides } from "ethers";
// import { decimalToBalance } from "./ether-utils";
import Web3 from "web3";

import ERC20 from "./ERC20";
// import { getDisplayBalance } from "../utils/formatBalance";
import { testRPC } from "../utils/provider";
import { getContractInfoMap } from "./contractInfoMap";
import { getChainsGasPrice, setTxParamCache } from "./utils";

// import { promiseCache } from '../services';
import { clearParamCache } from "./utils";
import { Chains, Configuration, CustomTxRes } from "src/config";
import { bn0 } from "src/utils/constant";

export class Tiny {
  myAccount?: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  boardroomVersionOfUser?: string;
  DEFAULT_RPC_PROVIDER: string;
  chainId: Chains;
  web3Instance?: Web3;

  constructor(config: {
    cfg: Configuration;
    defaultProvider: ethers.providers.Web3Provider;
    chainId: Chains;
    web3: Web3;
    DEFAULT_RPC_PROVIDER: string;
  }) {
    const { cfg, defaultProvider, web3, chainId, DEFAULT_RPC_PROVIDER } =
      config;
    const { externalTokens } = cfg;
    const provider = defaultProvider;

    this.config = cfg;
    this.provider = provider;
    this.web3Instance = web3;
    this.chainId = chainId;
    this.DEFAULT_RPC_PROVIDER = DEFAULT_RPC_PROVIDER;
    this.contracts = {};

    for (const [name, deployment] of Object.entries(
      getContractInfoMap(chainId)
    )) {
      this.contracts[name] = new Contract(
        deployment?.address,
        deployment.abi,
        provider
      );
    }

    this.externalTokens = {};

    for (const [symbol, address] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, 18);
    }
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account?: string) {
    const newProvider = new ethers.providers.Web3Provider(
      provider,
      this.chainId
    );

    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    this.web3Instance = new Web3(provider);

    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = Object.values(this.externalTokens);
    for (const token of tokens) {
      token.connect(this.signer);
    }

    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  logout() {
    delete this.myAccount;
    delete this.signer;
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  gasOptions(gas: number, gasPrice: number, nonce?: number): Overrides {
    const multiplied = Math.floor(gas * this.config.gasLimitMultiplier);
    console.log(`⛽️ Gas multiplied: ${gas} -> ${multiplied}`);
    return {
      gasLimit: BigNumber.from(multiplied),
      gasPrice: BigNumber.from(gasPrice),
      nonce,
    };
  }

  gasAmount(contract: Contract, hexString: string, value: string) {
    const account = this.myAccount as string;
    return this.web3Instance!.eth.estimateGas({
      from: account,
      to: contract?.address,
      data: hexString,
      value,
    })
      .then((gas) => {
        setTxParamCache({
          gas2: gas,
        });
        return gas;
      })
      .catch((err) => {
        const message = err.message;
        if (
          message &&
          message.includes("Invalid JSON RPC response") &&
          this.config
        ) {
          return testRPC(this.config.defaultProvider, this.DEFAULT_RPC_PROVIDER).then(() => {
            return this.web3Instance!.eth.estimateGas({
              from: account,
              to: contract?.address,
              data: hexString,
            }).then((gas) => {
              setTxParamCache({
                gas3: gas,
              });
              return gas;
            });
          });
        } else {
          throw err;
        }
      });
  }

  async getTxNonce() {
    let nonce: number | undefined;
    const account = this.myAccount;
    if (!this.signer || !account) return;
    try {
      nonce = await this.signer.getTransactionCount();
      setTxParamCache({
        nonce1: nonce,
      });
    } catch (error) {
      console.error(error);
    }
    if (nonce === undefined) {
      try {
        const web3 = this.web3Instance;
        if (web3) {
          nonce = await web3.eth.getTransactionCount(account);
          setTxParamCache({
            nonce2: nonce,
          });
        }
        // const account = this.myAccount as string;
      } catch (error) {
        console.error(error);
      }
    }
    return nonce;
  }

  async contractMethodSend(
    contract: Contract,
    method: string,
    params: any[],
    useReceipt = false,
    value = bn0 //调用合约手续费
  ): Promise<CustomTxRes> {
    let gasPrice = getChainsGasPrice(this.chainId);
    // this.chainId === 56 ? 3000000000 : 10000000000;
    // 250000000
    clearParamCache();

    const hexString = contract.interface.encodeFunctionData(method, params);
    let gas = await this.gasAmount(contract, hexString, value.toString());
    let nonce = await this.getTxNonce();

    const web3 = this.web3Instance;
    if (web3) {
      return new Promise((resolve, reject) => {
        return web3.eth
          .sendTransaction({
            from: this.myAccount,
            to: contract?.address,
            data: hexString,
            gas: Math.floor(gas * this.config.gasLimitMultiplier),
            gasPrice: gasPrice,
            nonce,
            value: value.toString(),
          })
          .on("transactionHash", function (hash) {
            if (!useReceipt) {
              resolve({ hash });
            }
          })
          .on("receipt", function (receipt) {
            if (useReceipt) {
              const hash = receipt.transactionHash;
              const blockHash = receipt.blockHash;
              const logs = receipt.logs;
              resolve({ hash, blockHash, logs });
            }
          })
          .on("error", function (error) {
            reject(error);
          });
      });
    }

    try {
      if (!gas) {
        // estimateGas failed
        gas = await contract.estimateGas[method](...params).then((gas) => {
          setTxParamCache({
            gas1: gas.toNumber(),
          });
          return gas.toNumber();
        });
      }

      return contract[method](...params, this.gasOptions(gas, gasPrice, nonce));
    } catch (error: any) {
      if (
        typeof error === "string" &&
        error.includes("invalid BigNumber value")
      ) {
        throw new Error(
          error + `gas:${gas},gasPrice:${gasPrice},nonce:${nonce}`
        );
      } else {
        throw error;
      }
    }
  }

  async bscBuyTera(amount: BigNumber): Promise<CustomTxRes> {
    const BuyTera = this.contracts?.BuyTera;
    return this.contractMethodSend(BuyTera, "buy", [amount]);
  }
}
