import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { AppDispatch, AppState } from "../index";
import { addTransaction, clearStaleTransactions } from "./actions";
import { TransactionDetails } from "./reducer";
import { CustomTxRes } from "src/types";
// import type { TransactionResponse } from "@ethersproject/providers";

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: CustomTxRes,
  customData?: {
    summary?: string;
    approval?: { tokenAddress: string; spender: string };
  }
) => void {
  const { chainId, account } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (
      response: CustomTxRes,
      {
        summary,
        approval,
      }: {
        summary?: string;
        approval?: { tokenAddress: string; spender: string };
      } = {}
    ) => {
      if (!account) return;
      if (!chainId) return;

      const { hash } = response;
      if (!hash) {
        throw Error("No transaction hash found.");
      }
      try {
        const params = { hash, from: account, chainId, approval, summary };
        dispatch(addTransaction(params));
      } catch (error) {
        console.error({
          error,
        });
      }
    },
    [dispatch, chainId, account]
  );
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useWeb3React();
  const state = useSelector<AppState, AppState["transactions"]>(
    (state) => state.transactions
  );

  return chainId ? state[chainId] ?? {} : {};
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions();
  if (!transactionHash || !transactions[transactionHash]) {
    return false;
  }
  return !transactions[transactionHash].receipt;
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86_400_000;
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(
  tokenAddress: string | undefined,
  spender: string | undefined
): boolean {
  const allTransactions = useAllTransactions();

  return useMemo(() => {
    try {
      const res =
        typeof tokenAddress === "string" &&
        typeof spender === "string" &&
        Object.keys(allTransactions).some((hash) => {
          const tx = allTransactions[hash];
          if (!tx) return false;
          if (tx.receipt) {
            return false;
          } else {
            const approval = tx.approval;
            if (!approval) return false;

            return (
              approval.spender === spender &&
              approval.tokenAddress === tokenAddress &&
              isTransactionRecent(tx)
            );
          }
        });
      return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  }, [allTransactions, spender, tokenAddress]);
}

export function useClearStaleTransactions(): {
  clearStaleTransactions: () => void;
} {
  const { chainId } = useWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  return {
    clearStaleTransactions: useCallback(() => {
      chainId && dispatch(clearStaleTransactions({ chainId }));
    }, [dispatch, chainId]),
  };
}
