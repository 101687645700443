import { Contract, BigNumber, constants, utils } from "ethers";
import { Chains } from "src/config";

export const bn0 = BigNumber.from("0");

export function noFunc() {}

export function noFuncPromise(): Promise<void> {
  return new Promise<void>(() => {});
}


export const getBackEndUrl = (chainId: Chains) => {
  // console.log({
  //   key: "getBackEndUrl",
  //   chainId,
  //   NODE_ENV: process.env.NODE_ENV,
  // });
  if (process.env.NODE_ENV === "production") {
    if (chainId === 97) {
      return "https://farmapi.tinyworlds.io";
    }
    // if (chainId === Chains.zkSyncTestnet) {
    //   return "https://test-node.tinyworlds.io";
    // }
    return "https://api.tinyworlds.io";
  }
  if (chainId === 97) {
    // return "http://localhost:3005";
    return "https://farmapi.tinyworlds.io";
    // https://farmapi.tinyworlds.io/api/farm/pools
    // return "http://192.168.50.7:3005";
    // return "http://ec2co-ecsel-iuoelrd6t8du-561022681.us-east-2.elb.amazonaws.com:3005";
  }
  // if (chainId === Chains.zkSyncTestnet) {
  //   return "https://test-node.tinyworlds.io";
  // }
  return "https://api.tinyworlds.io";
};
// console.log("getBackEndUrl(chainId)", getBackEndUrl(chainId));

export const static_api_base = "/resources/data";

export const img_url = "//images.tinyworlds.io";

export const my_api_base = "/api/me";

export const farm_api_base = "/api/farm";

export const auction_api_base = "/api/auctions";

export const game_api_base = "/api/game";

export const game_login_api_base = "/api/login";

export const game_login_signin_api_base = "/api/sign-in";

export const game_register_api_base = "/api/Register";

export const game_users_api_base = "/api/users";

export const nfts_api_base = "/api/nfts";

export const rune_auction_api_base = "/api/runeauctions";

export const referral_api_base = "/api/referral";

export const overview_api_base = "/api/overview";

export const ticket_api_base = "/api/ticket";

export const lord_api_base = "/api/lord";

export const lord_online_api_base = "/api/lordonline";

export const ur_auction_api_base = "/api/urauction";

export const active_api_base = "/api/dev";

export const pets_api_base = "/api/pets";

export const pet_market_api_base = "/api/petmarket";