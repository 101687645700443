import { MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React, { useCallback, useEffect, useMemo } from "react";
import BSCTestIcon from "src/images/layer/BSCTest-Layer.png";
import zkSyncTestIcon from "src/images/layer/zkSync-Layer.png";
import BSCIcon from "src/images/layer/BSC-Layer.png";
import styled from "styled-components";
import switchChain from "src/utils/switchChain";
import useTiny from "src/hooks/useTiny";
import { ConnectorNames, findConnectorId } from "src/wallet";
import { useWeb3React } from "@web3-react/core";
import { Chains } from "src/config";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

export const getLayerMaps = (chains: Chains | undefined) => {
  if (!chains) {
    return [];
  }
  if (chains === 97 || chains === 280) {
    // if (chains === 97 || chains === 270) {
    return [
      {
        icon: BSCTestIcon,
        text: "BNB Smart Chain Test",
        chain: Chains.bscTestnet,
      },
      // {
      //   icon: BSCIcon,
      //   text: "BNB Smart Chain",
      //   chain: Chains.bsc,
      // },
      {
        icon: zkSyncTestIcon,
        text: "zkSync Era Testnet",
        chain: Chains.zkSyncTestnet,
      },
    ];
  } else {
    return [
      {
        icon: BSCIcon,
        text: "BNB Smart Chain",
        chain: Chains.bsc,
      },
    ];
  }
};

export const useCheckLayer = (checkChainId?: number) => {
  const { chainId } = useWeb3React();
  const checkChain = checkChainId ? checkChainId : chainId;

  const checkLayer = checkChain
    ? Object.keys(Chains)
        .map((key) => {
          return Chains[key as keyof typeof Chains];
        })
        .includes(checkChain)
    : false;

  return checkLayer;
};

const useLayer = () => {
  const { config, chainId, setChainId } = useTiny();
  const { connector } = useWeb3React();

  const handlerChangeLayer = useCallback(
    (chain: Chains, cb?: () => void) => {
      if (connector && chain !== chainId) {
        const connectorId = findConnectorId({
          connector,
        });
        if (
          connectorId === ConnectorNames.Injected ||
          connectorId === ConnectorNames.WalletLink
        ) {
          // TODO 限制用户切换网络只能切换到 2个网络  Chains
          switchChain(connectorId, chain, () => {
            setChainId(chain);
            cb && cb();
          });
        }
      }
    },
    [chainId, connector, setChainId]
  );

  const layersComponents: MenuProps["items"] = useMemo(() => {
    return getLayerMaps(chainId).map(({ icon, text, chain }, index) => {
      return {
        key: index,
        label: (
          <StyledLayerButton
            onClick={() => {
              handlerChangeLayer(chain);
            }}
          >
            <img src={icon} alt={text} />
            {text}
          </StyledLayerButton>
        ),
      } as ItemType;
    });
  }, [chainId, handlerChangeLayer]);

  return {
    component: layersComponents,
    handlerChangeLayer,
  };
};

export const useNowLayer = () => {
  const { chainId } = useTiny();
  const layer =
    chainId && getLayerMaps(chainId).find((ele) => ele.chain === chainId);
  return (
    layer || {
      icon: BSCIcon,
      text: "defaultBSC",
      chain: undefined,
    }
  );
};

export default useLayer;

const StyledLayerButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 1.25vw;

  &:hover {
    background: #fdce4f !important;
    color: #514b45 !important;
  }

  img {
    height: 100%;
    margin-right: 0.312vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 24px;

    img {
      height: 100%;
      margin-right: 6px;
    }
  }
`;
