import { BigNumber } from "ethers";
import { Tiny } from "../Tiny";
import { CustomTxRes } from "src/types";

export class TinyEx extends Tiny {
  //  申购领取
  async zkSyncTeraClaim(): Promise<CustomTxRes> {
    const TesAirdrop = this.contracts?.TesAirdrop;
    return this.contractMethodSend(TesAirdrop, "claim", []);
  }

  // 空投领取
  async claimAirdrop(): Promise<CustomTxRes> {
    const airdropContract = this.contracts?.TinyAirdrop;
    return this.contractMethodSend(airdropContract, "claim", []);
  }
}
