import { useEffect, useState } from "react";
const daySeconds = 24 * 60 * 60;

export function useTimerSecondByZkSync({
  time,
  zkSyncNowDate,
  options,
}: {
  time?: number;
  zkSyncNowDate?: number;
  options?: {
    days: boolean;
  };
}) {
  const [countdown, setCountDown] = useState<number | undefined>(
    zkSyncNowDate && time ? Math.floor(time - zkSyncNowDate) : time
  );

  useEffect(() => {
    let timer: number;
    if (time) {
      setCountDown(
        time && zkSyncNowDate ? Math.floor(time - zkSyncNowDate) : time
      );
      timer = window.setInterval(() => {
        setCountDown((pre) => {
          if (pre === undefined) return;
          if (pre > 0) {
            return pre - 1;
          }
          return 0;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time, zkSyncNowDate]);

  return options?.days
    ? {
        days: Math.floor((countdown || 0) / daySeconds),
        seconds: (countdown || 0) % daySeconds,
      }
    : countdown;
}

export function useTimerSecond({
  time,
  zkSyncNowDate,
  options,
}: {
  time?: number;
  zkSyncNowDate?: number;
  options?: {
    days: boolean;
  };
}) {
  const now = zkSyncNowDate ? zkSyncNowDate : Date.now() / 1000;

  const [countdown, setCountDown] = useState<number | undefined>(
    time ? Math.floor(time - now) : time
  );
  useEffect(() => {
    let timer: number;
    if (time) {
      setCountDown(time ? Math.floor(time - now) : time);
      timer = window.setInterval(() => {
        setCountDown((pre) => {
          if (pre === undefined) return;
          if (pre > 0) {
            return pre - 1;
          }
          return 0;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [now, time]);

  return options?.days
    ? {
        days: Math.floor((countdown || 0) / daySeconds),
        seconds: (countdown || 0) % daySeconds,
      }
    : countdown;
}
