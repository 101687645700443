import { useQuery } from "react-query";
import { Provider } from "zksync-web3";
import { Chains, isDev, providerMap } from "src/config";

interface Props {
  open?: boolean;
}

let timestamp: number | undefined = undefined;
let reBatchNumber: number | undefined = undefined;
let reNowZkSyncBlock: number | undefined = undefined;
let reBatchNumberRange: [number, number] | null = null;

function useBatchNumber({ open }: Props) {
  const { data: zkSyncDate, refetch: zkSyncDateRefetch } = useQuery<
    number | undefined
  >(
    ["ZKSYNC_ERA_BATCH_NUMBER"],
    async () => {
      if (typeof open === "boolean" && !open) return;
      try {
        // const provider = new Provider("https://mainnet.era.zksync.io");
        const provider = new Provider(
          providerMap[!isDev() ? Chains.zkSync : Chains.zkSyncTestnet][0]
        );
        const batchNumber = await provider.getL1BatchNumber();

        // 当前的block号
        const blockNumber = await provider.perform("getBlockNumber", []);

        if (batchNumber !== reBatchNumber) {
          const batchNumberRange = await provider.getL1BatchBlockRange(
            batchNumber
          );
          if (batchNumberRange) {
            reBatchNumberRange = batchNumberRange;
            reBatchNumber = batchNumber;
          }
        }

        const nowZkSyncBlock = reBatchNumberRange
          ? blockNumber > reBatchNumberRange[1]
            ? reBatchNumberRange[1] + 1
            : reBatchNumberRange[0]
          : undefined;

        if (
          typeof nowZkSyncBlock === "number" &&
          reNowZkSyncBlock !== nowZkSyncBlock
        ) {
          reNowZkSyncBlock = nowZkSyncBlock;
          const blockDetails = await provider.getBlockDetails(nowZkSyncBlock);

          if (blockDetails.timestamp) {
            timestamp = blockDetails.timestamp;
          }
          return timestamp;
        } else {
          return timestamp;
        }
      } catch (error) {
        console.error(error);
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    }
  );

  return {
    zkSyncDate,
    data: zkSyncDate,
    refetch: zkSyncDateRefetch,
  };
}

export default useBatchNumber;
