import React, { useRef, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import useIsMobile from "src/hooks/useIsMobile";
import { Context } from "src/contexts/TinyProvider";

import Redirect from "../Svg/Redirect";

import LinkMetaMask from "./LinkMetaMask";
import useUserWallet from "src/hooks/useUserWallet";
import useTiny from "src/hooks/useTiny";
import { tokenFloor } from "src/tiny/utils";
import { chainsAddrresUrls } from "src/config";
import CopySvg from "../Svg/CopySvg";
import ModalActions from "../ModalActions";
import { TinyButton } from "../TinyComponents";
import { getDisplayBalance } from "src/utils/str";
import { runeSealed } from "src/views/Home/imgs";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
interface IWalletBalance {
  icon: string;
  title: string;
  value: string;
  isZkSync: boolean; //zkSync 上显示  true    不显示 false
  isBsc: boolean; //bsc 上显示  true    不显示 false
  address: string | undefined;
  valueZeroHide?: boolean;
}

function WalletDetail({ cb }: { cb?: () => void }) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { chainId } = useTiny();

  const { logout } = useContext(Context);
  const {
    account,
    config,
    gameSymbolImg,
    gameSymbol,
    mainSymbolImg,
    mainSymbol,
    usdSymbolImg,
    usdSymbol,
    isBsc,
  } = useTiny();

  const {
    lockSpiritBalance,
    tincBalance,
    spiritBalance,
    bnbBalance,
    busdBalance,
    runeBalance,
    brigeTINCBalance,
  } = useUserWallet();

  const walletBalance: IWalletBalance[] = useMemo(
    () => [
      {
        icon: gameSymbolImg,
        title: gameSymbol,
        value: tincBalance
          ? tokenFloor(Number(getDisplayBalance(tincBalance))).toString()
          : "0",
        isZkSync: true,
        isBsc: true,
        address: config && config.externalTokens.TINC,
      },
      {
        icon: "https://images.tinyworlds.io/tokens/TINC.png",
        title: "TINC",
        value: brigeTINCBalance
          ? tokenFloor(Number(getDisplayBalance(brigeTINCBalance))).toString()
          : "0",
        isZkSync: true,
        isBsc: false,
        address: config && config.externalTokens.brigeTINC,
      },
      {
        icon: "//images.tinyworlds.io/tokens/SPIRIT.png",
        title: t("staminaItem"),
        value: spiritBalance ? spiritBalance.toNumber().toString() : "0",
        isZkSync: false,
        isBsc: true,
        address: undefined,
      },
      {
        icon: "https://images.tinyworlds.io/tokens/LOCKED-SPIRIT.png",
        title: t("lockStaminaItem"),
        value: lockSpiritBalance
          ? lockSpiritBalance.toNumber().toString()
          : "0",
        valueZeroHide: true,
        isZkSync: false,
        isBsc: true,
        address: undefined,
      },
      {
        icon: isBsc
          ? runeSealed
          : "https://images.tinyworlds.io/tokens/RUNE-ZK.png",
        title: t("Mystery Rune"),
        // value: (redeemedRuneNum.toNumber() + runev2Num.toNumber()).toString(),
        value: runeBalance ? runeBalance.toNumber().toString() : "0",
        isZkSync: true,
        isBsc: true,
        address: undefined,
      },
      {
        icon: mainSymbolImg,
        title: mainSymbol,
        value: bnbBalance
          ? tokenFloor(Number(getDisplayBalance(bnbBalance))).toString()
          : "0",
        isZkSync: true,
        isBsc: true,
        address: undefined,
      },
      {
        icon: usdSymbolImg,
        title: usdSymbol,
        value: busdBalance
          ? tokenFloor(Number(getDisplayBalance(busdBalance))).toString()
          : "0",
        isZkSync: true,
        isBsc: true,
        address: undefined,
      },
    ],
    [
      bnbBalance,
      brigeTINCBalance,
      busdBalance,
      config,
      gameSymbol,
      gameSymbolImg,
      isBsc,
      lockSpiritBalance,
      mainSymbol,
      mainSymbolImg,
      runeBalance,
      spiritBalance,
      t,
      tincBalance,
      usdSymbol,
      usdSymbolImg,
    ]
  );

  const fooRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    logout();
    cb && cb();
  };

  // onDismiss && onDismiss();
  return (
    <>
      <StyledTitle>
        <a
          target="_blank"
          href={chainId && chainsAddrresUrls[chainId] + account}
          rel="noreferrer"
        >
          {t("Your Wallet")}
          <Redirect className={"ml-4"} color={"#514B45"} />
          {/* <img className={"ml-4"} src={redirectSvg} alt="View on BscScan" /> */}
        </a>
      </StyledTitle>

      <StyledContent>
        <StyledItem className="position-relative">
          <input type="text" disabled={true} value={account || undefined} />

          <div
            className="flex-center flex"
            onClick={() => {
              const successd = copy(account ? account : "");
              if (successd && fooRef.current) {
                ReactTooltip.show(fooRef.current);
              }
            }}
          >
            <CopySvg fill="#514B45" />
          </div>

          <div
            style={{ position: "absolute", right: "5%", bottom: 0 }}
            data-tip
            data-for="copy-address"
            ref={fooRef}
          />

          <ReactTooltip
            place="bottom"
            id="copy-address"
            effect="solid"
            isCapture={true}
            afterShow={() => setTimeout(ReactTooltip.hide, 1000)}
            border
            textColor="#935316"
            backgroundColor="#FFBD4A"
            borderColor="#BC6C1E"
          >
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {t("Copied")}
            </span>
          </ReactTooltip>
        </StyledItem>
      </StyledContent>

      <StyledContent>
        <StyledItem>
          <ul>
            {walletBalance.map(
              (
                {
                  icon,
                  value,
                  title,
                  valueZeroHide,
                  isBsc: bscShowToken,
                  isZkSync: zkSyncShowToken,
                  address,
                },
                index
              ) => {
                const render = (isBsc ? bscShowToken : zkSyncShowToken) && (
                  <li key={index}>
                    <StyledBalanceLeft>
                      <StyledIcon
                        style={
                          index === 2
                            ? {
                                height: "auto",
                                width: isMobile ? 20 : 25,
                              }
                            : undefined
                        }
                        className="mr-4"
                        src={icon}
                      ></StyledIcon>
                      <span className="mr-8">{title}</span>
                      {address && (
                        <LinkMetaMask symbol={title} symbolAddress={address} />
                      )}
                    </StyledBalanceLeft>
                    <div>{value}</div>
                  </li>
                );
                return (
                  <>
                    {valueZeroHide ? (
                      Number(value) === 0 ? (
                        <></>
                      ) : (
                        render
                      )
                    ) : (
                      render
                    )}
                  </>
                );
              }
            )}
          </ul>
        </StyledItem>
      </StyledContent>

      <ModalActions
        style={
          isMobile
            ? {
                height: "5rem",
                margin: "0px -8px -24px",
              }
            : {
                height: "4.2vw",
                margin: "0px -0.416vw -1.248vw",
              }
        }
      >
        <TinyButton onClick={handleLogout}>
          Logout
          {/* {t("Logout")} */}
        </TinyButton>
      </ModalActions>
    </>
  );
}

export default WalletDetail;

const StyledBalanceLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledIcon = styled.img`
  height: auto;
  width: 1.3vw;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: auto;
    width: 20px;
  `}
`;

const StyledTitle = styled.div`
  text-align: center;

  a {
    display: flex;
    align-items: center;
    margin-left: 0.208vw;
    color: #514b45;
    justify-content: center;
    font-weight: 600;
    font-size: 1.092vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin-left: 4px;
    font-size: 20px;
  }
`;

const StyledItem = styled.div`
  width: 100%;
  background-color: #fffbdd;
  border-radius: 0.572vw;
  min-height: 2.1658vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.52vw;
  box-sizing: border-box;

  input {
    color: #514b45;
    width: 90%;
    background: none;
    border: 0;
    font-size: 0.833vw;
    font-weight: 600;
  }

  ul {
    list-style: none;
    width: 100%;
    margin: 0.52vw;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 2.1658vw;
    }

    li + li {
      border-top: 0.052vw solid #aea78a;
    }
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    font-size: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;

    border-radius: 11px;
    min-height: 42px;
    padding: 0 10px;

    input {
      font-size: 16px;
    }

    ul {
      margin: 10px;

      li {
        min-height: 42px;
      }

      li + li {
        border-top: 1px solid #aea78a;
      }
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.78vw 0 0;

  .__react_component_tooltip {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-out !important;
    opacity: 0 !important;
    visibility: visible;
  }

  .__react_component_tooltip.show {
    visibility: visible;
    opacity: 1 !important;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    justify-content: center;
    margin: 10px auto 0;
  }
`;
