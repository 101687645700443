import React, { useCallback, useContext, useEffect } from "react";
import { X } from "react-feather";
import styled, { ThemeContext } from "styled-components";
import { useSpring, animated } from "react-spring";
import { PopupContent } from "../../state/application/actions";
import { useRemovePopup } from "../../state/application/hooks";
import TransactionPopup from "./TransactionPopup";
import ErrorPopup from "./ErrorPopup";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

export const StyledClose = styled(X)`
  color: #a8a8a8;
  :hover {
    cursor: pointer;
  }
`;
export const Popup = styled.div`
  /* display: inline-block; */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em;
  background-color: #5c5c5c;
  position: relative;

  border-radius: 0.52vw;
  padding: 1.04vw;
  padding-right: 1.82vw;
  overflow: hidden;

  @media (max-width: 768px) {
    min-width: 290px;
    min-height: 40px;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    border-radius: 10px;
    padding: 20px;
    padding-right: 35px;
  }
`;
const Fader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.104vw;
  z-index: 1;
  background-color: #b4b4b4;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 2px;
  }
`;

const AnimatedFader = animated(Fader);

export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null;
  content: PopupContent;
  popKey: string;
}) {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(
    () => removePopup(popKey),
    [popKey, removePopup]
  );
  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  const theme = useContext(ThemeContext);

  let popupContent;
  if (content.txn) {
    const { hash, success, summary } = content.txn;
    popupContent = (
      <TransactionPopup hash={hash} success={success} summary={summary} />
    );
  }
  if (content.error) {
    const { message, stack } = content.error;
    popupContent = <ErrorPopup message={message} stack={stack} />;
  }

  const faderStyle = useSpring({
    from: { width: "100%" },
    to: { width: "0%" },
    config: { duration: removeAfterMs ?? undefined },
  });

  return (
    <Popup>
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
      <StyledCloseContainer>
        <StyledClose className="popup-close" onClick={removeThisPopup} />
      </StyledCloseContainer>
    </Popup>
  );
}

const StyledCloseContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0.052vw solid;
  border-image: linear-gradient(
      to bottom,
      transparent 16%,
      #707070 16% 82%,
      transparent 82%
    )
    5;

  :hover {
    border: none;
    cursor: pointer;

    .popup-close {
      color: #514b45 !important;
    }
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    border-left: 1px solid;
  }
`;
