import React, { useCallback, useEffect, useMemo, useState } from "react";

interface Props {
  imgs: string[];
}

const cache: {
  [url: string]: boolean;
} = {};

function useImgsLoaded({ imgs }: Props) {
  const imgLoaded = useMemo(
    () => imgs.filter((ele) => !!cache[ele]).length === imgs.length,
    [imgs]
  );
  const [loaded, setLoaded] = useState(imgLoaded);

  const handleLoaded = useCallback(() => {
    setLoaded(true);
    imgs.map((ele) => {
      cache[ele] = true;
    });
  }, [imgs]);

  useEffect(() => {
    const imgPromise: Promise<unknown>[] = [];
    imgs.map((i) => {
      imgPromise.push(
        new Promise((res, rej) => {
          const img = new Image();
          img.src = i;
          img.decode().then(() => {
            res(i);
          });
          img.onerror = (err) => {
            rej();
          };
        })
      );
    });

    Promise.all(imgPromise).then((res) => {
      if (res.length !== imgs.length) {
        console.error(`${imgs} ${res} imgs loading faild`);
      } else {
        handleLoaded();
      }
    });
  }, [handleLoaded, imgs]);

  return {
    loaded,
  };
}

export default useImgsLoaded;
