import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "antd";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

//  extends ButtonHTMLAttributes<HTMLButtonElement>
export interface BaseProps extends ButtonProps {
  width?: number | string;
  height?: number;
  ref?: React.RefObject<HTMLButtonElement>;
}

export function TinyButton(props: PropsWithChildren<BaseProps>) {
  const { children, width, height, ref, onClick, disabled, style, ...rest } =
    props;
  return (
    <StyledBaseButton
      style={style}
      onClick={onClick}
      ref={ref}
      width={width}
      height={height}
      {...rest}
      disabled={disabled}
    >
      {children}
    </StyledBaseButton>
  );
}

interface BaseStyledProps {
  width?: number | string;
  height?: number;
  fontSize?: string;
  url?: string;
  disabled?: boolean;
}

export const StyledBaseButton = styled(Button)<BaseStyledProps>`
  padding: 0.42vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.32vw;
  box-shadow: none;
  transition: unset;
  font-family: EuclidSquare;
  color: #febe41;
  ${FontSizeConfig.l}
  height: 1.67vw;

  &.ant-btn-default:disabled {
    cursor: auto;
    border-color: #5a5a5a;
    color: #5a5a5a;
    background-color: #2f2f2f;
    box-shadow: none;
  }

  &.ant-btn-primary:disabled {
    cursor: auto;
    border-color: unset;
    color: #313131;
    background-color: #5a5a5a;
    box-shadow: none;
  }

  &.ant-btn-default {
    background-color: rgb(254 190 65 / 30%);
    /* background-color: #443b2d; */
    border-color: #febe41;
  }

  &.ant-btn:not(:disabled):focus-visible {
    outline: 0 solid #febe41;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
  }

  &.ant-btn-default:not(:disabled):hover {
    color: #febe41;
    border-color: #febe41;
    background-color: rgb(254 190 65 / 50%);
  }

  &.ant-btn-default:not(:disabled):active {
    color: #febe41;
    border-color: #febe41;
  }

  &.ant-btn-primary {
    color: #313131;
    background-color: #febe41;
  }

  &.ant-btn-primary:not(:disabled):hover {
    color: #313131;
    background-color: rgb(254 190 65 / 80%);
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 32px;

    padding: 8px;
    border-radius: 6px;
  }
`;
