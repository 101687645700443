import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { styled } from "styled-components";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { DefaultTFuncReturn } from "i18next";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

const { Panel } = Collapse;

export interface Question {
  header: string | JSX.Element | DefaultTFuncReturn;
  context: string | JSX.Element | DefaultTFuncReturn;
}

interface Props {
  questions: Question[];
}

export const TinyCollapse: React.FC<Props> = ({ questions }) => {
  return (
    <StyledTinyCollapse
      bordered={false}
      expandIconPosition={"end"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      {questions.map(({ header, context }, index) => {
        return (
          <StyledTinyPanel header={header} key={index}>
            {typeof context === "string" ? <p>{context}</p> : context}
          </StyledTinyPanel>
        );
      })}
    </StyledTinyCollapse>
  );
};

const StyledTinyPanel = styled(Panel)`
  p {
    margin: 0;
  }
`;

const StyledTinyCollapse = styled(Collapse)`
  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    height: auto;
    display: flex;
    align-items: center;
    padding-inline-end: 0.625vw;
  }

  &.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0.42vw 0.833vw;
    ${FontSizeConfig.m}
    padding-bottom: 0rem;
  }

  &.ant-collapse-borderless {
    background-color: unset;
    border: 0;
    width: 100%;
  }

  &.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0;
    margin-bottom: 0.42vw;
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-bottom: none;
    border-radius: 0.312vw;
    background: #2c2b2e;
    color: #ffffff;
    padding: 0.625vw 0.833vw;
    align-items: center;
    ${FontSizeConfig.m}
  }

  &.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: unset;
    border-top: 0;
    color: #959595;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    &.ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-expand-icon {
      padding-inline-end: 12px;
    }

    &.ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 8px 16px;
    }

    &.ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 0;
      margin-bottom: 8px;
    }

    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      border-radius: 6px;
      padding: 12px 16px;
    }
  }
`;
