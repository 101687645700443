import { BigNumber, Contract, ethers } from "ethers";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Chains, getChains, providerMap } from "src/config";
import useApprove, { ApprovalState } from "src/hooks/useApprove";
import useBatchNumber from "src/hooks/useBatchNumber";
import useTiny from "src/hooks/useTiny";
import useTx from "src/hooks/useTx";
import { getContractInfoMap } from "src/tiny/contractInfoMap";
import { testRPC } from "src/utils/provider";
import { getDisplayBalance, tokenFloor } from "src/utils/str";

export const inputMinPurchase = "500";

export const usePurchaseBSCBaseData = () => {
  const { t } = useTranslation();

  return useQuery<
    | {
        buyEndTime: BigNumber | undefined;
        buyStartTime: BigNumber | undefined;
        buyAmountCounts: BigNumber | undefined;
      }
    | undefined
  >(
    ["PURCHASE_BASE_DATA"],
    async () => {
      try {
        const chainId = getChains("bsc");

        const provider = await testRPC(providerMap[chainId]);

        const defaultProvider = new ethers.providers.Web3Provider(
          provider as any,
          chainId
        );

        const deployment = getContractInfoMap(chainId);

        const contract = new Contract(
          deployment["BuyTera"].address,
          deployment["BuyTera"].abi,
          defaultProvider
        );

        if (contract) {
          const buyEndTime: BigNumber | undefined =
            await contract?.buyEndTime();
          const buyStartTime: BigNumber | undefined =
            await contract?.buyStartTime();
          const buyAmountCounts: BigNumber | undefined =
            await contract?.buyAmountCounts();
          return { buyEndTime, buyStartTime, buyAmountCounts };
        }
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchInterval: 60_000,
      keepPreviousData: true,
    }
  );
};

export const usePurchaseBSCUserData = () => {
  const { account } = useTiny();

  return useQuery<
    | {
        buyAmounts: BigNumber | undefined;
      }
    | undefined
  >(
    ["PURCHASE_USER_DATA", account],
    async () => {
      try {
        if (account) {
          const chainId = getChains("bsc");

          const provider = await testRPC(providerMap[chainId]);

          const defaultProvider = new ethers.providers.Web3Provider(
            provider as any,
            chainId
          );
          const deployment = getContractInfoMap(chainId);
          const contract = new Contract(
            deployment["BuyTera"].address,
            deployment["BuyTera"].abi,
            defaultProvider
          );

          if (contract) {
            const buyAmounts: BigNumber | undefined =
              await contract?.buyAmounts(account);

            return { buyAmounts };
          }
        }
      } catch (error) {}
    },
    {
      refetchInterval: 60_000,
      keepPreviousData: true,
    }
  );
};

// startTime
export const usePurchaseClaimTime = () => {
  return useQuery<BigNumber | undefined>(
    ["PURCHASE_Claim_TIME"],
    async () => {
      try {
        const chainId = getChains("zkSync");

        const provider = await testRPC(providerMap[chainId]);
        const defaultProvider = new ethers.providers.Web3Provider(
          provider as any,
          chainId
        );

        const deployment = getContractInfoMap(chainId);

        const contract = new Contract(
          deployment["TesAirdrop"].address,
          deployment["TesAirdrop"].abi,
          defaultProvider
        );

        if (contract) {
          const startTime = await contract?.startTime();
          return startTime;
        }
      } catch (error) {}
    },
    {
      refetchInterval: 60_000,
    }
  );
};

export const usePurchaseApprove = (amount: BigNumber | undefined) => {
  const { tiny } = useTiny();
  const BuyTera = tiny?.contracts.BuyTera;
  const TINC = tiny?.externalTokens?.TINC;

  const [approveStatus, approve, currentAllowance] = useApprove(
    TINC,
    BuyTera?.address,
    amount
  );

  return {
    approveStatus,
    approve,
    currentAllowance,
    needApprove:
      approveStatus === ApprovalState.PENDING ||
      ApprovalState.NOT_APPROVED === approveStatus,
    approveDisabled: approveStatus === ApprovalState.PENDING,
    approveSubmitDisabled: approveStatus === ApprovalState.UNKNOWN,
  };
};

function usePurchase() {
  const { account, tiny } = useTiny();
  const BuyTera = tiny?.contracts.BuyTera;
  const { t } = useTranslation();

  const { data: userData, refetch: userDataRefetch } = usePurchaseBSCUserData();

  const { data: bscBaseData, refetch: buyAmountCountsRefetch } =
    usePurchaseBSCBaseData();

  const { data: activityClaimTime } = usePurchaseClaimTime();

  const {
    myPercentage,
    myAmount,
    totalAmount,
    buyEndTime,
    buyStartTime,
    claimOpenTime,
  } = useMemo(() => {
    const defalutData = {
      max: 0,
      myAmount: 0,
      totalAmount:
        bscBaseData?.buyAmountCounts && !bscBaseData?.buyAmountCounts.isZero()
          ? tokenFloor(
              Number(getDisplayBalance(bscBaseData?.buyAmountCounts, 18, 2)),
              2
            )
          : 0,
      myPercentage: 0,
      buyEndTime: bscBaseData?.buyEndTime
        ? bscBaseData?.buyEndTime.toNumber()
        : undefined,
      buyStartTime: bscBaseData?.buyStartTime
        ? bscBaseData?.buyStartTime.toNumber()
        : undefined,
      claimOpenTime: activityClaimTime
        ? activityClaimTime.toNumber()
        : undefined,
    };

    try {
      const buyAmounts = userData?.buyAmounts;

      return {
        buyEndTime: defalutData.buyEndTime,
        buyStartTime: defalutData.buyStartTime,
        claimOpenTime: activityClaimTime
          ? activityClaimTime.toNumber()
          : undefined,
        myAmount:
          buyAmounts && !buyAmounts.isZero()
            ? tokenFloor(Number(getDisplayBalance(buyAmounts, 18, 2)), 2)
            : 0,
        totalAmount: defalutData.totalAmount,
        myPercentage:
          buyAmounts &&
          bscBaseData?.buyAmountCounts &&
          !bscBaseData?.buyAmountCounts.isZero() &&
          !buyAmounts.isZero()
            ? tokenFloor(
                buyAmounts
                  .mul(1e8)
                  .div(bscBaseData.buyAmountCounts)
                  .div(1e4)
                  .toNumber() / 100,
                2
              )
            : 0,
      };
    } catch (error) {
      console.error(error);
    }
    return defalutData;
  }, [activityClaimTime, bscBaseData, userData?.buyAmounts]);

  const { handleTransactionReceipt, handleTxConfirm } = useTx();

  const handleBuyTera = useCallback(
    ({
      cb,
      amount,
      loadingStart,
      loadingEnd,
    }: {
      amount: BigNumber;
      loadingStart: () => void;
      loadingEnd: () => void;
      cb: () => void;
    }) => {
      if (account) {
        handleTransactionReceipt(
          tiny?.bscBuyTera(amount),
          t("buy tera"),
          [amount].toString()
        )
          ?.then((tx) => {
            if (tx) {
              loadingStart();
              handleTxConfirm(tx.hash)
                .then(() => {
                  buyAmountCountsRefetch();
                  userDataRefetch();
                  cb();
                })
                .finally(() => {
                  loadingEnd();
                });
            }
          })
          .catch((err) => {});
      }
    },
    [
      account,
      handleTransactionReceipt,
      handleTxConfirm,
      buyAmountCountsRefetch,
      t,
      tiny,
      userDataRefetch,
    ]
  );

  const activeEnd = buyEndTime
    ? Math.floor(Date.now() / 1000) - buyEndTime > 0
    : // ? Math.floor(Date.now() / 1000) - 1688546779 > 0
      true;

  // console.log({
  //   myPercentage,
  //   myAmount,
  //   totalAmount,
  //   buyEndTime,
  //   buyStartTime,
  //   claimOpenTime,
  // });

  const { data: zkSyncDate, refetch: zkSyncDateRefetch } = useBatchNumber({});

  return {
    buyAmounts: userData?.buyAmounts,
    buyAmountCounts: bscBaseData?.buyAmountCounts,
    myPercentage,
    bscBaseData,
    myAmount,
    totalAmount,
    inputMinPurchase,
    handleBuyTera,
    // buyEndTime: 1688546779,
    buyEndTime,
    buyStartTime,
    activeEnd,
    // claimOpenTime: 1688546853,
    claimOpenTime,
    zkSyncDate,
  };
}

export default usePurchase;
