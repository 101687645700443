export enum ActivityKeys {
  // Purchase = "purchase",
  Airdrop = "airdrop",
}

export interface ActivitySetting {
  key: ActivityKeys;
  title: string;
  countDownTitle: string;
  countDown: number | undefined;
  icon: string | undefined;
  iconPre: string | undefined;
  children: JSX.Element | string;
  endDate: undefined | number;
  startDate: undefined | number;
  isZkSyncTime?: boolean;
}
