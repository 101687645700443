import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
  token: {
    fontFamily:''
    // colorPrimaryBorderHover: "#FEBE41",
    // colorBgContainer: "#443B2D",
    // colorPrimary: "#FEBE41",
    // colorWarning: "#443B2D",
  },
};
