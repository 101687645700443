import React from "react";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

import styled from "styled-components";

interface WalletCardProps {
  icon: React.ReactNode;
  onConnect: () => void;
  title: string;
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => {
  return (
    <StyledItem onClick={onConnect}>
      <StyledImgBox>{icon}</StyledImgBox>
      <StyledTitle>{title}</StyledTitle>
    </StyledItem>
  );
};

export default WalletCard;

const StyledTitle = styled.span`
  line-height: 0.833vw;
  margin-top: 0.365vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    line-height: 16px;
    margin-top: 7px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  width: 6.25vw;
  height: 4.58vw;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: #696969;
    font-size: 0.833vw;
    white-space: nowrap;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 100%;
    height: 85px;
    span {
      font-size: 12px;
    }
  }
`;

const StyledImgBox = styled.span`
  svg {
    width: 3.12vw;
    height: 3.12vw;
    border-radius: 0.52vw;
  }

  img {
    width: 3.12vw;
    height: 3.12vw;
    border-radius: 0.52vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    svg {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }
`;
