
// 2023-07-15 16:30:00
// export const airdropDate = 1689409800;
export const airdropDate = 1689109800;

export const whileUsers = {
  "0x1260A9A878eB414deefE0026Ae8E8F1E4b927559": 80137.1,
  "0x5963e683FFEEb3613CF2ea5bc3829a8F107ebb97": 63809.4,
  "0x7638584DD25FbC7B319EC0d4f5f3d85A2a5C2B01": 62679.04,
  "0xa04DE08AADf0AD9c112e07f5d44BCBd544720b95": 59838.04,
  "0xf88A7aca42b62c4EdDb885F918BA4d3480A38086": 30139.39,
  "0xead2e1CD98dA980865Cc9433c41FF011EB2E761c": 28561.78,
  "0xAe988EaEc132d9F5De508eB361603453D44AFD8e": 23929.94,
  "0xb937fb747eF9cb234D2Ad2fd8f94F1c6cF5851f1": 17480.8,
  "0x8858aAf03B08384699aAe81Af1Ff545f06d6a20c": 16793.66,
  "0xFeAA1594a6207E5D9120fD8BdC42BA8972637343": 16194.89,
  "0xB7897938E004C5761A40f057FB3d5539CF685517": 15456.54,
  "0x9E22EFf00a6eadD9B6819Ff536F1b8DBae057258": 14119.64,
  "0x532bEb3Aa55F5a0Fb558800aA99c522f22b61ADB": 13780.0,
  "0xc973010CD2484c3B49008ed963b5395D228bc58A": 13754.78,
  "0x0b42aace55A03679928A88aE6FB368558F645413": 12236.51,
  "0x28c695Abc5f28b72b90110C704C36290BdACB7fa": 10635.55,
  "0x1256510DfF6564CbF3E2035E4d1AF9AF4d961897": 10629.03,
  "0xfC9f874764BbFAD39269f418fC902f7a35402460": 10201.03,
  "0xE4580d46A3073dA0903fF476b82BC0F14c1F5570": 10013.71,
  "0x6275eaeDA4478b66222F6601Ca040ABD8B033b26": 9988.17,
  "0x3b0D3BED6A455873D87aDb350330aD1A4580763E": 9872.91,
  "0x0E2568Db767A7F8dF06D5b9837628F979Db4bB59": 9452.98,
  "0xddfba3aAD19d3f466472b5EA8A5b0F77E59b4fC6": 8494.95,
  "0xdE53b973b6361ADb9439e984b97787d1A517d283": 7803.06,
  "0xc35D8291C0025232319C175a5900bC4fE8aCa25d": 6920.19,
  "0xE245027a6eB031Db85b4E90ab6C043793dA6Ae64": 6478.42,
  "0x24C090d199E5330d968Ac013669fb75df6E4729A": 6147.5,
  "0x69663E693f72f3369D82854D185FCa133E053DD2": 6036.23,
  "0x0C62D23100A2C0de24dafB23b447F8f999ca45d5": 5634.43,
  "0xa5E5b5bA885E69BE5B46ea67D07bb2f2d0a6Decb": 5430.18,
  "0xB4F5bDF86f91c88e160eDd8cE69D81c38B5C6FDf": 5345.56,
  "0x39214591cf583A3F4808433BebD7153BFa27e56A": 5275.18,
  "0x0A14E3B14AE71d75a7551b4604Fd2247639A05Fa": 4683.3,
  "0x4b296808F414Ab3775889fa2863E1d73f958A58e": 4664.04,
  "0x75C26a3CF8Ffd6dF0d0b310ccB8387feF0e4Df48": 4617.65,
  "0xAC9a35C621bE68C7972baCDF299105cf2F46BE2c": 4549.58,
  "0x05B19d74AC16ee08Eae60a06989b3522a5BD6d78": 4365.11,
  "0xAA4Ae3D16770AEf95ed8D2E9a5851760B900Cafe": 4193.23,
  "0x55E3065c2914CDF5E55080971D7F6C85A342ad71": 4167.97,
  "0xF1809bA11E15198666BCDa1092152Be961FF41E3": 4108.97,
  "0xD59Df7e009e498d8b7A7fCD986edf764C458bb6f": 4076.87,
  "0x1797051765E18D95c408056975790541C8dFF9Ad": 4076.4,
  "0x7E8c820104f98C77A70C76dc43C1AAcD2f37c333": 3953.69,
  "0x988512A9e60a03B991FE7DE20EAB5433a234A953": 3902.52,
  "0x789Fd88a88716b3e4EF2cC6d41E7dBd1880AEd1b": 3873.92,
  "0xF49EAD5C090AEB69F6a5e18072753b7C3d53120a": 3867.7,
  "0xaD5c1fDFD5b776D40e22a1451C61Aec360029C06": 3830.9,
  "0xa4F5d08F6a4a3db7DD44aF560D9ef1d3eAb07a7F": 3741.54,
  "0x59F2d1F319e312E56e246A91677f4607C7F06138": 3576.26,
  "0xd3F47Fa661a8164D38b6394BF278Ac23700cFf52": 3448.57,
  "0xa0BA64595E51137626E5c4fF04f839E59BbBB385": 3444.3,
  "0x7baa5bF2eaa7DF0fe1ab64226688EAe4f46561Fa": 3363.24,
  "0xA1303521693fFEB3DCDA97B3C6c04c3B7B54a330": 3273.91,
  "0x84253b603f69663ba32b9a731C64Fcc217240A1E": 3247.68,
  "0x79d256D25c6DE402cD45375dD85e2aF34FF4D254": 3190.16,
  "0xb12B611D558BB8C65e0FfE2e0f5eC7C00FE48D05": 3096.42,
  "0x6504A77Cc87A0aaE26e836375176Dd62b2949aA7": 3006.59,
  "0xC512B6927CA73F5dE3655EAD8e00798873302ad7": 2872.45,
  "0x30c35950F2A8B8b76338bcd0b4d6fe67C98d2439": 2850.53,
  "0x5E4117829fD98B863a765cf93e33852E7bc4d538": 2716.01,
  "0x70577BfE9146939212090549C1870e930834cD2e": 2713.57,
  "0xcE04f45cFaC1D4Dab19ee1C40b9c9cF7DE156A9c": 2710.21,
  "0x4b3f6823A578399c37E173Ae9B2De31A3E6BFE96": 2709.41,
  "0x8F86affc827f070622bc6D6E33B07389BA4a8A93": 2676.31,
  "0xFdfC51f1b556499DdBa9a7B2Eb6C2233238aBD66": 2480.8,
  "0xaB51049B82cfe877cFF168Ed7eFC36cb22819841": 2405.92,
  "0x1836466F0b27896041EA06FA06C5C725A2Cd0141": 2395.5,
  "0x29263E20dB6C37411aD1f3bA34A6f006587CD74D": 2377.03,
  "0x2940FC70B1Fc102a26285f9bf12FdB768410f4f4": 2361.55,
  "0xCb2f17DcB52121F043e8FFb44b4079a10Ea734e1": 2280.2,
  "0x55024b076F46D0957645Fd0cF362307cDe5Da359": 2272.35,
  "0x451e63278161E60378b53BFFf6260968421A5B32": 2261.12,
  "0x561066656f0d7B44557Ed2Cf561BC677C6481AD8": 2251.5,
  "0xA5dbe53f830c601F88759a8f4F05f0eEB7b9B790": 2226.41,
  "0xa9BEE0E9B5ec83C31de36e4f00b3f08b7aF64fee": 2156.26,
  "0x28454Dd0943b0d1ab097aD7d2258cA3d71eE916c": 2069.43,
  "0xD83659E57c81d9cFaf40Cf9Dd77228E663d441Af": 2065.5,
  "0xDaeB5f4383cb7cCA5486A1FA6Cb84F3544fC3aA4": 2028.06,
  "0x9Be1425c46D41b9aFB59d0ef4509917cdf594B79": 1972.55,
  "0x77CeFfcB5f2B8533b9aD903431861487F98c34e8": 1915.38,
  "0xDd7A4fBb4fa9E6a0F9f1b0CDb9Dc45E0297Fa2da": 1847.94,
  "0x6D512ab877AaBE7B4471d4aA426D53D0ACA6401e": 1833.06,
  "0xd75036D64ae9c2E83EA6D69CfDf6eE39fA2C0D29": 1829.12,
  "0xe06882b0bbB10fa34017C9C3cE18ED1dda73d9BD": 1808.22,
  "0xAe6F231FE99630e72357b1D464b162Fb22401e95": 1795.22,
  "0xf192eaa0f174965C385ed615a18808B6a01af103": 1686.81,
  "0x8032d42B3b3Dd6051dA4D053A6d164Ad8539F106": 1670.93,
  "0x8b88b818E43d3255b0EaE0c343e06Dad2b199D14": 1669.73,
  "0xFcf1af7eB18c0a1C95677e53A97b19DF5224E6DB": 1666.81,
  "0x9661398c32f457c996F4c3123e9DCbD21280Fcb6": 1664.62,
  "0xdcC9501bf95Ac934BB94b4d799D3AFc6d08B0ff1": 1601.77,
  "0x67e30E9DAB5595ad5F5CCd81F06D518B47Ce837E": 1580.84,
  "0x816E9359Ea847839e0bE4C18218e6658a1Ac3662": 1551.12,
  "0x4E1786114315C529700a6a65388538F3645Cb4d2": 1522.29,
  "0x79B0eeB8c3D896de0d77102CF988fCCcb4BEf108": 1502.18,
  "0x229016F22E2C9eab63c14cd8905Ab397Fc9D0705": 1497.77,
  "0xCF91ECE28Fa9E80Ca47cf572E66F409d5A6d98Ed": 1479.57,
  "0x8DAb8fB08b71364FE74D5cD66B03a6680DAE1471": 1451.89,
  "0xAc1873Eab1BC12776eB006E7FFF134A7d684e8fa": 1437.32,
  "0xd7AD41b606b03456d3195F27b1928fF1A9b6aa38": 1434.99,
  "0x4A2b878bcdD91818A51bC804E3e156b902c3E782": 1412.88,
  "0x352835183FD5A7E4b1eFC8E139D9B0e3e2de0515": 1411.33,
  "0x7744446f4fBE3e660AE9206FB8b0D46b8D097176": 1407.52,
  "0xC4833ad2D4Ad72d8B2F1B5D9f3b1A342442637E5": 1403.16,
  "0x62b33d3130bACA314c5DF795915E6f0294D36d95": 1394.52,
  "0x250B9f5ceD1723Ed86262D3b5A4C5eC45c8e77a4": 1384.51,
  "0x02df3d24f8E673BCdcC1A617cd1247BaA19D1Da6": 1338.94,
  "0xA9993910d0de45268bCE07e93e21d570E417A173": 1322.13,
  "0xCEcB9A1ee60e922A44829619A2B26ac40B1Bf3C1": 1315.46,
  "0xdE94c9bE706Ee4499637716c2EaF1a39Cc83398e": 1304.24,
  "0xe584D4d4C0663D16262B75192143A3B6F59DC2b6": 1283.23,
  "0x4fC2Ac80956DCA5d6B094D60Db9955acC357d234": 1279.7,
  "0x0ba3e882E5A212Be3bB774B18c1D54fDD0B3e66c": 1260.74,
  "0xC805E7b96Bd259691F7C7095c7C93AB58b2697E4": 1252.63,
  "0xBe719aaa0eEcE60eaD1dec0DD52A938143600Cfa": 1247.76,
  "0xAD4E1d9a121B898270821843eD42BCC2D714A66B": 1247.15,
  "0xA8683B35bfF24242CE5f7d6d95735b2EF426d3a1": 1234.82,
  "0x1b70B010DC586e7DF419Ab1191e3cEc06EA24E9b": 1233.31,
  "0x298BBd28437ba7AAf0d5Ebe13d8cB375f1428808": 1225.28,
  "0x6dF841AD84CEf4B6c6374278E8530d1c66880b7F": 1223.12,
  "0x79b24c9f5f7E2aadB81fdc1Edb3e4cB874645E74": 1217.87,
  "0x717a1FffB01A7B00bF55dAEb2ee6b1Fd2d169B82": 1202.1,
  "0x563293ECC84C819f02Aac7e470f8bB4675AbfC58": 1174.49,
  "0x3b55436c2485468951aC1970706e00c19ABf9B41": 1164.29,
  "0xa395706A057f1b63356079de5E4D0564b7c58260": 1162.96,
  "0xAEBd66083A91609eD4a4476033571ff9188de43c": 1156.16,
  "0x9FC313752311b87da6f60eCeAFf7838a4665D836": 1150.19,
  "0xdD069e5929f1b95c7fc83Be5C456Be552B953774": 1150.08,
  "0x2de9119Fe747D0e3Ef612526166DDFdD32f6E71b": 1147.11,
  "0x5ee9A0e130cbe165097C3360f61b99010A3e52b0": 1143.76,
  "0x653F19c7866e2620ff6d77FC6F8EF4A492558C24": 1134.24,
  "0xf1863061e9643879345cF7542F5896b62bB4E823": 1109.33,
  "0x7b6377C374ef406fDeff0CC96ae328f9AE37f04d": 1107.06,
  "0xbE5BB1CD72EdCa447d28Eb35FC3c6F4912b998b1": 1094.47,
  "0xDD3077454400596f888f0615D1Db0c667d42595f": 1086.57,
  "0xB2251fC24F61BF1c697642235d1bfAbA899aE1A4": 1080.19,
  "0x6c3B79a8A12214c66b8Ef5dFF48510b501147Bb7": 1070.33,
  "0xA4Ece5c81345bf808f1547D6f8E2D6276779f6B1": 1069.48,
  "0x546a915286086a53F1Bc7a1D0B0a4486773Fd35F": 1066.76,
  "0x102fd44bD3591F1010501a6F7aDCaB62BB5E7FA4": 1060.56,
  "0x0268eEe6773e8Cfc8bd1412b8F3c796F492B6d5B": 1059.14,
  "0x99A7Da1b97dBB59bCa8eb28831e8B733b62eeCe2": 1042.88,
  "0x6a65cC8e3Aa35bd8c8a5BF6A2aA335A5EC73Cd1B": 1036.72,
  "0xDb29dc5c21765635fef56e8eeBDBa74C4452E671": 1021.24,
  "0x85105a83123a8C4EC5974741818F80b7248f1822": 1020.99,
  "0x3B14eF6013Be62772978843F207b131AD7E9ffe5": 1017.46,
  "0x8fe46B2E26EC29618449759453E3bd9F818b9940": 985.73,
  "0x49387bcF63A495F35cE214D8bc81Bb4A16893139": 984.25,
  "0x6d3630ee38Fb5375B0926e13ca425a272F835907": 945.45,
  "0x768F2A7CcdFDe9eBDFd5Cea8B635dd590Cb3A3F1": 930.69,
  "0xb5945E3336637855Ef008DacF195F5B7EE4445aA": 907.23,
  "0xF473A19AfBC87ad5f5a1DF11fCaB040E7D1C2E86": 898.49,
  "0xA231c48395577A4Ce3df48D6fC67c15CD81F0232": 897.56,
  "0x204903557E7923E3b35EAc4a2d6295e870357562": 897.49,
  "0x1Dcc04f2000eeC779E99784637993f53Bc46F334": 893.81,
  "0xdAe7f9e05b2B3AF48aEd885d5431620004A018B7": 891.55,
  "0x5983590e7bD3E00d2994335a450d1daFfdE67619": 881.15,
  "0x43f4b74d4fF6B5fb1e9f745572Fc01b0b39A2512": 865.9,
  "0xd38579533C7fcb6bB8b1475d2F1925dB6DF42bd7": 853.45,
  "0x870b486122facC5ABa88A6667218BE5f8F24DB06": 850.87,
  "0xcEd75c2813caD01E7c426B6F8D4267999F0c6952": 844.49,
  "0xdb33b55f964FC2C36151c277988F6a1A92FBe32D": 842.42,
  "0x2ff24171f54bD66F97992cF505c30B1bdBC5601b": 838.07,
  "0xDB24107179C55e267f4edda92C12D4f926070657": 834.76,
  "0xd37ec0D8C8F0039d0ed942b3723e893C19306df2": 826.53,
  "0x068Dc3f5340d36b183c5dB1D7d48413A9541622F": 824.55,
  "0x8dE496242093D7fdFaB037C0c33440b76D09d8f0": 804.0,
  "0x99CBB85E8c4d32388E9dD29c5cF24C92087B1F89": 803.63,
  "0x36871335e5b517a5252E8A1D3aE67A64DFbDe51D": 801.29,
  "0xd0cA9D88F6D7Fff4Ce6227C8082835800352A836": 800.17,
  "0x0c6366D233248728fd16Bb985CC3CD1D25edA0D7": 792.53,
  "0x246ce9c0e34E6223fFA50a5799C71493eDB3D7e3": 784.8,
  "0xfCf42BCd1612A669F36Ab90aA4FF0a681Aff3Af8": 784.46,
  "0xE4953E7e41a0c9423aeA2B9132ef4f9fc624E5f0": 777.32,
  "0x9a244e044C438f38674F66d05792191B2C1D7e45": 776.97,
  "0x0f8001e2aDFDc32F9c0F9A48daB1f8982AEbc734": 775.81,
  "0xB6F45D8E0C34eea9bA23ED04c49A8F548eFa91FD": 766.3,
  "0xc48474e0553f623280C907660fb6543D0F6cd809": 760.05,
  "0x14FF6E80a9AD54E713b6323CD65212E572180FbF": 757.37,
  "0x55b27db96E3433BA8038402a59781DEd6ba8DbB4": 756.63,
  "0x1e72D0Cc3a4D19a6751493DcBF4B3AC1c0ed2733": 749.54,
  "0x16947e49fB950c8Df78Fe0200B4d9cba12cC6a3F": 742.73,
  "0xD79dA94519cC40de4206C920Af5B9C7d0741e556": 736.67,
  "0x290c073d22d3A8F91D240009028eAb9C99ad5E2C": 731.88,
  "0x5242df062c22194396E28b44f05012f2c22f731b": 707.89,
  "0x10849C1A1Dba14A3C8FeE20BC8a2e875cfd43Eb8": 706.42,
  "0x096FCB30285afeE1bB3fB33804c43815AE0c6a43": 706.36,
  "0xa3233db42a00C18f5938cB20C04734AaA7d720A0": 698.79,
  "0x5A16C26E16e0f03341d3AB15b098EB87031Ca176": 698.22,
  "0xAE8d472B97965144df37ED01494c723711D4C0F3": 697.62,
  "0xf7f8953e979a6dC860929eFd7224d16fD52d6E4a": 689.05,
  "0x6faBD014f768DA6D47D23b844BaAdd3ef2b05F20": 689.04,
  "0x638BbA2710098Db1A5cB63Ac33667d9b8D47477A": 688.93,
  "0xAd6B6a5345492ae94F70c59A7Af96Bb78dABb00c": 678.48,
  "0x8A46A0FB50a829F56DfBB56a751c5920cFC2eB30": 677.91,
  "0x02697079c329d2Fc66b1b7d49A656F38f94aFBfa": 676.95,
  "0x6d357aE867Bdd1bb2B74be4B3DBeF036d03801E1": 670.78,
  "0x7BA3b6Dc13A370ed9F27849C0C8a79827a559eFe": 657.58,
  "0xf5C611f6b9053c3369c47D18e0BFBcf4b8eb720D": 651.6,
  "0xdAcf3DE40a68a75736D7E54da2F8A15ff306B386": 651.35,
  "0x7322322F67d3903fdb7A0B0e768d594C25509FC6": 648.91,
  "0x00577f4154a439cDD264041Ede477FBCBC98A816": 645.74,
  "0xE2BF454E044cD4e60f5bf66Ea639178Bc249aB9c": 639.01,
  "0x4d3aD73eCf5bF3655c5cfe511535E27192d91a22": 638.99,
  "0xBAD29a92ca1bEdCD6C17b3917BCD38D71Fd25370": 637.73,
  "0x02A9cc3a44B8669a0fb681B2B68229CFF2c06725": 630.9,
  "0x20aEaa2e7a038aDEad48EE2FD449e57ed57Eb0d5": 630.07,
  "0xb0E46311bA6836351452c4D3452737b98218AFA0": 629.57,
  "0x81785FA01d669Ce92aF89acEDcC4DE96A9341C30": 623.65,
  "0x20b0688984139D63D61447DFB1D2CC6D55388ec3": 621.31,
  "0xC29f49420eB4bF25e3A26b90EfD806f699804130": 609.9,
  "0x668CC8b2b7263cf340eE72b1A9b570260d8dD595": 608.47,
  "0x01EBb8C76f45461E1B930ba474BE33FbCebF7744": 607.45,
  "0xe77c25BC7ADb9C000028715d83b39A2646207bCE": 603.15,
  "0x04f5636b9F59169f2730dBbb35AcCc858cAC3BA4": 595.69,
  "0x75044A162361e6C9f0D7803daFD6b6dBd38E6666": 594.32,
  "0xfcedD570A58d4a0711C5fA2CA4CE1B8ae2aF2dF5": 593.4,
  "0xCb7D1eb7DE3AdB8EC377a1a9c58b62338d0Aa582": 590.02,
  "0x5aBD2bE32c865E9E6b7C7362B3681bD71987F46C": 588.09,
  "0x8e07dE0C7F1fb0c161BC1440c6B3d10033569479": 585.14,
  "0xC289919a5Dd0d80cb574b2EAA8Ae4206a5E04740": 583.43,
  "0xA17f9C245bBe90531C90e18AFcF84F0Edf4C6A89": 579.03,
  "0xFF45dBAA1bAf4F32f4067893EE24ADC81188eB00": 578.88,
  "0xE4100a748E618561B4ccB97E3a660fe3ba529c10": 573.33,
  "0x20E8fb38DfDd141Bd1d55ed1a56c35615AD893ba": 570.23,
  "0xc283303C4cEbF42B5dFb0Ff1475AA051Dc5C8D96": 567.67,
  "0xAf7992113747dDC7B09A4adDe3b3bE03b0025a60": 564.53,
  "0xFa523eF48BA74fC4f4cb7Ffc8F9dacB8216B6891": 551.02,
  "0x78F4CCE303D1bEeFFe8F6f5E10E13aa52d3eCcFA": 545.51,
  "0x81Ab9D56C5a7f268A9EA5e3fe233771423754895": 533.37,
  "0x28df96AC8634dac9a072DBE1ee45039bFE16e575": 530.9,
  "0xaa8d73C8B409591Eb2a96882DF249F871e6bf6c9": 524.36,
  "0xacB3a82fFa9f0816a25f8D87EA836189e321c893": 522.91,
  "0xc3e3C27B0111030a9D0C4E19E7DF6B0778f39A0E": 522.29,
  "0xbd108b19a3b2ADBeEEfd9E38585B92ACC639c093": 516.06,
  "0x1B1B9304cAB2D11C3694803D5472a50B38c2B241": 512.81,
  "0x9FAF2023BF5a34aA3ee9aAaB4ddD42fA6d07964D": 500.86,
  "0x457eCAE9f4E75312F763ef682882B48B9f8BeEfE": 500.27,
  "0xbf0b63b9a12Db59ECA44F7d9663838B4f9fdf698": 500.24,
  "0xF2D0fAA2276568714079908df70867b0B4Ab23de": 499.51,
  "0x90815650ce2077a93751eb46D48938d578d0A2AF": 496.62,
  "0x260F8854dA362a1F923D3Aa0Cb200292a98810Ac": 492.29,
  "0x57F50F14fcF11f8bf444647d4E619e78DE53D954": 488.0,
  "0x792eEBbBdAb3290B47d0daDf479595E11080cce8": 482.78,
  "0xf2Ee03d4F4883e1af4D8171Dd4d3D36a83CBfcD1": 479.42,
  "0x11aCf91e08170FB585334C02e9854CC0E6F5DE7b": 479.17,
  "0x78c9641161f2954D14EB8dC9485E5A999244D09A": 478.97,
  "0x3BFAEf38074155BAaDdc9d9C4750841E3bA809cD": 478.28,
  "0xadd05E00E77997a50DBCEdC710cC6a4Cf5943be5": 477.26,
  "0x4686547d8f61A5FCb419455ff7b34889180d54C6": 474.26,
  "0xC52a5A012Ea46aF41c2D55261C8A11910439d63C": 472.93,
  "0x64C025361BB6799EDa396dBCDbB04955eB4A1f14": 470.8,
  "0x0501670a4dDD0f52e781237e946F8c423Bd466ce": 463.05,
  "0xc0027e934b9b2d27c1e6183aC851b547dac28CF9": 456.89,
  "0x16e54B35D789832440ab47aE765E6a8098280676": 455.95,
  "0x36f763487e1082302a245992e1A30761551c1F70": 455.52,
  "0x5933B92d3c168835DAE8006dCdF5Ec92156239ac": 453.03,
  "0x492D972B0F8C1D1a207fF3AfB39F4022349c7490": 451.9,
  "0xeE59f75386eDd7Cb5E63a825F109b1dBFE16E145": 450.29,
  "0x044e11573869a43Fb2ad6bD2B4329CA274157909": 450.1,
  "0xB77115dDA1AC9Bb764B8A0376413b80812C26Ce3": 448.57,
  "0x315894e025BF6CBF236c0e9E6DAe53d4CF74e38D": 446.48,
  "0x561a9209f94949942c89D3158f3Cd177738392F2": 441.94,
  "0xd9B6a36Fb418b3f27072DAea054259661d4569bA": 439.53,
  "0x9d2fC9b46eA8a00dF94c4F2bdF8526cc9f50dbbF": 436.8,
  "0x829B3babFb85A4a2dd6024D4D38aAbeE099AD082": 431.96,
  "0x057418E3B31D474BA0d4c253536D0Afb55855180": 430.34,
  "0xC03587Bc6a4151DA1493A33504510D60800274AF": 428.37,
  "0xf959579eDf47f166f316Bc03887540D5d80AA302": 423.57,
  "0x45ae1b51FAdE15ecea89e464c1B3f91FeE97D95E": 415.33,
  "0xfb8000799413855938f464aEcE2e16d0Bc0e9E69": 414.78,
  "0xf712AD82c545DF326f9090cE9B3D0736f32F9a1C": 414.01,
  "0x1f8CE993638Ad8162FA8E307b8D8bC1B03c7824c": 409.85,
  "0xD49Fa8ac282579AB6Aa28194E684a4b85Ef43605": 406.94,
  "0xD974FA6d19bcFBD4B85781D1dADA5E96f8D886E4": 405.53,
  "0x0c07FF9b3068F6873455B89fB1F56be2a6bD7098": 402.32,
  "0x4F1529741891B69eeE50672e77907a0F24D29ec5": 397.62,
  "0xFC467e3c4a7A0321CfC09FdAf0ecc73945CA08aB": 394.95,
  "0x22f7a1b23eb77bE395e2F0D902A6d02C5409f71a": 394.71,
  "0x2b2C29058de59e21a427bE8CBB7959cE4f2243d3": 387.15,
  "0x55a3e5Aa703362A4e89655F9514890848f7F20bd": 386.14,
  "0x3A9cbE7Df08ceb0f9F29950573cBc85c407BFF4f": 386.04,
  "0xFd58Dc6053F3D72431fc07edEa96377d811DbAf3": 381.45,
  "0x100ed917CA3a9Ffd2580fc47D614e39F90c70CD8": 377.95,
  "0xaA81D29471dcDA3b8487C9cce108c2d05d9C37dA": 376.25,
  "0x05858A18F5720B753C4E688F5FB0A38134249aE9": 367.12,
  "0x75954F723f4B87e569975017915a6505E8451e58": 367.03,
  "0xA7400Ff2038d62348B48bba184aFf0f600Fb8ca8": 365.15,
  "0x97b9FaD4EBa011b5AC1CeF5aDf6a97FcA957508B": 365.08,
  "0xa27b5b7e280C9A2c8E1E081F43Fec709C0Fc7205": 364.17,
  "0xF5D1b7de117003dcFc1F663B5c53e64b75Ac4652": 363.46,
  "0x84106e9c52F940435DFD186ce06039a48fC7BeFF": 362.27,
  "0x45a4b4F54D00aE0833c3c8050b1ad4ff0206e2Bb": 362.13,
  "0xC6e9f65e25a218E805f1D8d5149Af8977Fb6A004": 361.97,
  "0x97739B1f2d8EB3876fA057eddc30651b0ad3d7FA": 361.86,
  "0xe48da6A8517aDa97AACAf4Cce84847A367506adB": 358.86,
  "0x1154F91833F886FEC048B05284ba7763ADba04bD": 357.56,
  "0xDA26415DE7157E2FcdC34527F342CD2e08bd6E04": 357.06,
  "0x1c47C8a43ba39817dFc5aE223C9D04ceE88A795E": 354.1,
  "0xB3677F6f3D2F529824a021db5D94B61ebBCE8563": 353.76,
  "0xa6E2A43e25Db947B475CF053fcE206Dc9f4A2EdD": 352.88,
  "0xfd2ed9F9782F616BaBf12fDcf3D37f551681FbC7": 351.91,
  "0xADd9665d40f6623d0e532214be6F7F72f2189d33": 350.83,
  "0x62FfC49E2530783C6b8600081383662FDa09E813": 350.74,
  "0x58F1fF615Bc40Ce8121ec0229E685A77C6d54421": 350.73,
  "0x082Ae8309E98A24dCCe82A029DC58a7fc6c3AbE1": 348.57,
  "0x8b868E8EAD3d98581D2863c9A766e25Fad383C30": 346.17,
  "0x70686F466e74250E50cE14d62Ab10dDB164Ff625": 342.81,
  "0x8ae22306E19655E079e5A2A2e9151563f8c06FEf": 341.82,
  "0x90C46B9c952d93EdfaD197E785293b8e64B068B4": 340.17,
  "0xfe4f8828Ccae8aD506a7546635b2fbBaDfEA9904": 336.32,
  "0xa699Cb656b82a74DF148ff22B115DB532403A60d": 335.96,
  "0x65AedDDfE397Ab69cD4bee92B06Fe30Df634A3c9": 333.18,
  "0x8707D4cB946C1c29c4A1373dA3330500507663f5": 332.9,
  "0x11533f82Ecfad3EefD307a403E6db93B43B01111": 319.85,
  "0x131aC9fc52CDEc2f1799f45d192A78F5fe82f7a1": 319.12,
  "0x8065999cce9A3D83925686FA9B589Ed2C0A63c24": 318.4,
  "0x6Ec60AF6D8989ab948b2974F15C9C66e64D36aA5": 317.77,
  "0x63a86f010079B20BF33BCf65B1e170dac6929cE0": 312.1,
  "0x3c0FE7CD7466491026414804a3cd589b05c2DbfE": 310.49,
  "0x99a0a172d13F4cC6f576d68C32210eD03E95Ddb9": 309.09,
  "0x8C0CE2976c75584e8F3A89E3977703aDf2683C65": 308.15,
  "0x2A999ded1D6d9Ae533bE2BBB1C81161d130d326D": 305.01,
  "0x24c26Ceed9af3D0e52514A39e60AAD91606DD799": 301.49,
  "0x2266A5480bDEf1A4c3b1844F1852800A1A90a56F": 301.38,
  "0x93DE758b91c160D789dd262070d2245B0380B2a3": 301.3,
  "0x25e6De1fc5701c1a1FAa4cEbcdDAd4C2522C1726": 297.07,
  "0xa02E1572E0A741DF882DdA20620B15551165E6f9": 292.39,
  "0x45c1c263fF2935F00ea4c216FA69225F16a3a3eE": 284.5,
  "0xfeA89E11f99D9593DD7C511b57AAEe2f8236238A": 282.39,
  "0xF928D09e08EF74408A8d19cFC3E27a403FdFAF81": 282.29,
  "0x477EB9dfeCE246a4A2E577C684a91c00e616b650": 277.08,
  "0x668a78711a5bF02dC2a857F0469f8dA0fD4C9995": 276.34,
  "0x3AbD19E8972db8aa2dbc45868FddAcB0e161DE2b": 276.22,
  "0x9263Cd9E10Dc3AfA87d44679796C324f79E5D2dF": 269.82,
  "0x1a1B78899B55fe1742dBAE946550223A0d2F8c9e": 269.36,
  "0x0871f1C4434D1de8cAf3F73ab39b30b46cD4FC1a": 267.4,
  "0x78C31441EA33F1b85a21A0D1e0d5f0Bfd123482a": 266.62,
  "0x636191e8D3f4EDcB972BBA66a4C91Fb313767714": 261.6,
  "0x797ddDd49E33B4cD3e9acaf9684D4Bd4C98fEF9e": 260.7,
  "0x994977535C8f518d8cfC82C297dC836bcB8Bbf05": 259.65,
  "0x1ea820Cc9bAa8fc6108a3Ae2EBa0794C5Ad10B79": 256.0,
  "0x6185511f66965EEe35e2E4faE032C2DA62DfF3e5": 253.28,
  "0x87F22A1e7d0E5a24901e52691B0084DB12C66Af9": 251.91,
  "0xE18598B01b12E7d4A35823139d0C5F6531b6030b": 251.51,
  "0x33FeD73cE0b769C59A7e61B08c936CDCE3F62Ed0": 250.18,
  "0x4E291351f1ec93Fe4bAFb041694D71C8622FDa8f": 250.01,
  "0xc15d343D8A69b9962719DaeC1F356Cb1bc8eF7EB": 249.45,
  "0xE9142D3AC492a8f80Ed27Fb606d48C03cFCEe993": 248.84,
  "0x7462fb4430B4B7D88608f28927B4a7C5CcD271C6": 246.04,
  "0xc401eDf816574D28501c01984F30e7c49810f8E5": 245.9,
  "0x60095164546CFEE752630AB98DCc5f5d119282df": 245.29,
  "0x2b20232861d2Faf4791864CE41dF5f4d74158A85": 243.33,
  "0x54dce2892B86A7aC61C7A9e949C1e7c95327B5b6": 242.87,
  "0x5235419466622C4aCA432753D814062442Acb392": 242.42,
  "0x99Cb9ae885Fc4c2da49c7f699CaEF8A406CF93e3": 240.91,
  "0xd043368F0464E7BEA2dE1D36a63e37A2F50DdB1a": 240.5,
  "0xDEfdac040a9CCC2e1794A4165DfD0452c551F0a6": 233.47,
  "0x589D0B870f8775b9d59BDE3B1C96314ba7f5E342": 231.64,
  "0x4f7500549292126D070F186dB4893819B4dDF739": 230.27,
  "0xb27Ebcd5981557B5ED50884e4cc50fA4341DdF54": 229.92,
  "0x7C88Ece28619102e4Edd85D21a69F1A6e0389887": 229.69,
  "0xB68672C4afC337dC73639aF2415e9Bd8374430B0": 228.84,
  "0x4c30Ed208C11e5f4a1cBE4c9f190F0F932B7fb9E": 228.11,
  "0x06CfD7c440a27c518a0c68119722a30bfC3bb6F2": 228.0,
  "0x9cC75Ff6A726725EFF730fa0f6f72Da6Da96dcAd": 227.55,
  "0xD71D868A10442164612a577d95E94DA5cA7A3d81": 227.23,
  "0x336C88275eee514b1725F0af2a321F9b4d0b061A": 226.08,
  "0x52BF354d5A3C39dcE9DE222A4384DEcb54aA9170": 226.05,
  "0x6dC734Fd0cf2a083245A3B1Bf60440993F32f739": 225.77,
  "0x83C1fB2b5C3f2FF7BDf37cd7CB4469045C369620": 225.43,
  "0x85D08455373079A10Ca6075C243D02D0501FdB28": 223.12,
  "0xcF3815bf0527F0900709B9D54Ae2c1B89f70Fb81": 220.26,
  "0xAAAdac2a76E150a0AAC6DB91dc9A177bAE857BFB": 218.78,
  "0x5fbc111B0f50B9445B9Abb8DE9980F417670cF81": 217.84,
  "0x10F0DDd106da1f170fA7a0D2e623F8335204Bb09": 217.4,
  "0x5871EC0133B6503BA11a134B1bE441858AE4b2d4": 216.4,
  "0x445C7eEd59328D8A25aa9Be4624Edf62f6430129": 213.73,
  "0x072D5c1988D6c8aD5A3B95BBb293a7cDD16A7bB2": 212.6,
  "0xF0a1078701525B5BDf1F46cA3e51ff77AFDc2cAD": 212.35,
  "0x5D2393F5D7515e3b8f19c92DB673106B58E23E4d": 209.36,
  "0x628b8A42e3fd494fc8e6dA90280D4aD91e28C88F": 208.69,
  "0x9d6228c8c28A228d4B124577a0a10d4226c3eA64": 207.87,
  "0xe5141f9132111085324e09eC41d92b03875fc0dF": 207.35,
  "0xB11CEa0397b2D0421e173faC985697580Cb33971": 205.24,
  "0xEf50BDefDf58dFA1e50A0984eF50352A98ED0D94": 203.92,
  "0x715BA7d377Ba8e12Fd45B3Eb5d33B89d581e0C33": 203.37,
  "0x1b40953944A0D0a1628642B1a1d773db5799057c": 201.43,
  "0xAF325368b094510D617f702F9166e8e292a3F541": 199.27,
  "0x7952EcC9Dc797def7bB93984A2C666528e92a3f2": 198.16,
  "0x51d645c8F29E2148abb49c95A1541aBaa92e3E36": 197.34,
  "0xf207d910E0810c712bAc7aEA6aC341C85dC7e7d6": 195.89,
  "0x21cf29f21b0E3979bb1b22af5F92D96d1dC55402": 195.72,
  "0x45CFbf5B6215cD0DE8c16bB864A3CE64898615aa": 195.05,
  "0x2FeC959474b3A0A40a01992c9385E53Cf33E6C12": 194.59,
  "0x63fa7f91Fa1c0f20E535168CBeF446eb4eDd7a5B": 194.47,
  "0x5A39F36E3f0cA7297eA5f86BF0990Ae3C8e9Db22": 193.33,
  "0x99EfFBd249305C5aCc37e9FBEF0a25239e4dfcEB": 190.08,
  "0x7F782756323F5B87c7513CbCd4eeaf9Aa95A1b1c": 188.91,
  "0x13fC74700Fd71a5201509222C574a02b69CB98a7": 185.61,
  "0x3702b9A4cCA50DC2c8D18060cAE31eE2D31B01a9": 184.32,
  "0xBCEf5eB420e48197D3352A447774DF7a837E8438": 183.2,
  "0xc892b4Cf20C0f2C49e8210b2600D0493CD4bFf0A": 183.05,
  "0x045f427a57b10FDC0E6B923951502000A4814fB5": 182.74,
  "0x824BcB8db7EFF0eeEA171344d2D6136AB8a3f20d": 182.5,
  "0x3f8d6EDFA35661771691424a6Be89EDEdd9C3F39": 181.93,
  "0x9C0E9a8E72AB71aAd7C85B3e1f87e3A70C8f4d14": 181.8,
  "0x7F7cB371c142183c401B64FB1E89a54BF08b559d": 181.12,
  "0x25A88efbDAdef5dA7967FcA48Ea01D86fE35A03E": 180.68,
  "0x5EcD6eFa84DcAc10ccf641cf3BCBB4aDe1d38B21": 180.53,
  "0x19B10F2d01eBDa786710167c5b72B16897b1f1c9": 180.36,
  "0x27237daBDd5DbAB7aC0bCc883221bD3009bC63cF": 179.48,
  "0x2b4230EBc3756c1F521dD6F196f46b71a2e1e8B6": 177.04,
  "0x938113Daf859F975345Dd7Ac9f7965BC2398070c": 175.86,
  "0x7905050E5E3DFA394b2dA93D61e752B3eD92D16b": 174.41,
  "0xdaAEB220e213136419aA3fF225Bbbf0DC4CDc4a6": 172.87,
  "0x17d4a0b5E6F6528c3dB732cDB367788fdD385a22": 172.48,
  "0x707eaefec6234a85594e40BaB08F6cc1B1616e81": 172.31,
  "0xcD2aaC884bA498F42860541d6598Bbb0F2181AB2": 171.73,
  "0x279CA547fbEAFb5B12a081520126415b22024f9F": 170.95,
  "0xAbc94cBc6Db885ced76E5DAAB604Ecb7741C2194": 170.5,
  "0x9d772A0436dCC222DF32e5cBb05B230619dff09D": 169.98,
  "0xc6903A578088721E46836Fea9D146999AAbe3e1b": 169.36,
  "0x5188891057aed6195D86c9d2673135704d192BF7": 169.16,
  "0x4D20f6225b7A36b1AfAfD72319410dE5ddD75C8D": 167.87,
  "0x1684ABEf206578D7a8979c7B1e89797354AE507A": 166.65,
  "0x3DB28B26Ac0478BFD1bA04dCbC2d15c1e186B6E0": 164.01,
  "0x0CfdbFDC5A528458B365deEBD669eC3Aa036B589": 162.38,
  "0x18386181bCD9F939B15c1aCF4a5525a82e398c3e": 159.89,
  "0xBD3AD4362CA6de8CbD79f5360e00a1a4982bb4AE": 159.16,
  "0x3af09f1E74567c159cEf5d57F4Bc0B22729AaAaa": 156.85,
  "0x56C19dE9D4E2F7c0Be31381344a0992E833BD2e5": 156.29,
  "0x1261Fc7391d632ab32D9b68F3eEAF83EAd74FEC5": 156.16,
  "0xf3968940cDB5ee5977A333E884C3105Cc0D22D02": 155.27,
  "0x49872C839a17ED119281a69043BAF4a0C370eec7": 154.52,
  "0x8dC557290Ee7bE6b65999fCc34b0aB778be65Fc3": 152.05,
  "0x3eF74359b5026379611f70E1B7545C43b1E095f1": 150.68,
  "0x5d05b8B51fd82af0F32201D4df6C1c9EDB78cECd": 150.03,
  "0x543b580cDFb7Bb878B29b7399e50cEf29aeA7027": 149.02,
  "0xc3a90E10D6f5B7265681a63C7355af5bdFB06072": 146.88,
  "0x6Ed5a75fc3A7fFF908470b0EC723B83A35C659Bd": 146.09,
  "0x5768295bb467B7e0552C399ceB5021Dd71EBb5D9": 145.96,
  "0xee93CdE6e4799B0FE5920C3f02e4cFEfbD39ff80": 145.75,
  "0x027af46123Cb769e2C37a670d1E653F62f86fb65": 144.55,
  "0x51d3d371e7a824A348A01723Cc23418D82e31775": 144.51,
  "0x9018dc334B95db62f8B47e88B1C54d22a7d70CDc": 144.32,
  "0x6ABAd6ab8DE3236A7d6A0e51F082089A07f05C78": 144.06,
  "0xFA9B512Be9D59B712b55b58df954eA14C99FBf5d": 143.92,
  "0x0E81Cc47921bb5569854d2240abdE0e8ef0aaA4B": 143.45,
  "0x20Ce40E8Bc99FE5aBab87a6D304BA5e0fBB94395": 142.92,
  "0x9EE51f4ee1D988dcD531a7D3498B076fD8e2c318": 142.84,
  "0x969a84269180db25Bd5634B5DBc70ce94Eb7bCE8": 141.9,
  "0xb12ce75e69E9bab742970d54185eBe2a56Aafd32": 141.07,
  "0x0d6e9fF4E1081648034302b663de3f5850062003": 140.44,
  "0xA352317E75441510E1AA31ac108214dBc5164D92": 140.29,
  "0xba0eb8B1962967de2bafb0f7Fb3994C3bAA6BfD4": 140.23,
  "0xA8e6A3Cc326B5e90600CA202e49798525e7D4072": 140.07,
  "0x15EEb6DCDf2ef7287eb4E534331a3B2c4dbdB4e5": 139.73,
  "0x783D0C20e01e768f2ce0e7099f2f4347E11E6d73": 139.51,
  "0x4D698502F5FbafD7ef1292Ce6A95F688Fe1d1705": 139.42,
  "0xA66FA85e9D32c5d853Dd81FCdc33140663fE239a": 139.08,
  "0x63fEd3fC9d312784a2E9e4EbfB7062b805E72356": 137.75,
  "0x8727529550eBC1aAF76A9485CA4f5A7fC77F699d": 137.39,
  "0x682340F541Aa8616eBB6E43782EE20eB6676AA81": 137.05,
  "0xfD2cA4c13023B3dbc83f96333E0c9D73ba786403": 136.22,
  "0x32C5260C6CA28e0096967ae74edb32fE51fbBbF3": 135.82,
  "0x716Dba430725Aa11BD91c5eF633Abd8572561a29": 135.47,
  "0x8b87Bf8F0AA8baE91a93C4aAd7c8dc656d706384": 135.15,
  "0x0A2467A9b723f0f35f531b1cc0B6f937862F2229": 133.38,
  "0x91a9709401Fd50049F1062E8645D5b23d80e8099": 132.49,
  "0x067d7c9C6af38d3fb21b43c5E81eA06236239C97": 132.43,
  "0x23b4b79a5C893b13Ab52E465eFEE2bB56AE03968": 131.82,
  "0xaB5D8d7f359E97AB26D3D8C2E04f787a71e5F060": 130.5,
  "0x08d1fF828F1f13D72B619CDFa91B1D4DD2A5BC63": 129.95,
  "0x14124cB4C08bF2aa71eBe2c450476054b11Ce0ef": 129.65,
  "0x554dD0dD3A960563463993234Fa6b4cd6542bA82": 129.51,
  "0xA297cc5F3Ac834FD032F125EF478Fc275895EDe6": 129.5,
  "0x1D79e9Cb678a67e7Bcb5047cC403B1CE0FABe896": 128.37,
  "0x48bF80bC91aD172597fEEe7d70Dd9aD282B7Ba8a": 127.63,
  "0x110d577fC4726FA92A5A8C3271d74d244835955a": 126.68,
  "0xE7fc16A34d10B305D650102EA21993C42e8FdDEe": 126.14,
  "0x6c081eB210082FEc9A553eE99fBf6E68B40031cc": 125.48,
  "0x04B684dA4D51CeFBf006E09D85b447aBF587A94B": 125.35,
  "0x25e6e8873Dbee091d55D2bA1cf9DC4cEf57C5c62": 125.12,
  "0x0025f33D7Cf2D2119Bc7A30bb1673aDA93fE86b2": 124.78,
  "0xb3Ad42bE1787F1526232e9417703F263CFD75806": 124.61,
  "0xC3EebA31d2536C7a81999dEce47Ab0184301A9EC": 124.49,
  "0x3718210d0C720D17d56F81Bd22653caEC7B9eA2e": 122.76,
  "0x75c45761AC2A06F3744Fed808383051cF79D0c2B": 121.5,
  "0xE42749ED1Cc7a8B4cA054f64A3D4Dd08ddF56bd1": 120.95,
  "0x538Cce1A5972f44a3F41Cef4ADA52DCC50E898b6": 120.53,
  "0x6E174039C06cd80C93D33054b741a7b7d839265E": 120.0,
  "0xbda69F72a4853881f0F33e2a62B21A117C207b6F": 118.87,
  "0x0A9c505122c1Fb92bfd59724386e4BfCbe014A6D": 118.38,
  "0x12a10DE798bBf9332b86c88dd4fe139BF3cc3784": 117.96,
  "0xbcBF663CBFcB2885e9DA3282D7BC98EaF7BAC271": 117.61,
  "0xa8c37E2165C133effebB5919eA362af78946c921": 117.2,
  "0x57acf20f42cA37b053f427b3CF8169FAe942736d": 117.11,
  "0xc81a64EEA77357eC4005B9Bbd55c3D7b7E1990AC": 116.84,
  "0x5a89919264Ee63E6eDBA72faf826e5001eB73744": 116.55,
  "0x43BAD7E68bed30c7665b9852Ccae810234A80000": 115.55,
  "0xCB8Cc33a019c902E92e86e49ca4106EeE1668F27": 115.48,
  "0xeC6540A1fD4BC3374666482d6DE9801fC26C0fD3": 114.95,
  "0x6451Abae542576b623feBF10554f3d9d0cd5D037": 113.29,
  "0x2b4f9Acdf03CFA4483542ddbFBb4BF096d30D59D": 112.92,
  "0x41b75B8635eDF48953c814a1111D3C1A91420F84": 112.68,
  "0x960cFC89666A69d4abbca2A93d8cb42922a65736": 112.57,
  "0x1f70306B3A9ACB75cA945A6ed00854a7963C48f2": 112.34,
  "0xA937Cadde298bC2124763FFaAA82846f0fB0E2F4": 109.82,
  "0xaEb32D85344c84Ae96381E5C9a283A2C2aE5779f": 109.56,
  "0xdF22A4d294C17fc0Be78dbDe75374a315bE1767F": 107.7,
  "0x4867e415F6079CB289b5006E3EA125102cBf2531": 106.66,
  "0xAA400938f8aFD2C5A1FB42336a4412E6b66e9121": 106.06,
  "0x17F2a33619b6Ee388fC15941A8A6de2473902624": 105.04,
  "0xd8C57F8D545D3C68691EeeB865012F352f80BF79": 104.8,
  "0xcc56956D7C0Dd9F9628ce82F6a21D50822962227": 104.11,
  "0x081BeA4394A741C1c49447B30999Df5746e5981B": 104.05,
  "0x3776261BB2E27445A48d362154aad33eF796FA8D": 103.29,
  "0x6a510034b85bde10ceEC2C4083d51ceb2B7eCCdD": 103.28,
  "0xe69C718380ca9cF0d61C9FBA10f2927BA56AF42C": 102.31,
  "0x70AdB1F4cF377D159Db0B301D66cF29B3933B673": 101.96,
  "0x67A194ff6f5b9C1fB6aD1bD67c6bdEeC866b1DCc": 100.93,
  "0x41E5fD9EC1dB51eECFB6F0FBdD690664D7860DE2": 100.87,
  "0x4740B331222f0dD0F776dEdFb7927449F4Dd4870": 100.56,
  "0xeE0a6fbe63dc53faa1F6360Fe63fD592ba042195": 100.12,
  "0xc71B39BC6a162352c2734B3BF23719e6923F438A": 99.94,
  "0x52849b1F5B9c935966995211DB4C1a38598F5D9a": 99.7,
  "0x9b75157C8458245b210987a2229250bd05484651": 99.6,
  "0xfC9bCA3Cd4BB756ae8766791373E074cbc721fd7": 99.57,
  "0x35f31EC28905e985755BE2793f26A806eD1A0415": 99.34,
  "0xE38435C390AbADA38Ef20451a1b216645ba9c7DE": 99.14,
  "0xF96680c1eddCbB0C853907ea6438C61D7bE88C01": 98.27,
  "0x1C8DE85472A80250193693ba53b5d73E00B5989B": 97.26,
  "0x4ffd741da9f921fCFdF8Ac4481eA1d0A547Ca444": 96.89,
  "0xC6AE15Ed1e1363A87FbefD97657f42800C98d9AA": 96.29,
  "0xd1653B0B28f46505BC50EA837fC2c9c1F01269e4": 95.6,
  "0xFD8A973F6c71b2c7c736e72b61Ef3B3660A80aBc": 95.1,
  "0x5fDf31DaDA8D6381FC52E9D6C61A6089fF2e9b57": 95.06,
  "0x72274C343d8d9Beb346e52CF1EeF1ff7899A9859": 94.75,
  "0x77aE665460D6D914646d9f2b1Ba7DBeca50C1989": 94.41,
  "0x88C73424d87859049eFFE3bD5deCE3b39D811AD7": 93.67,
  "0x20E0D8252Dd1094aab645B05B0BE0C8066D83B9D": 93.64,
  "0x2Fe80e4E41f83c64192d40ff5E5aBd1DEf913B01": 93.23,
  "0xA924Acf0c12b13844EA55296F250172ccBd4181e": 93.22,
  "0x818E9bD1A168847deF4D2E87cE34062b4ff51643": 92.8,
  "0x03b1b53d5d65e6553ce9E0a90a785c915481B5Dc": 92.34,
  "0x2221cC93ADCDa6100621fec244B2803F407f6ECC": 91.85,
  "0x6DeD8b7b39c0A2c73a34Ef3390c00DCCE04731d3": 91.39,
  "0x02CF1C5E83796B55B842498d1A57d924a4278a58": 91.35,
  "0x07d74dA5e3E2D6218c2aCA387BF1B7fEE0A3257c": 91.18,
  "0x031B4ce6962beB1abE152B16A0B03F0db411c182": 90.68,
  "0x972864Fb3D4d300e6BD84A21d811E690B2AF03a3": 90.54,
  "0x8099529B8823fAdb7a5440B3382A97FE74dFD261": 90.5,
  "0xe987909B64D402e56ad23d7C544Bd13A0e21229B": 90.45,
  "0x041CF10A0ce8069d2C064aCb067B59c345De34A4": 90.18,
  "0xE0DE5fB1Dd04164941c3e3aB3b592B04aa3810FF": 90.15,
  "0x5184D2Ad739DBa18Ab38e5E525CAFa6D2cBDEaFc": 90.1,
  "0x1f529dD60FCf509802f635b9aAD5215b61D42aD3": 89.92,
  "0x4e99BC23c290ab2596629ac2656Fc944d0001366": 88.74,
  "0x91c6f2E8f4317F2A8e030E01f328E855419B2002": 88.56,
  "0x48216B922277D3496FE0e458e5d2d47563142D2c": 87.84,
  "0xeC550cffe4a963dE212D15ea4e14388Db74fe621": 87.08,
  "0x3423447Dc84791Bb49CbD68e521EB5d0A0d7e001": 86.19,
  "0x47F6eb7325B02ba98b8d22280ca5bB3b14F5BA5f": 86.15,
  "0xb46Bbe83Db7847B7Aca6109351257cD0821C2f11": 85.72,
  "0x0E7c045ecC3432086a5D6DD45Fd7Ffbb7E5182C5": 85.43,
  "0x559E991FC4e0055c2aE37Dab37f7Abc41f8f590E": 84.32,
  "0x1866743D1894E15CDc4CdEf4804eB601Ce990865": 83.92,
  "0xBddc33a965703Fb327c9Ac7b9bB993F42327f0b4": 82.74,
  "0xd508615a42Ce897f44171429657b8FaA6F01f6fd": 82.7,
  "0x035F00d1d72fC35dE49F78f1dF9105af92485600": 82.43,
  "0x5A450d891f88568245a0a13332b707E9Ed73c2e6": 82.38,
  "0x151487568099898D41d254654D85F517A1408127": 82.23,
  "0xfdEc2DF0faE3635DF33fd95c2eF1F334ccE42A1C": 82.1,
  "0x776c9a312Bfc6E1d70Eeb1734df69e4Dd9B38B59": 81.9,
  "0x7d0F023593978D6a9ff353793F8156DFa8076206": 81.59,
  "0x2215DBDaf3F75B897f058892f30146112E89C5AA": 81.51,
  "0x7b3fd8dc7ff72bf7bd5f6008fbd5f5a99a71d445": 81.03,
  "0xD63cf47bb133d79d4137d843ca274fa0bB79da50": 81.02,
  "0xE3afa6ac85058a815d676EFD8198544a19Ada3f6": 80.44,
  "0x638E6B0F148eEAC3D8a6D2E75E0eB410D0a9f473": 79.99,
  "0x56Be4511ED2c6F38f5d21ee921ABF57CEd968085": 79.9,
  "0x790651142148F4131e6fe1fee9c1E1FfaFc7464C": 79.61,
  "0xEaB66ceA81eB77F5439724767797599790852003": 79.54,
  "0x7a9B702d241f4879283B20765B2787405b8DC3A7": 79.31,
  "0xBaB2314E06283Ea59be82e64aDa6e59FEFf87217": 79.23,
  "0x80F014276eF6f847e2096003EDD43D81071F55b2": 78.63,
  "0x5C13baB807754DB1fa5ed37E2D78f9fFf2994e01": 78.36,
  "0x69E7DB1b04530fb426D8c9a171212120bbE20415": 78.08,
  "0xE4983dEB8A0B7F901d2CC11a26BF46b584e2a685": 77.88,
  "0x60e57c4f251e874943462Cce788bB25121D4Dc66": 77.3,
  "0xC7d5cc5381463cF42E9b426010456ab02f1b0F11": 76.27,
  "0x2a8617156c7d41160763Ce186c0756EB0B89a563": 76.12,
  "0xc4DC2a36D88e73b38F3e2DD2F8115FA023506E56": 76.09,
  "0x92E87C731A44FeDF3dF6C95e64A30e86AE64D5d6": 75.76,
  "0xE770e5Fc0452875386F7531C24aA0E085E752E2a": 75.71,
  "0x047862412AF18dA4c549549630887dbA1AF6C0F2": 75.13,
  "0x1E9057222A226A275F777aFC204A57883a1EeFa8": 75.13,
  "0xCa840De67aA9294d3A930cca9D51806C24584dbA": 74.53,
  "0x86891f2a59dB09316379e33804b12Adac0F6DF31": 74.47,
  "0x4145E389F5960F89f94D067f3B746529B0319505": 73.89,
  "0x93310e36aAE7F2030e61E5562F5f1687d8fA2Af1": 73.53,
  "0x82B6a1DaaB0294Da1Bbb966DaD7fcB174446311D": 73.41,
  "0x7c75ce38922bA47f2F9a1CfD4A5d820688F84001": 73.05,
  "0x1327B287a048AFe8E75c6bFF2Bcbe6106698a9e5": 72.99,
  "0xDa470323134397D807bE96Da970E3Be7d1C1fd18": 72.53,
  "0x9D9B6fE6E15A6ADb2c4270Fc579b20dfB63bf015": 72.28,
  "0x9b20BA176b15A3A5Bd31fDCb2C4cB8c8f68C5317": 71.56,
  "0x08f75F547962935f85e0a611C2a01F8f21eBC933": 71.33,
  "0xfA379Be797Bd5A617D17515f03F85cb1321c4100": 71.23,
  "0x3285608186E212B2c866cc75dBE36870f65b4F8b": 70.7,
  "0x8AA3fdeFB72E8acefcb1E1fFa4EEa05C24F62aaa": 70.62,
  "0xee8040b167E1C6EAd40F2Dd5AfE9997045352958": 70.49,
  "0x5C9AA386B803f5255cA145d6b4C10eC734501926": 70.22,
  "0x6f07a26C7bF4ed24093944006C77733d06595666": 69.78,
  "0xdcB57eb18E59495Abd6B0F787F6B4E8ee53027E4": 69.73,
  "0x752cC00ed44288DeA95b96C582FfA3E416fF58bE": 69.54,
  "0x4305240B4F02eed79EfF99B8Ce49f4A78cf46a85": 69.15,
  "0xf0004e7ADB0F0534dD1f746e3E2E35D5737CD36d": 69.05,
  "0x7b39e4653C501D6FF3bEf86c05cc970e7CBeEA7E": 68.44,
  "0x88d99bd79790DC9adEd7cC8025989D528dC632E8": 68.27,
  "0xB09DD7fA11DACaf6614f0D4868d751771D01F4e5": 67.43,
  "0x48e3AE03563C459F57203ADA02554736E3fE4365": 67.33,
  "0x0EFF54a6710dd85E101321DC6E84417631664287": 67.26,
  "0x2cAF32fC1f81EeAae4c6c41aca53fAAd14258766": 65.81,
  "0x683B1b3b43EB81Cd49Cdf2CC4b0c6aC90d1a2222": 65.38,
  "0x620017337352cb5aa63bD359CD76f4e15923D7A2": 64.65,
  "0xf89A444A7B166a89b69d039B6bD20027c712604c": 64.6,
  "0xE62A72C3e73F00BFb427caB9e439aa0EbD6752c3": 64.45,
  "0xd4C35F43F1289Cc1002099C6d5F456a84fd7433b": 64.36,
  "0xdB30199C6a4ef96dABc892f0Bd384185CFF0Fec4": 64.14,
  "0x3b0f081700927865b4fB32DaFD1dC705585D7fd0": 63.97,
  "0xD6D62DbAa43c80D659c90F195423Dc0Ba4d385BA": 63.88,
  "0x4a9b4c92939073D3206F0863064554594D1903c2": 63.87,
  "0x00759f29483c469da554b10a8b3bb7e212c4ce97": 63.57,
  "0x473eF5211298f95001A6D1576dd354f78f5cbcD0": 63.57,
  "0xa153Fa0b66257D70734F6d53E8B20Cf2AD8c2FdA": 63.19,
  "0x09C9e2528C892C643b496C61099Decc28E4da33B": 62.65,
  "0x4652f190dB7B7eB191bf26f055afB5B26cB78418": 62.41,
  "0x150CAcCD922E1b2A5682832a0e961C6382A7c41f": 61.89,
  "0x4794a86494EC35CCDc37c7B8C1811E66397eb9c9": 61.86,
  "0xbA61B5bAcb535729f0A16296757a9DBA86Dd87AA": 61.54,
  "0xa9aaB19694fd5570CD1e6B33880cC51d7a4C7aa5": 61.19,
  "0xe9cd13F625ce0052e433f2450E948B17284d297D": 61.03,
  "0xC065cB22677aE43cB2006d81639B6B7d194E50A4": 60.98,
  "0x02686E8601D0d9dE634fd286e685A87ffc3B9885": 60.63,
  "0x79327de7257A0B322df96EA34F495326d98125BD": 60.35,
  "0x2299dC5AcA413459A09b603503Df7b2D71772306": 59.85,
  "0x3a9300E9d5FFF721D3d4e527A7e4962329A2634c": 59.68,
  "0x9778DDe45EeCf5ef0B9358102fb91277DfEc98aB": 59.58,
  "0xa3EFf7d0df152D153eD9ec2ba9D854dD0042C0d9": 59.23,
  "0x494C0374B84c90A1AbB8Bc8fda1d6cfA4e3e3Ca5": 59.19,
  "0x8F81FFdf9508BBAD50588Aa3b3f67AE4a604146B": 57.01,
  "0xd7a813A04E0Cc2A36e12fD0b8d154fB1CeEB2E54": 56.99,
  "0xB4D68116AE218855058D9456AA664C9b274e3870": 56.96,
  "0xAFf19096f23C5fa24e00BE692C54535667c2c7AD": 56.75,
  "0x0D070194fbc84c4960be82dc8e2b9134C8671E37": 56.75,
  "0xfCaa953eD7C71A01f1c7A95902473cCeF4acc8E1": 56.73,
  "0xA438f35c135A425CB71F734C541031aB80C1ac6d": 56.54,
  "0x055348936203fd3d06403F492D9dc23aC7db5A98": 56.18,
  "0x792672A3a048160bE2970087dF44F003B35e26b1": 56.0,
  "0xf54c8E533a9148c329d137e8D9b9B9e30952D14F": 55.84,
  "0x617e9fcaC64C06f7Ca67B97091D22F4a342c21C1": 55.68,
  "0x8277e13A4f948AB01Ce470f1dB04Fd9Cd76D7c5A": 55.54,
  "0x61c82E29EDE7661815e90fc4e9E5805114c5497c": 55.16,
  "0xdCC9d9c039E34340Eb63A0BC34345D44a1D49b6c": 54.72,
  "0x1b0bD2fA28f1819B8C5402C2364d40CC17E74f58": 54.44,
  "0x3E5FFD40a6592Aa088275B7943A9539a3b152293": 54.34,
  "0xDf3AdDefB91F7099b25Afb04Cbd1B9b4259Ea005": 53.63,
  "0x28312a9B4Ff8913cE00dbe4A82b41bC35e0B97e4": 53.34,
  "0x46B3232969Ecf6E09FaB27f940212439075F0c54": 53.19,
  "0xdfC72834211f608086a36f5B7030025D5D8D2C36": 52.98,
  "0x3b0Fb8863bB2cDd846759afC9B991F1D21D26D44": 52.93,
  "0x033b6BD42212981f9F4EC0c3f745befcaBA1ab33": 52.9,
  "0xcF09040eAf6DA1171AF8a5BfF7A98051165bd081": 52.53,
  "0xbCf9f91Ab739a51C681A042eCae17F751A80b930": 52.31,
  "0xcdb0E4850D6c0D63Dd466815e52CF2eb2178261E": 52.03,
  "0x1259351091A044D636Df5611B47Ab96e02695420": 51.97,
  "0x696575E974C1E715B046F083aCE9bbd3300D2EAF": 51.61,
  "0x0886494AC8E9Cd7f7023AaFcbEB25faFe2dBE58F": 51.07,
  "0x843854b4c2b82a8919E46E2DA9cD6E6420911b03": 50.54,
  "0x13E4Ef728494344C9989B2B59b9c0A9E8a34dC45": 50.47,
  "0x8fE5009CF4662292010B0271f76aa4F31a103e06": 50.08,
  "0x7B6598Eb0803219774f67c4613c62ecc7A25d219": 49.87,
  "0x9bdDFFc5FeDF1238088888eA9A1E6e93b4F2A20F": 49.85,
  "0x0Cf1ce269f8a7D40e58e372EDd9634763019f677": 49.79,
  "0x7670F0B5C983a5FF2177B771375300890E207541": 49.31,
  "0x1f436021760133AccA8F15642D3985C9f14c095f": 49.15,
  "0xbb39D1E187281A779259c0Ffc30C128fD6f91b22": 48.68,
  "0x08c92fF00A5288F55e42846F21Abd29e7bca628a": 48.5,
  "0xF39512Cb6f209c528738984603E13e4624037b60": 48.4,
  "0x28a89f2c42d0AcA2226762356F4b9a98d8bcd0eB": 48.04,
  "0x038C095A8510aE518b5c437511532Ce22a56f467": 47.9,
  "0xA56453B12E0E3772Dfc552710c974664fE568693": 47.67,
  "0xa208Bae387350848A07728c582Ac0cd61bc2918D": 47.44,
  "0x870111f9cc65FdBCb2218d4Dc24a05BCa1Db5c52": 47.42,
  "0x01a6CfD3cb1bD5C96CD7Ca468045843B5DD28689": 47.14,
  "0xE426ffAB85e9652EFf7B68249f85A18f1305B302": 47.11,
  "0x48a6b3E0717B6a5562cC849C9956A7Fe09D92Ce6": 46.9,
  "0x5d265A289F803af6Fa591fD783119fEB8168e342": 46.43,
  "0x0Cd6Fe507Bf0E4c1c49F5a2A317907Fb2a26756A": 46.19,
  "0xfF66c8d373D5D110DE32E5d3DB1ebF7eE749677B": 46.12,
  "0x5f20F1161fd8781aeaAAe51369A4D9E35C7C5C9c": 46.08,
  "0x4cacfe3DcB650e6f1E0f231B20A5319b1785a24a": 45.73,
  "0xAe256A5a3C3c0742437469115Aa0dF5382184F12": 45.47,
  "0x5804c3cCE54c9b0Cf4c3A1c3F539b7729f80ae42": 45.35,
  "0x5B4607D4F5D36E0cBD7A56B78845bDdFD6bd80aD": 45.27,
  "0x332aae301ddE7AD47c27CC526FB44045a2F0FF36": 45.01,
  "0x89992E2e31eA9B78fF21c9e9A9944BEE12434E34": 44.83,
  "0x6dA67F7aDBb677a4312b2AcF9B3147eDC984F3Ee": 44.7,
  "0x26a77e4AEAa37a4CD0f1824df78bdDDfC898b808": 44.7,
  "0x544b9B4c46CF44F07088dF0A334E36C75E40c3cc": 44.66,
  "0x9d3e12D2CA8ba19Ddc888D89636bd485E2C2c4f5": 44.57,
  "0xFb01b006c054B6a3c94A007b187d5DA7E99580b3": 44.38,
  "0xE7c368a738195bBDAA006A7079d6F6285E40e3ce": 44.26,
  "0xA826511800d6D959BFdcB878D782A501E0Fd9907": 43.92,
  "0x155D495BB7F012e71d2DE65c0C5B3636e4BFe786": 43.75,
  "0x97bF13E5B525FF9F53E0920c2144da03B5A6891a": 43.42,
  "0xB7D30Dc70eB99821C71e3B9CFC375B85b08012EF": 43.31,
  "0x8EA9a0E921073dF34109D45bA17fe594e71dc71c": 43.13,
  "0x97C3B121c6D27Ae48E45787A495939a9215Be42D": 43.02,
  "0x91687ac0Ee81f454b63903baE37c8217b346917A": 43.01,
  "0x1f74886912F057Ed98CD1E5c38AE904C6543Cd26": 42.77,
  "0xfaFB1fCf2252A1836cB09cd5027aBd42aFfccAF4": 42.69,
  "0x02d3f530E02F4Bd1AfCdc8347c1187733456e82B": 42.68,
  "0x4799906AdF8097B60fA01BbA56c200c8E24C59C3": 42.48,
  "0xfCc5FA88fd13844cCd053A7B2f911b3C616a35b6": 41.89,
  "0xe97018CbeFF63dC0694ab592C05D1BD9C8e043AA": 41.85,
  "0x5bD4edA695A834777a7769Cf0501b23543B6EFF4": 41.78,
  "0x0807A1Bc0d562A185d4038812C884858770Fccb4": 41.71,
  "0xe9F03ac9b916Ef80c44B9839e1CbD48CC319E44a": 41.61,
  "0xA2eA63CFd6a608ff36A8330bc09dE99184aD7E83": 41.53,
  "0x2F9008D1E0F606A09ef10984529072d38B3D6258": 41.46,
  "0x4bC8d6cd2AB016C25bDb9B6423f2EA5651765Fb0": 41.39,
  "0xe788EB0b6249bf566De5E1abdB55E5F0ef12ab93": 41.34,
  "0x86958aDa90CBf617FBb95b96DaA30b7C09c010fe": 41.29,
  "0x5041b8098488A9B127C2F08d14529120a3BdCc32": 41.24,
  "0xB20A574Ee3Af4b5649CA820b5b571A9D0e52EEa2": 40.56,
  "0x8D4b75A04bE4Dd280f59F1a046CA26cF66f789A0": 40.51,
  "0xd666e0C97d7273757d33A530e3b283aC3358714c": 39.69,
  "0xe82a73D93660748836ad78A663122D6048F681BD": 39.57,
  "0x8fEc443cDF740716d89c59AE7fc73002cA044444": 39.38,
  "0xe7DfD00c497867d1959fc1b8314346C19b10594d": 39.04,
  "0x7eE69e99d2baB334dB25071f1AE43c8bb13e0D1d": 38.78,
  "0xa103eCb8Ff0EEB08F6710E615cd8BBEd373573aB": 38.42,
  "0x1b3a4A1bDCE209e54f4Be9B183038CE1AdDED643": 38.35,
  "0x779170ECE7ae21F8Bb5D710571c9Af40F62131F3": 38.11,
  "0x4E764ff2867617B3742D333ADf7a9319d2dA2b20": 38.05,
  "0xA746E646eDB80227328c39a5b6CbE3a2caf53FB5": 37.62,
  "0x7357878fa39021464FA9DEebf4Ced7f3189167a3": 37.41,
  "0x0c071D6dc3eB99a16bc1dE28c2475DF6415F2e1d": 37.39,
  "0xdc5BB85Bf763c5950471112C2AE73672aCCE99ba": 37.23,
  "0xdBA8cf791FE0C3d5460757C8E91aEB4E9e63e264": 36.53,
  "0xF95Af5e79aA99266304c0f1D425137D5211F7eBc": 36.24,
  "0x4eace8ddD5D36697f18E4386A6B107735dB7f482": 36.22,
  "0xf682b3203523419aC637ca2c6aa0b71dEebF91c3": 36.12,
  "0xD46d17d9b1837b3b1c9Cf141a19552A6F580DA9F": 36.07,
  "0x8344F3F7F060D05725E6BB4058d68Dd809dBdc5f": 36.07,
  "0x16bE593A80a876B01B3f2dDa88bCCabDE7b750Af": 35.87,
  "0x129bF55F7431476C88eddfF7Fe7eA4f4672D25CD": 35.83,
  "0x753E3522891ab355767DD1B4f0442B6606020cA9": 35.34,
  "0xeF8c798b48708e30357862A9BFA368Ee09609AA5": 35.24,
  "0x5B288A27208EaF907fAb3730B7b0CD9D3De33986": 35.06,
  "0x3119fe4ac4e389e0850515036f2A7DB53503f9f6": 34.56,
  "0x351b718AE571e6F24587766f7dc4A521d18dE5FD": 34.41,
  "0xd1B987c4B48C9faCF1f3f8fA064cB8d83D2BA57b": 34.26,
  "0xab5adea6069260589ff5d3d13b0ccD86C0b394Aa": 33.99,
  "0x3987b5d49BEfda3920802e1a266101127E517c79": 33.94,
  "0xB67e703b6a20DF731acA6C4a17b5FA1F8B30D2Bd": 33.85,
  "0xee9bA41C32662333A0c0F2d43EE5cF7CAb553052": 33.65,
  "0x7f609B791266361303FD9D97fb11DAc58df3cD9D": 33.62,
  "0x53210945003A27420A7630CBCe6ee726f7dD15AB": 33.37,
  "0x638dd8C5d4ADC461cD30c356c0EF2094E8aBdB95": 33.12,
  "0xB7831d978dD131019703C894439a1A3A5902BCeC": 33.11,
  "0x6997A328Fe8Ed4D582f1F0a639442E6d32e8aDD1": 33.06,
  "0x0a30ABa35C6813Ef8Dda492F9214a4bbb9130564": 32.93,
  "0xf98D7a3D72C2b4991C7C27Ea469ab121aB011c09": 32.9,
  "0xb2e747f27FEBE9ff974150E114e5597Df2eD0127": 32.56,
  "0xD25cf5b64575FB157ba96a109E685302bA91B0F3": 32.53,
  "0xCab4f7c27b039Ff1eD29935a5420beCf7c1C252d": 32.53,
  "0x0E19a15e001f00c8D6798ee9Ad6d54ee103D3A17": 32.5,
  "0xc54bD256035b2E822E1013489448d822DDf79f06": 32.25,
  "0x915EE5DdE23b57ae9998f3d310b2398d6CBE3DdF": 32.09,
  "0x67a58c5938B0cF1D619f78c8b8A5427e5A900770": 31.84,
  "0x189d4add66f54254337af767539703b14755d079": 31.79,
  "0x9569f33AE9146DdEAb28afD28a2dD2DA8a28d92A": 31.77,
  "0x5311bd63143876cC1Df9A5C1a84D648a3C7Be7Fb": 31.33,
  "0x42838970285c5Fef7E2F408A059eB6410bC60518": 31.29,
  "0xe34781e2556Ae01DA7b93Fc5534C974d030b4EF7": 31.27,
  "0x66b68B1E0e4acC219D2F6C401aB90643dbc215df": 31.12,
  "0x328B2adBA9467a120172c780c908A9AA13cC1323": 31.12,
  "0xC38D812ea29e1D2C76B7228543822181BA415f22": 31.11,
  "0xF18204C3A359837533D1E4D632A9e2EB1Fb1bd3C": 30.96,
  "0x94b6DcF72Ecaae9CE0f2a00188E26f5391993E94": 30.93,
  "0x21623A8FFe9d5fd818fe4F4388B49D4F8c7C37eC": 30.56,
  "0xD29210e33e494E65b540e46F236FfdAc5177F4C4": 30.41,
  "0xe21AB7284e642F1C50D4D4dB710A0f6B4dAB40B8": 30.23,
  "0xE85792dd8e3Ae18Dd0073F3B35182815289124A3": 30.09,
  "0xa4D2EA467CFE319A0f109c3e5C02E5Db3622cCD0": 30.0,
  "0xf17368dbCd869cC3D437f2c0f6452A21C73716E5": 29.92,
  "0xbA387EAA6296e93dF1C4894281d688d465d36675": 29.91,
  "0xB862B6caE00d99026A78d397f665a92d8A9599d9": 29.76,
  "0x0D8A35E1e10C4d9484de56060114Fa9317c01823": 29.43,
  "0xB1E817E0148d4648cA71E1caE9301D3F9Fcc0B4D": 29.04,
  "0xCD0C31E3050E6a69E66f3E10D4B496054A26662E": 28.7,
  "0x8046E165900C722EBccEE319bCc28b23BDF7266e": 28.63,
  "0x77811eBA6808f8073cbB9eefa884Ce43389DA248": 28.49,
  "0xe7AFc1509eA558A1ea3a25e6F26Ee018D23ec878": 28.3,
  "0xE623C6931Acd268ea6932287db9ea3672B114185": 27.91,
  "0x282238B5285D730912cbD51E50f1Cab68d57f504": 27.62,
  "0x4678E09968Ff5F14557a695b3e3C01f5F812e25e": 27.58,
  "0x6E58bDEe94a421f967Ad93a0C937681E9C75Aafc": 27.51,
  "0x616802d1267bbDEfcd43E9eE702f16Ceef6f255B": 27.27,
  "0x811B903236CbBD8B370465115580C24e003613B9": 26.85,
  "0x5e7EDd152F0278F8196e870fD62CFA154E945B76": 26.74,
  "0x483EBC461cDB1860c169D33392f2C51b8017a10a": 26.64,
  "0x0dB32bc64cebAd47ccfcDF7417eF195FeaBA93FA": 26.57,
  "0x8a719f4409867b30C25e92A924c71c8d7e852B0e": 26.47,
  "0x0bF1332CD9333145e536a47b40253F91c4c3Aad1": 26.44,
  "0x0b0f91611eED5036c5A522bd26ab0F10435Eb824": 26.35,
  "0x16CBedD9875907Fab04fe7bFd4697C6cb742d351": 26.33,
  "0x819f9d6Dc709b129f19995d60c55436429520e8C": 26.29,
  "0xB08bC161298C3777AfC84D24539103E4cD1f1E17": 26.27,
  "0x85c47c914e2ED012024aAD7518A9e5Bbc7c49535": 26.07,
  "0xDDfB5ba3D9Ed3a78F0d7B98BD1375d908F0d42E1": 26.07,
  "0xa0C3de76f5B05AeD05C35F81b023b390e6489785": 25.88,
  "0xC401806DAd7D42664D9907744133cfe698127076": 25.79,
  "0x62ea46F8c4D8B42383B52a727f62cDFb04c02288": 25.62,
  "0x15e899fb33842F567EF57a356E9d933DcD18E91B": 25.53,
  "0x9FC8d79454cA5861d4B79555d126F6d4383A3Fb5": 25.1,
  "0x5193d22cDeF3C11A168f2046bF665A009b474236": 25.03,
  "0xd17Da2ED967D5BC022AFa7fD77C9ea64c5872103": 25.0,
  "0x9cc499C475eDe61FB84efb5562a46Ca1D4CF4987": 24.83,
  "0xD9cD71325c6fE5FA489e3769d279a8be5E30d9Ea": 24.08,
  "0xBb9dc7c99B1C9b830fAf68088441b7894F2a40B5": 24.03,
  "0x9b08978B956dDCb3Ba4497C0dBf56B516Caf6671": 23.39,
  "0x5A4f1A51C332BeEDa547660D6f5913886702F592": 23.38,
  "0x523B9D9f961643950c5AB61506b711BD845f5Dd2": 23.08,
  "0xb8d29DfF28345273eaBB2Bc8e189fa15b731122B": 23.04,
  "0x74Ef18E52be2011483885aCE3194902D45fcc249": 22.98,
  "0x6A761309eA880Af07fDa042a321Da96715a1C32A": 22.97,
  "0x0264736E57E00D23232a1094A4126568A331bB29": 22.95,
  "0x97056f5365081B7015616aBCa33D9080a8d01F22": 22.78,
  "0x6dABC4CF2c2E9E709b8043cf97c3667E6F1F41B8": 22.53,
  "0xe40731203Ac46A65f9287F6bc4834AFd189BB651": 22.5,
  "0xeD366279ebce224b6b90B40DE017fc192cb0cd77": 22.5,
  "0x5fc33980320aC68154F4150e6d65FCB65F6A8d84": 22.48,
  "0x27e014Fb1Baf5cd19fE93aEfad1AD4e8D47Fd2f7": 22.32,
  "0xECf7066f927D2B63DE0Bd33e6E11AA897E6730f1": 22.29,
  "0xc755968df25d44056a7b00Cd7193C598639D7dD6": 22.2,
  "0x6DB0D1F428C327a4beCA697fe48253e4589F86D5": 22.2,
  "0x730260f567C89265A34465F18D5D2cE6b97B5Bd7": 22.17,
  "0x4Dfaf097Ab1b9354B139025ACAAF301fdD5CB637": 22.04,
  "0xCdB6811AC87766F545770903EA652F0EE966c4D4": 21.99,
  "0x9366f4A52C2ee0FA36394cc1950e797CE48A9AB1": 21.69,
  "0x36ccBb9dDE3D056a34327e41a28f49B83c8Aa5b8": 21.65,
  "0x340fbD6bA5EB53376f3af940971707a46EE577A8": 21.61,
  "0x641531D3Ecf42Cc7D70F4DEa34e04C9283c379CB": 21.59,
  "0xf6604B7963B2604487ba4358b99772e9319d9eBd": 21.47,
  "0x67a8B83794a3D35274c1Cf556D93F6333054F9C1": 21.42,
  "0x024eE903B6799321Ae752e9696ceb09ed9b0D83b": 21.41,
  "0xE5C443893C7DbC90E55Fb848957e14D1aA39A2E2": 21.29,
  "0xd04b042677D2134a8bc199D00B2d9c4A84871bC6": 21.08,
  "0xCd0d090a7720DFfdBFb9c06F5509aB7A2a42a44E": 20.98,
  "0x021389A7784176D43a0ba233150cbd2B1F8f5C3D": 20.97,
  "0x15a326a01E66df859b59dADE8d6dA69e63964126": 20.96,
  "0x44ED1f727bF5B9B5a0c7B051eD616dDD31615D44": 20.95,
  "0x166d247b9696cBDeBc17E92C9ef6993A2EF2F5Cb": 20.84,
  "0x08eE58D678ed4aC4b6B79e2Fc7E01dF4453380f1": 20.8,
  "0x2253CbC9C3c5F25A47724e72D371E8C969CB383B": 20.79,
  "0x937b6f3BD06a42341A544881686B841607269ee1": 20.79,
  "0x35219A7FB6B81494be9343cbD75aAc74491eFa62": 20.78,
  "0x173596b0199D07dc82ecc2639E6AaCBC2AA11b9A": 20.78,
  "0x1bD44EF34cf250e448d130621E3D65e301ED1C74": 20.63,
  "0x2f2744F388d4c79944aB30908ab722BfdAc05E97": 20.53,
  "0x799695D47b16C54DbeF430a02dae3f1e9842aCE7": 20.53,
  "0x8f2b19EC85c273731Ba099B47286afC62E6aE811": 20.43,
  "0xd502cbb5698514bb678A7Cfc71c41ce4643987b8": 20.31,
  "0xbCB64E76711B55402eFC500ff3EFEb66b0d5d105": 20.28,
  "0xF52aF0222c03d9Ac6c95e9607e07D0dCF7eF29cE": 20.18,
  "0x3f5bfB533873E99807E25a66F1a78Eb2a07A73c6": 20.11,
  "0xC40728704Ca4C79Dc426c5C7D579deaE12666784": 20.04,
  "0x4C0E99e7f79A5Dd9562154AA25b1EF809138D42A": 20.0,
  "0x672D73828B367fa787d96cc122088B198D058869": 19.88,
  "0x73433aF37f10BA46674cCE9F86cfD26C2cD621eC": 19.85,
  "0x4d784514BD8E78b2D59f16a029AF3De4AA6B16F9": 19.84,
  "0x28303D7B364972cf7C09Baf9137b6937DC627316": 19.82,
  "0x73F8aAD297d5264E259C94005F0cDF546Ad89c31": 19.81,
  "0x62CB797eE74A5851dE6C5a0c293eA39e428f4EB6": 19.8,
  "0xa29B4174D4728BBc20A3a52C7003487A50e62b42": 19.72,
  "0xF1655E8a908F3722902DCDF0553755BC53cc262e": 19.72,
  "0x454834CC4d3D05006Bc83Ba1eC65322351BD2dDF": 19.56,
  "0x9936A768bC8239DBC899d96A27CEBa28b36D8378": 19.41,
  "0x6b9Fe0Cfd371a6902dA050349F2CcaC7d4b818a3": 19.4,
  "0x136E6F4c7212fB02f4f91656a3d5D554877E45e9": 19.36,
  "0xB6052D557B5F1Ccb463DDf790d282AF095FaB57F": 19.35,
  "0xE9d5f34717A9ca3c7480C2955a8321240E5Cfa6b": 19.2,
  "0x497502DE4D431BFF4faF2e469e97e4f3314db2A6": 19.07,
  "0x6CE59b91E81fD96cc9fa1fF9DefaF14ED21B097f": 19.05,
  "0x0958591B8Fe6e9Eb6e8dF23d0C67712293ED4348": 19.05,
  "0x4f6FB33aB8E545EF4344f4De0D8E3bc599957669": 19.0,
  "0xe652402fe3d2e2D9aa9FdEb7bD7Fc0bA7460E89D": 18.96,
  "0xa8d6F4973307507139CA8Bd8Bd9c22F62bb9e30F": 18.91,
  "0xA88d125bFe5C1e55320deaA2800804C681c5dE1b": 18.9,
  "0x15C32e9Ac56F263eED1D16D1cb07AaD15Bc6FD97": 18.7,
  "0x9EeC0F43Df5eB7b8A8abd09F586EF75B83046f69": 18.67,
  "0x0D8E8DBb91E63e220696Dfcb26152d5199886F4D": 18.65,
  "0xDaDCEA29ef87b5F664BB8B39ad17AFc464405bF1": 18.57,
  "0xCe6CA228b2640D935011CFCFe53a6ef2D02ED0Bb": 18.57,
  "0x3EB1686b9D0001F1703717E2362EeB3553A64060": 18.42,
  "0x2081E006C335Fd9Ce5eD75f6863295860b99876F": 18.36,
  "0xE8475758990C86F2Dc46eD7928687a81F1Fe85D3": 18.35,
  "0xe61F0839C287F9e60ACa56AE6515bba566eE3f69": 18.23,
  "0x810BBcc6f31411C10653509C149E8B32006b1900": 18.16,
  "0xa5C071b454E4C40EA958EBE7476867B727c90988": 17.96,
  "0x7d8B26c9e709cE7251669dDe5d15B7C2Be387a3E": 17.96,
  "0x06B1767503aEC0d3E827CD27BB016F00f1Cec862": 17.94,
  "0xfbB89E7f8316E92b6a1668028799b5064d5a072D": 17.81,
  "0xD12fF17B6487adF609bfdbD994E22eE71f40dE04": 17.74,
  "0x8a35fab03AbC5637045cDD208Ab73B07D61ffA04": 17.66,
  "0xb64Db7135729d1A8d11E26472e3d06B3118bDD5D": 17.54,
  "0x1e5052C49E8D84a7f8bCcFFe35826091c529254D": 17.54,
  "0x164d0A2768C503035CD7f501eFE6981A0469D3eC": 17.52,
  "0x8B5BFE6ab5896F02ff23Bf0ABd825a907eb3Ba96": 17.48,
  "0x31DF5d55C422e3ccE91468cAabE8E794e5fE691c": 17.3,
  "0x029C657568769ca57693333244333B8d12b8d214": 17.23,
  "0x921E7Adf3A5AA8472b8aab1De71aDd7EB45241f9": 17.2,
  "0x6Cda306A4e96afCfe2dADae695e968321929a108": 17.2,
  "0x76Eb9D2fA069fFE0E606fD169683C64E810127A4": 17.11,
  "0x295373A4FAEcddD88Bfa975B87e5582d1A87e8D2": 17.02,
  "0x0487eD4a281e474dfC8887E11217f7743dEd9a33": 17.02,
  "0xF8975260FA837c7f31383d51Ff5BB74A9aA7073d": 17.0,
  "0x80655aF80ED3fb35D94Dd7F09d1f96b5a460c196": 16.95,
  "0xD7aa301A411Cb75676FF8f8686e9eD1Ebce4450F": 16.88,
  "0x640E7E3942E3dCeA42c11292994BE25c04090749": 16.78,
  "0x7F13570eC92651dbEb060D5aB9AfF6c38e09878E": 16.56,
  "0xb7645e82fdB6578213B34301fE4d1127808Ad538": 16.54,
  "0x05764ede4bb1CfCa8ddD3B5a268d399e09c546aa": 16.51,
  "0x19c9f4984197FFb81fc7A067447C0Be035FcA3b1": 16.5,
  "0x1f5F0Aecb5Dbf7E35A2d4EEfD2016721F44aa97B": 16.34,
  "0x4FCF3A3507A6a998F363679A29240578cE85D0F3": 16.34,
  "0xb972d4e149C6d04Dd12328A4213fBdb5a6770356": 16.26,
  "0xE9dDcA4b4bac5cd47a2B9aC23af9fE96e871EF0e": 16.03,
  "0xfF1d2AaF34318C2b9c621D3897E769128480A2c3": 15.95,
  "0x17E542C6c7c2bDE7C139eB8005E6AB0A37164130": 15.84,
  "0x0e5Df087b286431F7fc49af2885A49A0A573416F": 15.77,
  "0xeb701234310E8cD160E9F172963f6BEF4F6B90dC": 15.72,
  "0x05EA15851dbD1B945ee94E4Eb47B7dfF88Ff7d98": 15.66,
  "0xee21f76a1e888d536ce2f59143359765222d0858": 15.64,
  "0xe2a8192C8fBa31dcc83FFa9aC2D9AAfFA586ca7b": 15.59,
  "0x0a12A4dE55C78E95bA1b2Ae43ec35aE714296fBe": 15.57,
  "0xb4449f90FE785F1C764de2EB3BE86204EBD0C312": 15.56,
  "0x271e1cEE8BC44D246E25AcfEB6e6D9A6782C4de2": 15.55,
  "0xADB78E6b7ADcd72Ab4911E7C27077AecA03701c5": 15.43,
  "0x92B8f923c670E77974F0d8F97D67a47697927a60": 15.37,
  "0x8046702a5035cff8C860FCcE9c93cc63BC1465bd": 15.31,
  "0xef7533aa94E667F8964f4E0cc27C1F710837C84E": 15.26,
  "0x57813513d93ECBCf57c8b65bA4c3DC9cAECB615A": 15.24,
  "0x8228165D621FdA7844baa639F870dAe48025cFDf": 15.2,
  "0x6b5D1aF9e1ba7E2478294547B6750fB604bc703c": 15.13,
  "0x670Bb2fA1231550FCcaec308E6ED03B98D2f90FA": 15.13,
  "0x0742d15fB52D928505E75b0269C4d14DCA4fAc38": 15.07,
  "0xE11e8430981763Dd3d45850231D716b516fbd908": 15.04,
  "0x574710EcBB5636E309C7f768a8c80203c5340186": 15.03,
  "0x22C3546BBA1f932f10Ccf5795530f780CDc8065D": 15.02,
  "0xf45CE70359871F7c57c2554beeFd5b9528965562": 14.98,
  "0x6ed09a02f4e53A67c3440C82FB0D51B9e24F4c93": 14.96,
  "0xbC95A9f3688Dbb6d8Ec8bB2939d107c4de0513a3": 14.95,
  "0x3255Bae998bA186ef366e9BA1ad4E5adee484270": 14.94,
  "0xE81322F24453C09635A0343c973E808B3c4C4bFe": 14.9,
  "0xd1452a7fb94Db7d2201B899F3E1783975d41A1fe": 14.89,
  "0x7c92597A8A40aBC62177be109f6E8242d53Bff76": 14.83,
  "0x705E9E4917E67da85A7df7e4f9d6C0cD3d245c9d": 14.75,
  "0x4f25250D173f2b684d9634073ed640CdF0A2Bb38": 14.67,
  "0x85A777d694c8144a2B815777B0395Ad91CCf234B": 14.67,
  "0xeec47503c34f9492870cf83982451041af8d635d": 14.53,
  "0x121751f6606411D0809ca3Dc642Aee09A1a4AB09": 14.29,
  "0x5277ce95C6451b76f0645bF00B08506a326bF88a": 14.21,
  "0x071953c016bAc5f940F4f2134EdAB3a92e95F9B5": 14.15,
  "0xA1d3c872221f4425B20b47d02002e5A13Ad8AC9e": 14.1,
  "0x371E25FE140D29Ed2B3C505812D2B57cDcA264C7": 13.93,
  "0x16d7116f4f78819351F0CcbbB4B31e85A5EcC23A": 13.82,
  "0x0B1241aa6F1AB5A53C2f403BA52809BA9f0AA364": 13.81,
  "0x3191fC82e59FDb504777B193f081C3a4AEA21418": 13.8,
  "0xffC43Ff9FF4196F2d8c05D1Eb08e02dB411ebeEB": 13.75,
  "0x73903197bCDcE8aFaf9091FBa2f89eDf6c2d4f7A": 13.62,
  "0xAD77428362c311e7c39cc1C8d947AEbD57B3c2E4": 13.62,
  "0xAd9130D87A6c8476a1c67781aB57ea273dB540a5": 13.56,
  "0x1654b42638D8F977683F9a2FD1C38A1C6D9d2156": 13.41,
  "0xE3d66Ab54775C09523Bb93f02CCBbD8095918e76": 13.27,
  "0xAD748be8c75eCBc002F2A1058164FaC22b6484e2": 13.23,
  "0xb69A1431dAfB36e0FB15435854B554CF104b5444": 13.16,
  "0x14a219A10FE4489deAFf470Bca4e67DB22eF5A6d": 13.15,
  "0x295CfbefC61004F3a906F415Ed5e2f7f105df741": 13.14,
  "0x3574b6c1f04d908419B6c9474f65bF89f82bf8F1": 13.03,
  "0x8D81EAcFcdF61c5E02cDC28C2D51E029a9B6d14D": 12.96,
  "0xEfDdF8B8306E7d83617D3733E758a4B0c85FBa67": 12.73,
  "0x1De43c2dA18C154E45E856F443662f6d451Cc8E3": 12.73,
  "0x101a0a24B233191a3F23296601FB7D9829e10EE4": 12.72,
  "0x73A2deF357e3B77fc7688F40A744423E46e163e2": 12.59,
  "0x0C48363693aE50a4fc46116Ce57A788A6C283135": 12.52,
  "0xE196f0ABEBEeC09121d373520Fc4190cCd52126F": 12.45,
  "0x42771beC49E7E93fF262A05A9edEFF9F866860b4": 12.36,
  "0xCFDd49F299B7e69Db78b2422CE824965bB81f57e": 12.2,
  "0x2AfB9823934aC1D8213773140d508A624e7Ef26C": 12.2,
  "0x5a23a13F97Adda0a4b554bBE0D07d3321fA2Cd1a": 12.13,
  "0xf9D90D44a57c831037812ea413cBBc4CD797CA5C": 12.09,
  "0x922495d36012b48404b6eD93b644D41E15915AD7": 12.03,
  "0x30937D8D9766422577b9905fdb3698e9ff8E032C": 12.03,
  "0xB437e7F080D6f6c3A2174e1aBF4A4C22Ad60AD7f": 11.97,
  "0x7a15432D4B43d79411c35A084f63F7f948af9cC8": 11.94,
  "0x288EEa5DAd34F3d37139737C789D61294beb6Df6": 11.92,
  "0x95C0551572Aa631A2d9D522b0A289Ba4C2E5aF0b": 11.8,
  "0x409b357a47Ed692a5c403253Eb2F7E875B9fBAe8": 11.76,
  "0x890642D12E7eCa550D3c7FC266d912970ED1C7Df": 11.61,
  "0x2c0053bB4053C88A46CDea9705604AdA7B2c164d": 11.48,
  "0x7D68372868Cb8467d0Dba68529E6Cb472353A2AC": 11.48,
  "0xB2A78053506cDee6bDb9a2797E49F32dfC52c6EF": 11.47,
  "0xDa49eB5D221b001c54DaA9422E9cE8b4194832B0": 11.41,
  "0xCffdea5ce4516991CA444E310f0F86F0B6ADEf50": 11.39,
  "0x4d1dc4753471A214e5640256Ba3aAda0151244f0": 11.38,
  "0x61850d92A296C25ade5aCc917871B64648e18a49": 11.33,
  "0x2E088e6498d767396482102aAc96c83F5E98D515": 11.26,
  "0x20e61b70c4a53BAceC21966e6d1CbD11fB2b56c6": 11.25,
  "0x4dAb2B3e9E687F8cB232ba742129754FFf54b021": 11.24,
  "0x8C5f69D872879e9193dd988B37F12673E96E78C2": 11.2,
  "0x78d3B0319945D5F6d6073fB1E4c9C441a0E6B8Fc": 11.18,
  "0x57FBF6C5C57ccFAeF4Fe226aE2723649d2Af81D2": 11.04,
  "0xc0a70a38E76Bce51a7E8942469217cB8cF5703CF": 11.02,
  "0xD7E0bd69eaC2830272c93143323c8252d53b50F0": 11.01,
  "0xBa1FD346428A7ccb4F342Fd5ed2C876835888888": 11.0,
  "0x7534622957bB3ABE86958c93cB5d981c9Ea856eb": 10.99,
  "0xc33E15c9FE58Bbe234Ed0b8b8c1b8108E0F0b48E": 10.81,
  "0xF65052530b5A2f1c1d9aD60159a6Ae6fF2ca20f5": 10.74,
  "0x4db2aa760d6B8D5d9a593a0788A91bdF5fDD25BC": 10.69,
  "0x924aEFa99c39C31BE6531f195d9e6f6Ac2C28dA4": 10.6,
  "0x327ED2dB73484c3EC4bca4C16dEfd79B8D813333": 10.52,
  "0xdCB5cE2752422f464896Ff0Fa84A0703328e72dd": 10.49,
  "0xf57F3fDFB212E2B1df28C82441Bf208D42d878b0": 10.48,
  "0xbCA91374128995d6E2fD9652da13EB2ecAad96C3": 10.48,
  "0x1102f3Ed7DC9173C33d240CaaEC4e3cF34328Df6": 10.43,
  "0x6A78FeFF520587839Fc1c168679B66Da0A17EfCd": 10.38,
  "0x1604A263C8eC0DbCe5c4fbc4ba99a4D7CB0bb3dE": 10.37,
  "0xfe7B8Dc058082D154a4A0FF0F7c9b1621b386f7B": 10.31,
  "0x1a5c574B1c3196ac109CeBfD7Ffe290d7D57396a": 10.29,
  "0x9844A0D6bB78Fc5Ee66C33e29A60C91e1A1738F0": 10.27,
  "0xBaf6A7BBa9211C8B7137973E17484891B4EA0201": 10.27,
  "0xfe708B862cAa693596176e12690F179c0a4Ad26B": 10.26,
  "0x0758b2b12c7a8712F0018d6Cd29b26C33641055D": 10.25,
  "0xcD2BFf94e0DEd89C453B6A13B619b56D0d164dC8": 10.23,
  "0x7d18beC42560ca0c7711f15d196ff7b1e778c206": 10.19,
  "0xe44DEb3e3319fFE92ea2EC7F228Fd027EF4bA1E9": 10.15,
  "0xa476FBEC733a50b46375d92e8E078E3bd973533D": 10.13,
  "0xcf15BC8F9dEdcE3c4249f576f9B1baE39B376039": 10.13,
  "0x24AA4cf9783C12c6199999c2BFf9F8ba50DCbEBe": 10.13,
  "0xb01BFC7cDdB5242396299Cf0E29CF72F211c0B26": 10.11,
  "0x547A0DA133d2907496B73Aa2510cC341BD44Ad6E": 10.07,
  "0x4e52724ce8f246718FE21cd9cCf42e580dd6B8f2": 10.02,
  "0x161691C25b7C27A947b866bEF2707561Ea45Ee5d": 10.0,
  "0x03d7c3bC6aEc411F502CcbF41E4bE534a8e10866": 9.99,
  "0x888Ff81599F9f7e2A8E97dD59138CD4c3B63d3Dd": 9.98,
  "0x105A0a971C90fe53eDD1965b2e7866631fA043C0": 9.87,
  "0xc8c21F17c1656632E423335A5A169C9A5695aacB": 9.85,
  "0xA56BB69413d6a426b085898ce6E3668Cee2aDD5d": 9.81,
  "0x6A6dB0A11A02eE9201185Ed676982D5Eb196f45A": 9.77,
  "0x05659E391C2D63aC001AC4879cAc698Ac02697ee": 9.72,
  "0xD10Ee929fFC40b6B586E31C1B9350F5Ab988e784": 9.66,
  "0x0d7603783C74Fa142eE0F27f65912783a8Da3Cb8": 9.61,
  "0xee03726B9C5ddF4413c7454b3f2046734f0ce18C": 9.57,
  "0x85a8335e0f22459d3eBc74a764dFcFcC0a5Bc148": 9.55,
  "0x53754657cc0AA2a3d9e0a20fAdcc2ACECa212965": 9.55,
  "0x0601755f49D67454d7A391D8d84cd17dE482161a": 9.53,
  "0x3b84b57DBA31Ed0B2f0935141DA505C6cB1B1E0D": 9.53,
  "0x1592518E783dBFeefc225F7C0a0A573aA451F9e2": 9.53,
  "0x6C728617818Ef9ac8991BFCb5E43c52A9165F580": 9.53,
  "0x474f990b4191C2256040dfC99BCe755F09C5B40f": 9.53,
  "0x954Cd8F1b3d207d58aaB2B31E715058822EAe6C7": 9.53,
  "0xad071B551151AD4ae4E2EdE0411CDA80a1955dC7": 9.53,
  "0x0fa90aD7F08Df5CC132Fd1104C1c4415aDd3520B": 9.53,
  "0x8B746668976c07985E5E124224C61322C242c748": 9.51,
  "0x2a620e7423593a21e245AF41B86Fed252e931b3f": 9.49,
  "0xd97fAddC80B26c3b45835E70780e9351a69B0b46": 9.46,
  "0x0dD0f8630771Dc32b6476673414a291a22f40b04": 9.38,
  "0xD2187f290fB666bA35d782B4aCf335c59dec1366": 9.33,
  "0x143fA707Ebe7B21ae0bAb17E2d7afEF08Ab9b017": 9.3,
  "0x5410675ecA318dbEe713Af482C915ea5A69b6072": 9.29,
  "0xD4396eA8ac8E9954f33457E6d1249729Db2b7960": 9.27,
  "0xA1023461aBfd5eb81a3eCf50f5c19BF2fe9e3b4d": 9.24,
  "0xFF84202323ccE60622BE4d6109E08BadB62D4D11": 9.21,
  "0x67CB88931a32ED721BB011A0665A675998426db5": 9.21,
  "0x28109d563DbAa7be094A505E12a46417E19e512B": 9.1,
  "0xaC183a1B93dA94b39686AC6072932D37D1A85716": 9.1,
  "0x7252B1837C9c9e03D70F6772669317eE519f9e8d": 9.1,
  "0x211eAe1D38307019F01ffE017669E3fab3E633a7": 9.08,
  "0xff502e5F47f83076F78549f97e44359A120B4578": 9.05,
  "0xc85a7d2AfB3c380FEe80F13de217a77AfA988a99": 8.97,
  "0x88576E363cb9496425D1779B0a9976DFEAf21779": 8.96,
  "0xAF46cB19C00166C9B3e67D94627324a8E5e3Cf39": 8.95,
  "0xC9d479f30A96FE5c5d99D6d224A4eAee42a51151": 8.94,
  "0xAD19B942E496BeDa195aE9D25e080cF137e94886": 8.8,
  "0x94ea2e324FB4c3fE1d4A090D76deF61a586E576c": 8.79,
  "0x3efc0B6AF039ed13cBeE2ff5bd8bC38b400667Ff": 8.78,
  "0xAB47C4820f7BDAD19379ed6A837F3a43BaC7A38e": 8.77,
  "0x22aADB02650681F072438f956e633849fbf4D316": 8.77,
  "0x66bFCa16181ef39ae520D858e56e359De216d649": 8.67,
  "0x863F5881e3702C5E37d78694E61496D310BB4098": 8.64,
  "0x8B8F1C3c5B8fDcE5f91e99d184177670bc4e500c": 8.63,
  "0xaD052107b1E52B0269B9192c91021BED0488E07B": 8.63,
  "0x0BFd2e212F6a3B5bF242a662e73FA0898D7F332F": 8.62,
  "0x5C56DCeD44318a1A3c6049f912d2697726219516": 8.62,
  "0x90eccc14a0689Dd32808CA5B5452D29bC18c0b10": 8.62,
  "0x93C91E1B803A4E7059D5949303B67aBF5711d3E4": 8.46,
  "0x1aC1A423dBaC19AB985da1E7B69F3FB0E332aE11": 8.42,
  "0x1e3dB09b63A7E4A28DF021Ea263728f1195f8A5f": 8.41,
  "0xeb4D6d3565CB6e1577388c781B740bb365b04b95": 8.41,
  "0x808F34BA78203F84a23bEf87C548c440CcfBA9f6": 8.4,
  "0xFD594f9Ba1a1d107DE700898DE6b6F798b968B34": 8.38,
  "0xf056623391292f437b65ef1A2C5A047D6fD576fe": 8.29,
  "0x380555E9d3dcf5557Bd655838657Ee51635787aA": 8.17,
  "0x70d61D67784fEA7B81149dAB2c7de35c2d652De7": 8.17,
  "0x49328D2e643D536F775f698071c466AA802B0453": 8.11,
  "0x4f41C8D35739Ab79ED2a814C83a50b9F11d794eC": 7.97,
  "0x885Dd053dD5eD799b3389207F23C605CfBA12514": 7.95,
  "0xE8c7a00e52B08E25eE99bCA1eD177e3541045C77": 7.91,
  "0x5BeeE2a6846B15Fe1A6E9D271E0AbAc11471f44e": 7.88,
  "0x12e623966fE456155eA831CcfcAD97cA54F950c7": 7.85,
  "0xA76229eF56D01C76fF815a0F8419878c05b40a86": 7.82,
  "0x5d0707ceE9096b4A280607B67a84D61B324F99cA": 7.81,
  "0x971224dFA91079D11855c2939cA000A6E2699291": 7.77,
  "0x67Abb332f5887c1fb78C9F64AA4e8cef0E82386d": 7.74,
  "0xFE706aFC9a46D4261dA0A5d72F43a19FD3584812": 7.74,
  "0x5BA72E8617AF3A1881ab8c7e224be36056C78254": 7.74,
  "0x5Ad1e92bC09AD72A938401Cd6b119E506bB8026F": 7.74,
  "0x43b9F483902da1F87Eb91a3143627a35AC1096eB": 7.72,
  "0x413f3864DfAF4342faaAd2cCAB8e4AC92A77975A": 7.71,
  "0xac2e9CE8525C5a9Ea1293e0340b3240be889A9A5": 7.71,
  "0x898f6fd921D0ab32B67bdd937E0A81a44cf6D5a8": 7.71,
  "0x4dBb4db48454bD5397Fa8e053592c752b7DE096E": 7.67,
  "0xc763542B3189DB15dB02E950e5B355107EB77d71": 7.67,
  "0x65583EBad2c8B4536AEa5671a87eEE56Ab7F68b4": 7.66,
  "0x5004BAdFBDED890A1ebcC05abFE441e7D2dD6Bd9": 7.66,
  "0xf88f88FC9D15420F937Be5e151cb53196D8c3138": 7.63,
  "0x1F19100ACd6257d7Cdedf55033dFe14884D2cd1d": 7.58,
  "0xF8734aefeE1362B9B23732B2C1dDAEeeF7Aba5Ab": 7.56,
  "0x27350CFc23882F43542F303d0e4F6f99f633fD98": 7.55,
  "0x7BafF7126007a16ea1FdEa1ae7F7f4d01514f5bD": 7.54,
  "0xCB613C0D8C3ef097Fa8C5cA053dc1bd7c0e03D7A": 7.53,
  "0x180F7832a5AECFED3E667d80aCA078423Be8D982": 7.52,
  "0xde9fd237E4374c1ea59100290cD6397BF408C05C": 7.5,
  "0x27c86C0d2FA0Ef10d73B915140122a16a54F4295": 7.49,
  "0x5F37FEF69bE53fCAff128be7DaD6096a9f45F062": 7.38,
  "0x71c97CdF5c48E830acA6182Eb3c3E0fBF19322eb": 7.35,
  "0x2D04428b8810eb400fFD5D57b071Ed3Fea33c2B0": 7.29,
  "0x6E38DA8aB41737bE9DaB861de85d3b01649F9623": 7.23,
  "0x1C657d8790e0DD5e06Fe16B9dbDA380dC5C707f1": 7.22,
  "0xaA1d4e75e25Ab71Ce930B973740239dCdBd74aF5": 7.19,
  "0xAC0FA461dc1FF6d50C2a6441b92486bAd925FA0a": 7.16,
  "0x243e2fD7bE23679F10b6A3C22722D81b899d35F0": 7.15,
  "0x546d85D1395ABc36d846227C6A1b830442158159": 7.12,
  "0x3760dD821Fa6B662c137478c1388F77f8c29fA0d": 7.07,
  "0x63c8258b50e0AcDcE01125BAb4c01E8E17397ed2": 6.99,
  "0x9A408C684A1e334790f9076916FFC401f257773D": 6.98,
  "0x97F1f71C6171f8CEc402B4b54a6b8347dDe35FdD": 6.94,
  "0xD9aeAe24CdeAa1054d8552AAd64742B2240e903c": 6.84,
  "0xF4265c7D5c3b668B9Ec8d0Ce17fDd25B911CcDE6": 6.84,
  "0x334674b1b35656F9CCa30d74541d3DF52a7753dd": 6.83,
  "0x6d13D26F345806ec42f24E1a0FD69819Aa9be280": 6.8,
  "0xa5edB97F44C40DB33B24726E4a4ED24E0b23296d": 6.8,
  "0xeB3A5a749be7aEE08eCf8702b5F6dbdD1A668e2f": 6.8,
  "0x8C6254D96dF50BE733FCc978C311538714Eb54B9": 6.76,
  "0x473B0e901d086C6ceC9B75770d18c471Df6e02AE": 6.71,
  "0x40d09f86f09DF91f425CE5ea1f759aB6c562fa09": 6.71,
  "0x7297c5C49FC4a8350098B2d6dc0A9c3224801d9F": 6.7,
  "0x4bb779Bc2718591823B8B1493d49b5E445d23339": 6.67,
  "0xdb4d92830A66C61919d67C85fdeeCf816F3619Be": 6.67,
  "0xB08964D683FD12764071279f9187E9F58C8d6e29": 6.67,
  "0x9a9C4A74118b88588d58f45C2025C726B4EA8C4f": 6.65,
  "0x5E95eB09B4d772bB97AD20a68A5304dd16CBE9Ee": 6.59,
  "0x3C2a2A0565A958528AC9542913fd6a2EB4051538": 6.58,
  "0x28419F0e31862FF2a7dCE4259522b936890D92E6": 6.53,
  "0xAA7bEebdb122a026d790952C4c1B7063a3Adb544": 6.53,
  "0x3e1eBA617A4D6B470f2df12C0cd300811Adb9edc": 6.43,
  "0x433c06CeFA436Ac7977fE588763681E0CF143eeA": 6.43,
  "0xEc7710B43CAF145805e66A1Ea07dab954a2C20Be": 6.36,
  "0xbc6294f9c3537264f9728DE979dEAf7b483a1f32": 6.36,
  "0x9328EF8A8958189F9B6e4c8a46ac95d18019fDea": 6.36,
  "0x082357FBadB2f23256554337F715840b6f7CF5e3": 6.3,
  "0xd6516Bc2ADB68980BE5c8a61E7AD013BD94E0Fae": 6.24,
  "0x7cB50e9D7f3a6Bea51e21fB0F1EF9Ad12EED8475": 6.22,
  "0xdE11C38D0eaC7F90E6877c323c62A5A411dceC79": 6.18,
  "0x1E115A0cD3d5346b8A9B25a9DF56a142CD71c6eC": 6.18,
  "0x67050CcbE1067021204F54492bef0f63E76c717F": 6.13,
  "0x99C0ca0FFA419579265Ffa62b56CAA6788bd1068": 6.1,
  "0xB75BEd5f5988004477624163b4962dB960F0c248": 6.09,
  "0x6519dA0a72fC0ca38C5f169b44Ac3C1796077F38": 6.07,
  "0x7F44e97E7aF85266c8e8aCD4010671c02A9f739f": 6.05,
  "0x6647346ae58dc6688ff3fb036edbf4e5e97319a7": 6.05,
  "0xd0be1524FcA43882e1864558306ee7D46592322b": 6.01,
  "0x1b65b6773c91F404218908d07D7eAf503574F4D5": 5.97,
  "0x90a0E1E2ABAC0240aA0f8e4F0AC441e1C0A75c0B": 5.94,
  "0x919A01c66f7c9FDC531f86d2e7439dAC6841bDf3": 5.92,
  "0x36fa88A1322e3c02eFE0CF770CC3b52c8Fb836f7": 5.92,
  "0xb2Bdc693fFCC1fC1781676bBD121e66d0c96137c": 5.91,
  "0x4Fcf4EEC8D6ce062c4945F09d1919F599908fd1d": 5.9,
  "0x7De3812689B00161Eb1bdc13489EAa4774DC7341": 5.89,
  "0x8Aa18f523655012858E58c33C702C0FD70E9dc1B": 5.89,
  "0xAE799DFcb1DdFEA0cba8A9F8f8b6e3955332d8f9": 5.88,
  "0x0545872B4615E268811165B2aD25cA837143cbFf": 5.88,
  "0x4F5aFb57570F5aD4B32e84a6B20228e4bec63697": 5.85,
  "0x8bD9cf0De330bb2647E8d5B341841Fb2BB3ae9dA": 5.84,
  "0xa66F7B16D8E2bEefCC7E7DE3d3278F0454698576": 5.81,
  "0x655af836eb3661040FacdE6828aa30d4EDE01c7e": 5.75,
  "0x3A19A30B7025893d1A48C1206f3570d01aF847EA": 5.74,
  "0xd74c8Fa732458246786690a9ECb25c355c6A4c0a": 5.72,
  "0xD5aD76BA364c0F2241f6662A502C34FE0B4da981": 5.67,
  "0xD0Ecdc77Fc256e71D9F31B7eaF0eE8C7065254dF": 5.63,
  "0xf62932127eBdaD4081867B8d64027F706B51d923": 5.61,
  "0x7A075F8d0c68576c5323fb2984de7b1C0666b250": 5.58,
  "0xEAeE8D857d0Ed2d199e2a6045b17c81a5BFAdb00": 5.58,
  "0xa679A0992Dd45c9910d234cB10C534d297f611df": 5.57,
  "0xAEB2Aff0975fb5c81EF60de3D384a95c9370be4E": 5.57,
  "0x79feE7B2ECca0182215828D14470e41F9bfa3E44": 5.57,
  "0x7D39FEaCfB5CD21117667fBacA21ff8b4b1C0dFC": 5.55,
  "0x8717688e33Be7168632Fc726Ce1Bd7Dc17b81E04": 5.55,
  "0xEa719860157c8235347c143b4a9792cdFe1Fa142": 5.54,
  "0x312eE434B90245Bef40FC5231C9811929e3A1883": 5.52,
  "0x746d708b046C3a13B42012356219BcffaBF9d219": 5.52,
  "0x62733C31c255a39f107aA2a696F8997f2b867f25": 5.52,
  "0x5Ad3c970E33BAb0A732Ae8Fbae397621d4869ed5": 5.46,
  "0x9ff463090865256B31BBadb80EAd5c23445Ac6CA": 5.46,
  "0x7dC305E2a578f1D20c090b2018e41146498163A7": 5.45,
  "0x6BE6224D138eCbe6435299b39bD864314AaA3496": 5.39,
  "0x20A5ab8873c9ca7E9d452C66AB8C3F3C4506B69E": 5.39,
  "0x275dC1E99791C1a36393eBf01D051C09237dDF86": 5.35,
  "0xa07E04F54cB79558863906B3e96d4298Ec63296E": 5.33,
  "0xa34bf8d408E21AD9A6c91c3422eB517aC83817EA": 5.31,
  "0x9c9b58150B16894b420397907f5Ec8D28B8b9876": 5.3,
  "0xF7045F3175a2d296D02239A9f807eADE8430A926": 5.3,
  "0x3B86708A7aecF2ebbb11D3E62f940197E3b84184": 5.3,
  "0x3BE0d5e2874b9A3D77dC5927249A106A5819327e": 5.28,
  "0x6a3D183cc766e7AbF7039Ad412eA1776Ca5612f3": 5.28,
  "0x199C9Dfbee44cCe13E06C5CC4837EDC144A40f89": 5.25,
  "0x8350f12aE0771a881351506edCa800D39632224B": 5.24,
  "0x7074C905F1F7e1F42fafc11a63Fe6792aa3A8422": 5.24,
  "0x092fa720f517490fE4c68b70E494014Cd8f6b7ac": 5.24,
  "0x5FF33fB2E513C76B52c0Dc6D8726ebEf3d79ef88": 5.23,
  "0x5F3fe2B68cdceB6cC7F7daB75E1EdeDfe9Abd66a": 5.22,
  "0x4b868f66c49807acE52A8d5D1871C55Fb69dE731": 5.19,
  "0xe92C66257b9c88A939fAa3eD3cee0e6FFe3b374F": 5.19,
  "0x619e3dcbd29294B8e9F76405e7009e9e5A877B75": 5.19,
  "0x3a38C64E3339AaB854EFed8819873b6442792d8f": 5.17,
  "0xCf500FaCc05A9360Eb6d39AB83E46A303741cf54": 5.16,
  "0x40A1825Cc931A0700808040Ba2B169b6Fc19Af4F": 5.13,
  "0x7ECF2e576AAc92B6E53784c9A22cD573AF43D0Be": 5.13,
  "0x086595eD0A84B661070158ad435A1aeB1c8FDa7f": 5.13,
  "0x9B8f3dB852cEF72d83fDa6957B9D3721E596f36c": 5.13,
  "0x70b772b42c81b058e1d41d9b88d9875E775652B3": 5.13,
  "0x9e7c2B722f1F30b8dFa2A569eB14f7d2a6D28F70": 5.13,
  "0x77C35D70C346d5468c9C57499b44222A5A3261cd": 5.13,
  "0xf6c346939c880a95A4Ac59430Fe6ea3f5DFCEf98": 5.13,
  "0x9E4FCDf2c2fa58a3D66c926c78709019f9Db2A47": 5.13,
  "0x287BacA6F04b07ae14b61190c798D3d6b719Ea97": 5.13,
  "0x32B68681222C3633e03E6028F60399d235333995": 5.12,
  "0x55c1A8F9d5EAf956F418ed6F0eb3bCF8c2cDE9e0": 5.12,
  "0x6E7ea5C5abD84262fA5B0071D7D8f07A66a2d16a": 5.12,
  "0x60a08D78b2C5aA602F00898e8847B7ea4363cc53": 5.1,
  "0x7178E9d6cE118680c5cd915f783A5eDB93d1a714": 5.02,
  "0x5156b742FC556e2f81Bffe15a2C3f142e5eAD560": 5.01,
};
