import { BigNumber, utils } from "ethers";

import {
  Chain,
  Configuration,
  TokenSymbol,
  WebType,
  CustomTxRes,
} from "./types";

export type { CustomTxRes };

export enum Chains {
  bsc = 56,
  bscTestnet = 97,
  zkSync = 324,
  zkSyncTestnet = 280,
  // zkSyncTestnet = 270,
}

export type { Configuration, Chain, WebType };

export let ChainId: Chains = Chains.bscTestnet;

export const WEB_TYPE: { [key in Chains]: WebType } = {
  [Chains.bsc]: "BNB",
  [Chains.bscTestnet]: "BNB",
  [Chains.zkSyncTestnet]: "Ether",
  [Chains.zkSync]: "Ether",
} as const;

export const DEFALUT_SYMBOL = "TINC" as const;

export const ChainsFee: {
  [key in Chains]: BigNumber;
} = {
  56: utils.parseUnits("0.002"),
  97: utils.parseUnits("0.002"),
  280: utils.parseUnits("0.00025"),
  324: utils.parseUnits("0.00025"),
} as const;

export const getChains = (layer: "bsc" | "zkSync") => {
  // const processChainId = SYSTEM_CHAIN_ID;

  switch (layer) {
    case "bsc":
      return !isDev() ? Chains.bsc : Chains.bscTestnet;
    case "zkSync":
      return !isDev() ? Chains.zkSync : Chains.zkSyncTestnet;
    default:
      return !isDev() ? Chains.bsc : Chains.bscTestnet;
  }
};

export const ChainsMaps: {
  [key in Chains]: Chain;
} = {
  56: {
    id: 56,
    chainName: "bsc",
    name: "BNB Smart Chain",
    nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
      mainSymbol: "BNB",
      gameSymbol: "TINC",
      usdSymBol: "BUSD",
    },
  },
  97: {
    id: 97,
    chainName: "bscTestnet",
    name: "Binance Smart Chain Testnet",
    nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "tBNB",
      mainSymbol: "BNB",
      gameSymbol: "TINC",
      usdSymBol: "BUSD",
    },
  },
  324: {
    id: 324,
    chainName: "zkSync",
    name: "zkSync",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
      mainSymbol: "ETH",
      gameSymbol: "TES",
      usdSymBol: "USDC",
    },
  },
  280: {
    id: 280,
    chainName: "zkSyncTestnet",
    name: "zkSync Testnet",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
      mainSymbol: "ETH",
      gameSymbol: "TES",
      usdSymBol: "USDC",
    },
  },
  // 270: {
  //   id: 270,
  //   chainName: "zkSyncTestnet",
  //   name: "zkSync Testnet",
  //   nativeCurrency: {
  //     decimals: 18,
  //     name: "Ether",
  //     symbol: "ETH",
  //   },
  // },
};

export const checkNowChain = (chainId?: Chains) => {
  if (chainId) {
    // if (chainId === 97 || chainId === 280) {
    if (chainId === 97) {
      return 97;
    } else {
      return 56;
    }
  }
  return 97;
};

export const SPIRIT_CLOSE = false;

export const chainsAddrresUrls: { [key in Chains]: string } = {
  [Chains.bsc]: "https://bscscan.com/address/",
  [Chains.bscTestnet]: "https://bscscan.com/address/",
  [Chains.zkSyncTestnet]: "https://goerli.explorer.zksync.io/address/",
  [Chains.zkSync]: "https://explorer.zksync.io/",
};

export const scanUrls: { [key in Chains]: string } = {
  97: "https://testnet.bscscan.com/tx/",
  56: "https://bscscan.com/tx/",
  280: "https://zksync2-testnet.zkscan.io/tx/",
  324: "https://explorer.zksync.io/tx/",
};

export const providerMap: { [key in Chains]: string[] } = {
  97: [
    // "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s2.binance.org:8545/",
    "https://data-seed-prebsc-1-s3.binance.org:8545/",
    // "https://data-seed-prebsc-2-s1.binance.org:8545/",
    // "https://data-seed-prebsc-2-s2.binance.org:8545/",
    // "https://data-seed-prebsc-2-s3.binance.org:8545/",
  ],
  56: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc.nodereal.io",
  ],
  // 270: ["http://139.224.252.237:3050", "http://139.224.252.237:3050"],
  280: ["https://testnet.era.zksync.dev", "https://goerli.explorer.zksync.io/"],
  324: ["https://mainnet.era.zksync.io"],
};

export const externalTokenMap: { [key in Chains]: { [key: string]: string } } =
  {
    97: {
      BNB: "0x485813Ea110C62CDd6A6a47854E0C4Dd1aC51519",
      TINC: "0xa1122A74240d799a21C1B2e6cBD60Fb2c29B10FF",
      BUSD: "0xB7486B62B16aCd1e688693d1486Cc81D750010F5", // 测试用 BUSD
      USDT: "0xD9FB2B16B7D93f8EC0CDfFe07c98281ac3bBF11D",
      LP: "0x1925c7552561017395e8fc366a2dbb80f13e9694",
      "TINC-BUSD LP": "0xDaf859F41a4473BA37B05BB55Ba386122011701B",
      "BUSD-USDT LP": "0x1925c7552561017395e8fc366a2dbb80f13e9694",
      "BNB-BUSD LP": "0xd70a868971CeeAf1de5aE77D7aD789974991d208",
      SPIRIT: "0x15cc9036189263528BA509aA8CE20A17f69a94D9",
      "TINC-SPIRIT LP": "0xB75237646D73E4b51c4D50957fD483722ee59840",
    },
    56: {
      BNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      TINC: "0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
      BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      USDT: "0x55d398326f99059fF775485246999027B3197955",
      USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      BTCB: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      CAKE: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      LP: "0x0d5b9A0f4315a4bcE36D1Ea7d6B6d3123167aFAa",
      "TINC-BNB LP": "0x0d5b9A0f4315a4bcE36D1Ea7d6B6d3123167aFAa",
      "BUSD-USDT LP": "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
      "BNB-BUSD LP": "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
      "CAKE-BNB LP": "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
      "ETH-BNB LP": "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc",
      "BTCB-BNB LP": "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082",
      "BTCB-ETH LP": "0xD171B26E4484402de70e3Ea256bE5A2630d7e88D",
      "USDC-BUSD LP": "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1",
      "USDC-USDT LP": "0xEc6557348085Aa57C72514D67070dC863C0a5A8c",
      SPIRIT: "0x37e678d782d38a75EB16130b39C4ce2d7E3cC808",
      "TINC-SPIRIT LP": "0x137594d4b7cb34EfC3692D5AFF5883fc244691A1",
    },
    280: {
      BNB: "0x0000000000000000000000000000000000000000",
      BUSD: "0xC99A84b12DA7DFaa2F4d51318AA1E3B8be28409E",
      TINC: "0x51a74c4a05DD800261E6D88318E856E9dF00d05f", //zkSync上 它是 TEAR
      // TINC: "0xE9cB8477b0D3B100Bc2c9E4FC6EB21432926ab43", //zkSync上 它是 TEAR
      brigeTINC: "0x33BD996d518462281C1B3765cCCee93e7A90e7B4", //zkSync上 它是 TINC
    },
    324: {
      BNB: "0x0000000000000000000000000000000000000000",
      BUSD: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
      TINC: "0xCab3F741Fa54e79E34753B95717b23018332b8AC", //zkSync上 它是 TEAR
      // brigeTINC: "", //zkSync上 它是 TINC
    },
    // 270: {
    //   BNB: "0x0000000000000000000000000000000000000000",
    //   TINC: "0x111C3E89Ce80e62EE88318C2804920D4c96f92bb",
    // },
  };

export const isDev = () => {
  const hostLocaltion = window.location.hostname;
  switch (hostLocaltion) {
    case "localhost":
    case "192.168.50.59":
      // return true;
      return false;
    case "dev.tiny-activity.pages.dev":
      return true;
    default:
      return process.env.REACT_APP_CHAIN_ID === "97";
  }
};

export const TINY_FARM_HOME_URL = "https://app.tinyworlds.io/";

export const GET_TINC_URL =
  "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e";
