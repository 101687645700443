import React, { useCallback } from "react";
import styled from "styled-components";
import metamaskIcon from "src/images/connect/metamask.png";
import { importTINC } from "src/tiny/utils";
import useTiny from "src/hooks/useTiny";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface Props {
  symbol: string;
  symbolAddress: string;
}

function LinkMetaMask({ symbol, symbolAddress }: Props) {
  return (
    <StyledContainer
      onClick={() => {
        importTINC(symbolAddress, symbol);
      }}
    >
      <span className="mr-4 ml-4">+</span>
      <img src={metamaskIcon} alt="" />
    </StyledContainer>
  );
}

export default LinkMetaMask;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.208vw 0.312vw;
  border: 0.073vw solid #c6b27e;
  box-sizing: border-box;
  border-radius: 2.5vw;
  max-height: 1.3vw;
  cursor: pointer;
  user-select: none;
  background: #f5e4b6;
  font-size: 0.83vw;

  &:hover {
    background: #fff2d0;
  }

  &:clicked {
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 0.833vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    padding: 4px 6px;
    border: 1.4px solid #c6b27e;
    border-radius: 48px;
    max-height: 25px;
    font-size: 1rem;

    img {
      height: 16px;
    }
  }
`;
