import { createAction } from "@reduxjs/toolkit";
import type { Log } from "@ethersproject/abstract-provider";

export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
  logs: Array<Log>;
}

export const addTransaction = createAction<{
  chainId: number;
  hash: string;
  from: string;
  approval?: { tokenAddress: string; spender: string };
  summary?: string;
}>("transactions/addTransaction");

export const clearStaleTransactions = createAction<{ chainId: number }>(
  "transactions/clearStaleTransactions"
);

export const finalizeTransaction = createAction<{
  chainId: number;
  hash: string;
  receipt: SerializableTransactionReceipt;
}>("transactions/finalizeTransaction");

export const checkedTransaction = createAction<{
  chainId: number;
  hash: string;
  blockNumber: number;
}>("transactions/checkedTransaction");
