// import BigNumber from 'bignumber.js'
import { Contract, BigNumber, utils } from "ethers";
import { getContractInfoMap } from "./contractInfoMap";

import { Tiny } from "./Tiny";
import { Chains, Configuration } from "src/config";
// BigNumber.config({
//   EXPONENTIAL_AT: 1000,
//   DECIMAL_PLACES: 80,
// })

export const getWBNBContract = (tiny: Tiny) => {
  return tiny && tiny?.externalTokens.BNB;
};
export const getBUSDContract = (tiny: Tiny) => {
  return tiny?.externalTokens.BUSD;
};
export const getUSDTContract = (tiny: Tiny) => {
  return tiny?.externalTokens.USDT;
};

export const getYieldFarmingContract = (tiny: Tiny) => {
  return tiny?.contracts?.YieldFarming;
};

export const getMulticallContract = (tiny: Tiny | undefined) => {
  return tiny?.contracts?.multicall;
};
/**
 * 获取矿池权重
 */
export async function getPoolWeight(
  yieldFarmingContract: Contract,
  pid: number | string
) {
  const { allocPoint }: { allocPoint: BigNumber } =
    await yieldFarmingContract.poolInfo(pid);
  const totalAllocPoint: BigNumber =
    await yieldFarmingContract.totalAllocPoint();
  // console.log('allocPoint', allocPoint);
  // console.log('totalAllocPoint', totalAllocPoint);
  return allocPoint.mul(10000).div(totalAllocPoint).toNumber() / 10000;
}

// 这是计算单币池质押的 Token 的数量
// export async function getSingleValue(
//   yieldFarmingContract: Contract,
//   pid: number | string,
//   tiny: Tiny
// ) {
//   const poolInfo = await yieldFarmingContract.poolInfo(pid);
//   // const strategyAddress = poolInfo.strat;
//   const strategyContract = tiny?.contracts?.farmStrategy.attach(poolInfo.strat);
//   const wantLockedTotal: BigNumber = await strategyContract.wantLockedTotal();
//   // console.log("getSingleValue wantLockedTotal", wantLockedTotal);
//   return {
//     tokenAmount: wantLockedTotal,
//     poolWeight: await getPoolWeight(yieldFarmingContract, pid),
//   };
// }

export function getEarned(
  yieldFarmingContract: Contract,
  pid: number | string,
  account: string
): Promise<BigNumber> {
  return yieldFarmingContract.pendingReward(pid, account);
}

// export const getTotalLPBaseTokenValue = async (
//   yieldFarmingContract: Contract,
//   baseToken: ERC20,
//   lpContract: Contract,
//   tokenContract: ERC20,
//   pid: number | string,
//   tiny: Tiny
// ) => {
//   // Get balance of the token address
//   // 这是 LP交易对 拥有的 Token 的量
//   // console.log("pid, tokenContract", pid, tokenContract);
//   // console.log("lpContract?.address", lpContract?.address);
//   const tokenAmountWholeLP = await tokenContract.balanceOf(lpContract?.address);
//   // console.log("pid, tokenAmountWholeLP", pid, tokenAmountWholeLP);
//   // 这是 LP交易对 拥有的锚定币的数量，一般是 BNB 的数量，可能是 BUSD、USDT
//   const baseTokenWholeLP = await baseToken.balanceOf(lpContract?.address);

//   // console.log("baseToken", baseToken);
//   // Get the share of lpContract that yieldFarmingContract owns
//   const { tokenAmount: balance, poolWeight } = await getSingleValue(
//     yieldFarmingContract,
//     pid,
//     tiny
//   );

//   // Convert that into the portion of total lpContract = p1
//   // LP 矿池的总量
//   const totalSupply: BigNumber = await lpContract.totalSupply();

//   const coefficient = 1e8;

//   const portionLp = balance.mul(coefficient).div(totalSupply); // 矿池锁定的 LP 占矿池所有 LP 的比例
//   // Get total weth value for the lpContract = w1
//   // console.log("portionLp", portionLp);
//   const baseDecimal = baseToken.decimal;
//   // console.log('baseDecimal:', baseDecimal);

//   // console.log('lpContract?.address', lpContract?.address);
//   // Return p1 * w1 * 2
//   const totalLpBaseValue = portionLp.mul(baseTokenWholeLP).mul(2); // 锁定的 LP 的相对 锚定币 的价值
//   // console.log('portionLp', portionLp.toNumber()) // 0.1

//   // Calculate
//   // 这是 LP 矿池拥有的 token 的数量
//   const tokenAmount = tokenAmountWholeLP.mul(portionLp).div(coefficient);

//   // 这是 LP 矿池拥有的 HT 的数量
//   const baseTokenAmount = baseTokenWholeLP.mul(portionLp).div(coefficient);
//   // console.log('tokenAmount', tokenAmount.toNumber())
//   // console.log('wbnbAmount', wbnbAmount.toNumber())

//   const tokenPriceInBase =
//     baseTokenWholeLP
//       .mul(coefficient)
//       .div(tokenAmountWholeLP)
//       .div(BigNumber.from(10).pow(baseDecimal))
//       .toNumber() / coefficient;

//   return {
//     tokenAmount,
//     baseTokenAmount,
//     totalBaseValue:
//       totalLpBaseValue.div(BigNumber.from(10).pow(baseDecimal)).toNumber() /
//       coefficient,
//     tokenPriceInBase,
//     poolWeight,
//   };
// };

// export const getPairPrice = async (
//   lpContract,
//   token1Contract,
//   token2Contract
// ) => {
//   const token1AmountWhole = await token1Contract.methods
//     .balanceOf(lpContract.options?.address)
//     .call();
//   const token2AmountWhole = await token2Contract.methods
//     .balanceOf(lpContract.options?.address)
//     .call();
//   const token1Decimals = await token1Contract.methods.decimals().call();
//   const token2Decimals = await token2Contract.methods.decimals().call();

//   const token1Amount = new BigNumber(token1AmountWhole).div(
//     new BigNumber(10).pow(token1Decimals)
//   );
//   const token2Amount = new BigNumber(token2AmountWhole).div(
//     new BigNumber(10).pow(token2Decimals)
//   );
//   return token1Amount.div(token2Amount);
// };

// export const approve = async (lpContract, yieldFarmingContract, account) => {
//   return lpContract.methods
//     .approve(yieldFarmingContract.options?.address, ethers.constants.MaxUint256)
//     .send({ from: account });
// };

// export const approveAddress = async (lpContract, address, account) => {
//   return lpContract.methods
//     .approve(address, ethers.constants.MaxUint256)
//     .send({ from: account });
// };

// const MIN_GAS_LIMIT = 150000;
// function getGasLimit(gas) {
//   const gasLimit1 = Math.floor(gas * 1.2);
//   return Math.max(gasLimit1, MIN_GAS_LIMIT);
// }

// export const stake = async (yieldFarmingContract, pid, amount, account) => {
//   const limit = await yieldFarmingContract.methods
//     .deposit(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .estimateGas({ from: account });

//   return yieldFarmingContract.methods
//     .deposit(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };

// export const unstake = async (yieldFarmingContract, pid, amount, account) => {
//   const limit = await yieldFarmingContract.methods
//     .withdraw(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .estimateGas({ from: account });

//   return yieldFarmingContract.methods
//     .withdraw(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };
// export const harvest = async (yieldFarmingContract, pid, account) => {
//   const limit = await yieldFarmingContract.methods
//     .deposit(pid, "0")
//     .estimateGas({ from: account });
//   console.log("limit", limit);
//   return yieldFarmingContract.methods
//     .deposit(pid, "0")
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };

export async function getStaked(
  yieldFarmingContract: Contract,
  pid: number,
  account: string
): Promise<BigNumber> {
  try {
    const amount = await yieldFarmingContract.stakedWantTokens(pid, account);
    // console.log('pid amount', pid, amount);
    return amount;
  } catch {
    return BigNumber.from(0);
  }
}
// _ownerflag, 目标英雄所在的位置：0--NFT在钱包地址，1--NFT在质押挖矿，2--NFT在LP挖矿，3--NFT在游戏中，默认0
export function getNFTOwnerflag(
  nftOwnerAddress: string,
  config: Configuration | undefined,
  chainId: Chains | undefined
) {
  if (config && chainId) {
    const gatewayAddress = getContractInfoMap(chainId).TinyGateway
      ?.address;
    const farmAddress = getContractInfoMap(chainId).TinyLPFarm?.address;
    const squadAddress = getContractInfoMap(chainId).TinyNFTFarm
      ?.address;
    if (nftOwnerAddress === squadAddress) {
      return 1;
    }
    if (nftOwnerAddress === farmAddress) {
      return 2;
    }
    if (gatewayAddress === nftOwnerAddress) {
      return 3;
    }
  }
  return 0;
}

const cache: {
  nonce1?: number;
  nonce2?: number;
  gas1?: number;
  gas2?: number;
  gas3?: number;
} = {};
export function getTxParamCache() {
  return cache;
}
export function setTxParamCache(params: {
  nonce1?: number;
  nonce2?: number;
  gas1?: number;
  gas2?: number;
  gas3?: number;
}) {
  const { nonce1, nonce2, gas1, gas2, gas3 } = params;
  if (nonce1) {
    cache.nonce1 = nonce1;
  }
  if (nonce2) {
    cache.nonce2 = nonce2;
  }
  if (gas1) {
    cache.gas1 = gas1;
  }
  if (gas2) {
    cache.gas2 = gas2;
  }
  if (gas3) {
    cache.gas3 = gas3;
  }
}
export function clearParamCache() {
  delete cache.nonce1;
  delete cache.gas1;
  delete cache.nonce2;
  delete cache.gas2;
  delete cache.gas3;
}

/**
 * @param castleLevel
 * @param castalIndex 从 0 开始，不能超过对应 level 的最大数值
 * @returns
 */
export function getTarget(castleLevel: 1 | 2 | 3, castalIndex: number) {
  const target = (castleLevel << 16) + castalIndex;
  return target;
}

// 在metamask类钱包中导入TINC代币
export const importTINC = async (symbolAddress: string, symbol: string) => {
  try {
    const wasAdded = await window.ethereum?.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20" as any, // Initially only supports ERC20, but eventually more!
        options: {
          address: symbolAddress, // The address that the token is at.
          symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          image: `https://images.tinyworlds.io/tokens/${symbol}.png`, // A string url of the token logo

          //
        },
      },
    });
    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.error(error);
  }
};

export function bigNumber_sqrt_old(value: BigNumber) {
  const ONE = utils.parseUnits("1", 18);
  const TWO = utils.parseUnits("2", 18);

  let z = value.add(ONE).div(TWO);
  let y = value;

  while (z.sub(y).isNegative()) {
    y = z;
    z = value.div(z).add(z).div(TWO);
  }
  return y;
}

export function bigNumber_sqrt(value: BigNumber) {
  let a = BigNumber.from("1");
  let b = BigNumber.from(value)
    .div(BigNumber.from("2"))
    .add(BigNumber.from("1"));

  while (b.gt(a)) {
    const mid = a.add(b).div(BigNumber.from("2"));
    if (mid.mul(mid).gt(value)) {
      b = mid.sub(BigNumber.from("1"));
    } else {
      a = mid.add(BigNumber.from("1"));
    }
  }

  return a.sub(BigNumber.from("1"));
}

export const getChainsGasPrice = (chainId: Chains) => {
  switch (chainId) {
    case Chains.bsc:
      return 3000000000;
    case Chains.bscTestnet:
      return 10000000000;
    case Chains.zkSyncTestnet:
      return 250000000;    
    case Chains.zkSync:
      return 250000000;
    default:
      return 10000000000;
  }
};

export const tokenFloor = (value: number, decimal = 3) => {
  const decimals = Math.pow(10, decimal);
  return Math.floor(value * decimals) / decimals;
};
