import { useContext, useEffect, useMemo, useState } from "react";
// import { useQuery } from "react-query";
// import { chainIdDefault } from "src/config";
import { ethers } from "ethers";
import { testRPC, web3ProviderFrom } from "src/utils/provider";
import Web3 from "web3";
import { Context } from "src/contexts/TinyProvider";
import { useWeb3React } from "@web3-react/core";
import { TinyEx } from "src/tiny/zkSyncMethods";
import {
  Chain,
  Chains,
  ChainsMaps,
  Configuration,
  DEFALUT_SYMBOL,
  WEB_TYPE,
  externalTokenMap,
  providerMap,
} from "src/config";
// import useLayer from "src/components/Layer/useLayer";
import useIsMobile from "./useIsMobile";
import { shortenAddress } from "src/utils";

export const useInitTiny = (
  chainId: Chains | undefined = undefined,
  account: string | undefined = undefined
) => {
  const {
    nowChainId: nowChainIdByContext,
    account: nowAccountByContext,
    setChainId,
  } = useContext(Context);
  const nowChainId = chainId ? chainId : nowChainIdByContext;
  const nowAccount = chainId ? account : nowAccountByContext;

  // const { handlerChangeLayer } = useLayer();

  const [data, setData] = useState<
    | {
        chainId: Chains;
        chain: Chain;
        config: Configuration;
        defaultProvider: ethers.providers.Web3Provider;
        web3: Web3;
        DEFAULT_RPC_PROVIDER: string;
        tiny: TinyEx;
        account: string | undefined;
      }
    | undefined
  >();

  useEffect(() => {
    if (typeof chainId !== "undefined" && chainId !== data?.chainId) {
      setData(undefined);
      setChainId(chainId);
    }
  }, [account, chainId, data?.chainId, nowChainIdByContext, setChainId]);

  const { library, deactivate } = useWeb3React();

  const isUnlocked = useMemo(() => {
    return !!(nowAccount ? nowAccount : undefined);
  }, [nowAccount]);

  useEffect(() => {
    // console.log("data", data);
    // console.log("nowAccount", nowAccount);
    if (!data) {
      if (nowChainId) {
        const configChainId = nowChainId;
        const config: Configuration = {
          defaultProvider: providerMap[configChainId],
          externalTokens: externalTokenMap[configChainId],
          refreshInterval: 10000,
          gasLimitMultiplier: 1.4,
          // lordTINCPerDay: 19208 * 2, //奖励临时加倍
          zeroAddress: "0x0000000000000000000000000000000000000000",
          appUrl:
            configChainId === 56
              ? "https://app.tinyworlds.io"
              : "https://test.tiny-farm.pages.dev",
          appVersion: "1.0.11",
        };

        const DEFAULT_RPC_PROVIDER = "DEFAULT_RPC_PROVIDER_" + nowChainId;
        const firstProviderUrl = localStorage.getItem(DEFAULT_RPC_PROVIDER);
        const provider = web3ProviderFrom(
          firstProviderUrl || config.defaultProvider[0]
        );
        const getDefaultProvider = (): ethers.providers.Web3Provider => {
          return new ethers.providers.Web3Provider(provider as any, nowChainId);
        };
        const web3 = () => new Web3(provider);

        if (window.location.pathname !== "/tinykingdom") {
          setTimeout(() => testRPC(config.defaultProvider, DEFAULT_RPC_PROVIDER), 1_000);
          setTimeout(() => testRPC(config.defaultProvider, DEFAULT_RPC_PROVIDER), 15_000);
          setTimeout(() => testRPC(config.defaultProvider, DEFAULT_RPC_PROVIDER), 35_000);

          setInterval(() => {
            testRPC(config.defaultProvider, DEFAULT_RPC_PROVIDER);
          }, 60_000);
        }

        const tiny = new TinyEx({
          cfg: config,
          defaultProvider: getDefaultProvider(),
          chainId: nowChainId,
          web3: web3(),
          DEFAULT_RPC_PROVIDER,
        });

        tiny && library && tiny?.unlockWallet(library, nowAccount || undefined);

        setData({
          chainId: nowChainId,
          chain: ChainsMaps[configChainId],
          config,
          defaultProvider: getDefaultProvider(),
          web3: web3(),
          DEFAULT_RPC_PROVIDER,
          tiny: tiny,
          account: nowAccount ? nowAccount : undefined,
        });
      }
    }

    if (data) {
      if (nowAccount) {
        data.tiny && data.tiny?.unlockWallet(library, nowAccount);
        if (data.account !== nowAccount) {
          setData({
            ...data,
            account: nowAccount,
          });
        }
      }
    }
  }, [data, nowChainId, nowAccount, chainId, library]);

  return { ...data, isUnlocked };
};

const useTiny = () => {
  const {
    nowChainId: nowChainIdByContext,
    config,
    tiny,
    account,
    defaultProvider,
    isUnlocked,
    setChainId,
    web3,
    logout,
  } = useContext(Context);

  const webType = nowChainIdByContext && WEB_TYPE[nowChainIdByContext];

  const defalutGameSymbol =
    (nowChainIdByContext &&
      ChainsMaps[nowChainIdByContext].nativeCurrency?.gameSymbol) ||
    DEFALUT_SYMBOL;

  const defalutMainSymbol =
    (nowChainIdByContext &&
      ChainsMaps[nowChainIdByContext].nativeCurrency?.mainSymbol) ||
    "BNB";

  const defalutUsdSymbol =
    (nowChainIdByContext &&
      ChainsMaps[nowChainIdByContext].nativeCurrency?.usdSymBol) ||
    "BUSD";

  useEffect(() => {
    if (tiny && tiny.chainId !== nowChainIdByContext) {
      setChainId(tiny.chainId);
    }
  }, [account, nowChainIdByContext, setChainId, tiny]);

  const isMobile = useIsMobile();

  return {
    tiny,
    config,
    chainId: nowChainIdByContext,
    gameSymbol: defalutGameSymbol,
    mainSymbol: defalutMainSymbol,
    usdSymbol: defalutUsdSymbol,
    gameSymbolImg: `//images.tinyworlds.io/tokens/${defalutGameSymbol}.png`,
    // gameSymbolImg: `//images.tinyworlds.io/tokens/TINC.png`,
    mainSymbolImg: `//images.tinyworlds.io/tokens/${
      defalutMainSymbol === "BNB"
        ? defalutMainSymbol + ".png"
        : defalutMainSymbol + ".svg"
    }`,
    // mainSymbolImg: `//images.tinyworlds.io/tokens/BNB.png`,
    usdSymbolImg: `//images.tinyworlds.io/tokens/${
      defalutUsdSymbol === "BUSD"
        ? defalutUsdSymbol + ".png"
        : defalutUsdSymbol + ".svg"
    }`,
    // usdSymbolImg: `//images.tinyworlds.io/tokens/BUSD.png`,
    account,
    defaultProvider,
    isUnlocked,
    web3,
    setChainId,
    webType: nowChainIdByContext && WEB_TYPE[nowChainIdByContext],
    isBsc: webType && webType === "BNB",
    logout,
    isMobile,
    showAccount: account && shortenAddress(account, 2),
  };
};

export const ActiveEndTime = 1677139200;

export default useTiny;
