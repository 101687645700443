import React, { useEffect, useState } from "react";
import useImgsLoaded from "src/hooks/useImgsLoaded";
import {
  homeBg,
  logo,
  homeBgMobile,
  rune,
  tera,
  treeHomeM,
  treeHome,
} from "../Home/imgs";
import { ReactComponent as LoadingAvatar } from "src/images/loading-tiny.svg";
import loadingStar from "src/images/star_unselect.svg";
import { styled } from "styled-components";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

function HomeLoading() {
  const [showLoading, setShowLoading] = useState(true);

  const { loaded } = useImgsLoaded({
    imgs: [homeBg, logo, homeBgMobile, rune, tera, treeHomeM, treeHome],
  });

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;
    if (loaded) {
      id = setTimeout(() => {
        setShowLoading(false);
      }, 1000);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [loaded, setShowLoading]);

  return showLoading ? (
    <StyledContainer className={`${loaded && "animate__fadeOut"}`}>
      <StyledLoadingBox>
        <StyledLoadingAvatar width="150" height="212" />
        <StyledLoadingRotateBox>
          <StyledLoadingStar loading="eager" src={loadingStar} alt="" />
        </StyledLoadingRotateBox>
      </StyledLoadingBox>
    </StyledContainer>
  ) : (
    <></>
  );
}

export default HomeLoading;

const StyledLoadingStar = styled.img`
  position: absolute;
  top: -7.5px;
  transform: translate(-50%, 0);
  left: 50%;
  width: 15px;
  height: 15px;
`;

const StyledLoadingRotateBox = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0.5px solid #fff;
  border-radius: 50%;
  position: relative;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(1turn);
    }
  }
  animation: spin 2s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
`;

const StyledLoadingAvatar = styled(LoadingAvatar)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: -20px;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 130px;
    margin-left: -12px;
  }
`;

const StyledLoadingBox = styled.div`
  position: relative;
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 150px;
    width: 150px;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  background-color: #18212c;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  height: 100%;
  width: 100%;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  &.animate__fadeOut {
    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
    .animate__fadeOut {
      -webkit-animation-name: fadeOut;
      animation-name: fadeOut;
    }
  }
`;
