import { useCallback, useContext } from "react";
import TxConfirmModal, { txStatus } from "src/components/TxConfirmModal";
// import styled from "styled-components";

import { Context } from "src/contexts/Modals";

export default function useTxModal() {
  const { onTxModalPresent, onTxModalDismiss } = useContext(Context);

  const open = useCallback(
    (props: { status?: txStatus; message?: string; title?: string }) => {
      const { status, message, title } = props;
      onTxModalPresent(
        <TxConfirmModal
          onDismiss={onTxModalDismiss}
          status={status}
          message={message}
          title={title}
        />
      );
    },
    [onTxModalPresent, onTxModalDismiss]
  );

  const showTxModal = useCallback(
    (
      status?: txStatus,
      options?: {
        message?: string;
        title?: string;
      }
    ) => {
      if (status) {
        open({ status, message: options?.message, title: options?.title });
      } else {
        open({ status: "pending" });
      }
    },
    [open]
  );
  return showTxModal;
}
