import Web3 from "web3";
import type { HttpProvider } from "web3-core";
// import { ethers } from "ethers";

export function web3ProviderFrom(endpoint: string) {
  return new Web3.providers.HttpProvider(endpoint, {
    timeout: 12000,
  });
}

export function testRPC(
  providers: string[] | undefined,
  DEFAULT_RPC_PROVIDER?: string
) {
  let list = [];
  let finished = false;

  return new Promise<HttpProvider>((resolve) => {
    if (providers) {
      providers.forEach((url, index) => {
        let time1 = performance.now();
        const web3 = new Web3(url);
        web3.eth.getBlockNumber((error, blockNumber) => {
          if (error) {
            console.error("getBlockNumber error", error);
            // reject(error);
          } else {
            const delay = performance.now() - time1;
            list.push({
              url,
              index,
              delay,
            });
            if (finished === false) {
              finished = true;
              if (DEFAULT_RPC_PROVIDER) {
                localStorage.setItem(DEFAULT_RPC_PROVIDER, url);
              }
              const provider = web3ProviderFrom(url);
              // web3.setProvider(provider);
              resolve(provider);
            }
          }
        });
      });
    }
  });
}
