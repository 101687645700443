import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import useDebounce from "../../hooks/useDebounce";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import { updateBlockNumber } from "./actions";
import useTiny from "src/hooks/useTiny";
import toast from "react-hot-toast";
import toastConfig from "src/configs/toastConfig";
import { useTranslation } from "react-i18next";

export default function Updater(): null {
  const { chainId, library } = useWeb3React();
  const { defaultProvider } = useTiny();
  const provider = defaultProvider;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState<{
    chainId: number | undefined;
    blockNumber: number | null;
  }>({
    chainId,
    blockNumber: null,
  });

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== "number")
            return { chainId, blockNumber };
          return {
            chainId,
            blockNumber: Math.max(blockNumber, state.blockNumber),
          };
        }
        return state;
      });
    },
    [chainId, setState]
  );

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return;
    setState({ chainId, blockNumber: null });

    provider
      ?.getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) => {
        console.error(
          `Failed to get block number for chainId: ${chainId}`,
          error
        );
        toast.error(t("NetworkError"), {
          ...toastConfig,
        });
      });

    provider?.on("block", blockNumberCallback);
    return () => {
      provider?.removeListener("block", blockNumberCallback);
    };
  }, [
    dispatch,
    chainId,
    library,
    blockNumberCallback,
    windowVisible,
    provider,
    t,
  ]);

  const debouncedState = useDebounce(state, 100);

  useEffect(() => {
    if (
      !debouncedState.chainId ||
      !debouncedState.blockNumber ||
      !windowVisible
    )
      return;

    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
      })
    );
  }, [windowVisible, dispatch, debouncedState]);

  return null;
}
