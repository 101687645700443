import React, { FC } from "react";
import { BaseSvgProps } from "../types";

interface Props extends BaseSvgProps {}

const MoreIcon: FC<Props> = ({ className, style, color }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill={color}
    >
      <g transform="translate(-4089 123)">
        <g transform="translate(1996 -452)">
          <rect
            width="60"
            height="60"
            rx="10"
            transform="translate(2093 329)"
            fill="#ffefbc"
          />
        </g>
        <g transform="translate(774 -421)">
          <circle
            cx="3"
            cy="3"
            r="3"
            transform="translate(3342 325)"
            fill="#815920"
          />
          <circle
            cx="3"
            cy="3"
            r="3"
            transform="translate(3354 325)"
            fill="#815920"
          />
          <circle
            cx="3"
            cy="3"
            r="3"
            transform="translate(3330 325)"
            fill="#815920"
          />
        </g>
      </g>
    </svg>
  );
};

export default MoreIcon;
