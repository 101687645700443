import React, { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

const CardContent: React.FC<
  PropsWithChildren<{ className?: string; style?: CSSProperties }>
> = ({ children, className, style }) => (
  <StyledCardContent style={style} className={className}>
    {children}
  </StyledCardContent>
);

export const StyledCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
`;

export default CardContent;
