import { CloseCircleOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import styled from "styled-components";

interface Props {
  size?: number;
  color?: CSSProperties["color"];
  background?: CSSProperties["background"];
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  style?: CSSProperties;
}
export default function Close({
  size,
  onClick,
  color,
  background,
  style,
}: Props) {
  return (
    <StyledClose
      style={{
        ...{
          fontSize: 16,
          color: color ? color : "#959595",
        },
        ...style,
      }}
      onClick={onClick}
    />
  );
}

const StyledClose = styled(CloseCircleOutlined)`
  position: absolute;
  width: 0.833vw;
  height: 0.833vw;
  right: 0.42vw;
  top: 0.42vw;

  svg{
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 16px;
    height: 16px;
    right: 8px;
    top: 8px;
  }
`;
