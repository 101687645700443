import { useCallback } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import useTxModal from "./useTxModal";
import { logErrorToService, ErrorBody } from "src/services/util";
import { useTranslation } from "react-i18next";
import { ConnectorNames, findConnectorId } from "src/wallet";
import { CustomTxRes } from "src/types";
import { useTransactionAdder } from "src/state/transactions/hooks";
import { useAddPopup } from "src/state/application/hooks";
import useTiny from "../useTiny";
import { getTxParamCache } from "src/tiny/utils";
import { getErrorMsgKey } from "src/utils/str";

function isCancelMessage(message: string) {
  if (
    message.includes("User canceled") ||
    message.includes("Cancelled") ||
    message.includes("cancelled") ||
    message.includes("Canceled") ||
    message.includes("cancel") ||
    message.includes("Cancel") ||
    message.includes("User denied transaction") ||
    message.includes("User rejected") ||
    message.includes("Anda membatalkan") ||
    message.includes("Подписание транзакции отменено") ||
    message.includes("取消")
  ) {
    return true;
  }
}

// Không thể hoàn tất tác vụ. (Lỗi Browser.DAppBrowserError 0.)
// Operasi tidak dapat diselesaikan. (kesalahan Browser.DAppBrowserError 0.)
// The operation couldn’t be completed. (Browser.DAppBrowserError error 0.)

function isSendErrorToService(message: string) {
  if (message.includes("underlying network changed")) {
    return false;
  }
  if (message.startsWith("nonce")) {
    return false;
  }
  if (message.includes("transaction underpriced")) {
    return false;
  }
  if (message.includes("Please checkin later")) {
    return false;
  }
  if (message.includes("[ethjs-query] while formatting outputs")) {
    return false;
  }
  // if (message.includes("Browser.DAppBrowserError")) {
  //   return false;
  // }
  if (message.includes("Attempted to add existing transaction")) {
    return false;
  }
  if (isCancelMessage(message)) {
    return false;
  }
  return true;
}

function isNodeError(message: string) {
  if (message.includes("Invalid JSON RPC response")) {
    return true;
  }
  if (message.includes("MetaMask is having trouble")) {
    return true;
  }
  return false;
}

function isTxError(message: string) {
  if (message.startsWith("ETH")) {
    return true;
  }
  if (message.startsWith("BNB")) {
    return true;
  }
  if (message.startsWith("insufficient funds")) {
    return true;
  }
  if (message.includes("fee insufficient")) {
    return true;
  }
  if (message.includes("余额不足")) {
    return true;
  }
  if (message.includes("transfer amount exceeds")) {
    return true;
  }
  if (message.startsWith("Ledger device")) {
    return true;
  }
  if (message.startsWith("No keyring found for the requested account")) {
    return true;
  }
  return false;
}

// failed
// error
// Internal JSON-RPC error.

// Error: Failed to execute 'transferIn' on 'USBDevice': A transfer error has occurred.
// No keyring found for the requested account. Error info: There are keyrings, but none match the address
// Unknown address - unable to sign transaction for this address
// No transaction hash found.
// Invalid parameters: must provide an Ethereum address.
// Error processing the transaction
// JsonRpcEngine: Response has no error or result for request:
// sending a transaction requires a signer

function useHandleTransactionReceipt(): (
  promise: Promise<CustomTxRes> | undefined,
  summary: string,
  params?: string,
  isLowerCase?: boolean
) => Promise<CustomTxRes | void> | undefined {
  const { t } = useTranslation();
  const addTransaction = useTransactionAdder();
  const addPopup = useAddPopup();
  const showTxModal = useTxModal();
  const { tiny, config, chainId: nowChainId, web3 } = useTiny();
  const { account, chainId, connector } = useWeb3React();
  return useCallback(
    (
      promise: Promise<CustomTxRes> | undefined,
      summary: string,
      params?: string,
      isLowerCase?: boolean
    ) => {
      showTxModal();
      if (!promise) return;
      return promise
        .then((tx) => {
          showTxModal("confirm");
          addTransaction(tx, { summary });
          return tx;
        })
        .catch((err) => {
          console.error("err", err);

          let errorMsg = null;
          const connectorId = findConnectorId({ connector });
          if (connectorId === ConnectorNames.bsc) {
            if (err.error) {
              errorMsg = err.error;
            } else {
              errorMsg = err;
            }
            if (errorMsg === "Rejected by user") {
              return showTxModal("cancel");
            }
          }
          if (connectorId === ConnectorNames.Injected) {
            if (err.code === 4001) {
              return showTxModal("cancel");
            }
            if (err.message) {
              errorMsg = err.message;
            }
          }
          if (errorMsg === null) {
            if (err instanceof Error) {
              errorMsg = err.message;
            } else {
              errorMsg = err;
            }
          }
          console.error("errorMsg", errorMsg);
          // document.write(errorMsg)
          if (isCancelMessage(errorMsg)) {
            // User denied transaction signature on MetaMask.
            return showTxModal("cancel", { message: errorMsg });
          }

          if (errorMsg.startsWith("Returned error: ")) {
            errorMsg = errorMsg.slice(16);
          }
          if (
            errorMsg.startsWith("Transaction has been reverted by the EVM:")
          ) {
            errorMsg = "Transaction has been reverted by the EVM";
          }
          let stack = errorMsg;
          if (nowChainId !== chainId || "NETWORK_ERROR" === err.code) {
            stack = "network error";
          }
          const i18nKey = getErrorMsgKey(stack);
          let errorMessage = i18nKey ? t(i18nKey) : undefined;
          if (process.env.NODE_ENV === "development" && !errorMessage) {
            errorMessage = errorMsg;
          }
          if (isNodeError(errorMsg)) {
            // 节点问题
            showTxModal("nodeError", { message: t("txError") + errorMsg });
            return;
          } else if (isTxError(errorMsg)) {
            showTxModal("nodeError", { message: errorMsg });
            return;
          } else {
            showTxModal("error", { message: errorMessage });
          }

          const message = `${t("Unable to")} ${
            isLowerCase
              ? `${summary}`
              : `${summary[0].toLowerCase()}${summary.slice(1)}`
          }`;

          console.error(`${message}: ${stack}`);
          if (account && isSendErrorToService(stack) && config && web3) {
            const body: ErrorBody = {
              account,
              error: errorMsg,
              errType: typeof err,
              summary,
              params,
              type: "tx",
              chainId,
              code: err.code,
              connector: connectorId,
              version: config.appVersion,
            };
            const errData = err.data;
            if (errData) {
              if (typeof errData === "string" && errData.startsWith("0x")) {
                // xxx
              } else {
                body.errData = JSON.stringify(errData);
              }
            }
            if (
              stack.includes("cannot estimate gas") ||
              stack.includes("invalid BigNumber value") ||
              stack.includes("Internal JSON-RPC error") ||
              stack.includes("DAppBrowserError") ||
              stack.includes("JSON Parse error") ||
              stack.includes("Failed to fetch") ||
              stack.includes("missing from address") ||
              stack.includes("Unexpected token") ||
              stack === "error" ||
              stack === "failed"
            ) {
              if (web3.currentProvider instanceof Web3.providers.HttpProvider) {
                body.providerHost = web3.currentProvider.host;
              }
              body.txParam = JSON.stringify(getTxParamCache());
            }
            logErrorToService(body);
          }
          addPopup({ error: { message, stack: stack } });
        });
    },
    [
      showTxModal,
      addTransaction,
      chainId,
      connector,
      config,
      nowChainId,
      t,
      account,
      web3,
      addPopup,
    ]
  );
}

export default useHandleTransactionReceipt;
