import useHandleTransactionReceipt from "./useHandleTransactionReceipt";
import useHandleTxConfirm from "./useHandleTxConfirm";

function useTx() {
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();
  
  return {
    handleTransactionReceipt,
    handleTxConfirm,
  };
}

export default useTx;
