import React, { useMemo } from "react";
import useTiny from "src/hooks/useTiny";
import { whileUsers } from "./WhiteUser";
import { tokenFloor } from "src/utils/str";
import useWhiteUser from "./useWhiteUser";

// const whileUsers = [
//   "0x19dBf7B58674594b7A0043bBDcED6E7C4fC59818",
// ] as const;

function useWhiteUserAmount() {
  const { account } = useTiny();
  const whiteUser = useWhiteUser();

  const whiteUserAmount = useMemo(() => {
    if (whiteUser && account) {
      let userValue = 0;
      Object.keys(whileUsers).map((key) => {
        if (account?.toLowerCase() === key.toLowerCase()) {
          userValue = tokenFloor(
            whileUsers[key as keyof typeof whileUsers]
          );
        }
      });

      return userValue;
    }
    return 0;
  }, [account, whiteUser]);

  // console.log({
  //   whiteUserAmount,
  //   whileUsers,
  //   account,
  // });

  return whiteUserAmount;
}

export default useWhiteUserAmount;
