import React, { useState } from "react";
import { styled } from "styled-components";
import Faq from "./Faq";
import Claim from "./Claim";

function Airdrop() {
  return (
    <StyledContainer>
      <Claim />
      <Faq />
    </StyledContainer>
  );
}

export default Airdrop;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;
