import { css } from "styled-components";

export const MEDIA_WIDTHS = {
  upToXXXS: 320,
  upToXXS: 370,
  upToXS: 400,
  // upToExtraSmall: 500,
  upToExtraSmall: 600,
  upToSmall: 600,
  upToMedium: 960,
  upToMediumLarge: 1150,
  upToLarge: 1280,
  upToLargeBp: 1480,
  upToExtraLarge: 1550,
  upToMediumXs: 820,

  windowMD: 1136,
  windowSM: 768,
  windowXS: 500,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const theme = {
  // media queries
  mediaWidth: mediaWidthTemplates,
};

export default theme;
