import React from "react";
import {
  BrowserRouter,
  RouteObject,
  createBrowserRouter,
} from "react-router-dom";

import Home from "src/views/Home";

function useRouter() {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Home></Home>,
    },
    // {
    //   path: "about",
    //   element: <div>About</div>,
    // },
  ];

  return routes;
}

export default useRouter;
