import React, { useCallback } from "react";
import styled from "styled-components";
import { AutoColumn } from "./Column";
import { AutoRow } from "./Row";
import { popups_close } from "./imgs";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function ErrorPopup({
  message,
  stack,
}: {
  message: string;
  stack: string;
}) {
  const copyErrorDetails = useCallback(async () => {
    await navigator.clipboard.writeText(`${message}\n${stack}`);
  }, [message, stack]);

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <img src={popups_close} alt="" />
      </div>
      <AutoColumn gap="8px">
        <StyledPopupDesc>{message}</StyledPopupDesc>
        <StyledLink onClick={copyErrorDetails} href="#">
          Copy error details
        </StyledLink>
      </AutoColumn>
    </RowNoFlex>
  );
}

const StyledPopupDesc = styled.span`
  font-weight: 500;
  color: #eaeaea;
`;

const StyledLink = styled.a`
  color: #9a9a9a;
`;
