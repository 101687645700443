import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";

import useTiny from "src/hooks/useTiny";
import { scanUrls } from "src/config";
import { AutoRow } from "./Row";
import { AutoColumn } from "./Column";
import { popups_close, popups_check } from "./imgs";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const { t } = useTranslation();
  const { chainId } = useTiny();

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <img src={popups_check} alt="" />
        ) : (
          // <AlertCircle color="#FF6871" size={24} />
          <img src={popups_close} alt="" />
        )}
      </div>
      <AutoColumn gap="8px">
        <StyledPopupDesc>
          {summary ?? "Hash: " + hash.slice(0, 8) + "..." + hash.slice(58, 65)}
        </StyledPopupDesc>
        {chainId && (
          <StyledLink href={`${scanUrls[chainId]}${hash}`} target="_blank">
            {chainId === 56 || chainId === 97
              ? t("View on BscScan")
              : t("View on zkSync")}
          </StyledLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}

const StyledPopupDesc = styled.span`
  font-weight: 500;
  color: #eaeaea;
`;

const StyledLink = styled.a`
  color: #9a9a9a;
`;
