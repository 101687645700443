import React, { CSSProperties } from "react";
import WalletButton from "./WalletButton";
import { Chains } from "src/config";
import { useWeb3React } from "@web3-react/core";
import { styled } from "styled-components";
import { airdropDate } from "./Activitys/Airdrop/hooks/WhiteUser";
import useActivityNotStart from "src/hooks/useActivityNotStart";

interface Props {
  chaiId?: Chains;
  style?: CSSProperties;
}

function TinyLoginButton({ chaiId, style }: Props) {
  return (
    <StyledWalletButton
      style={style}
      selectChaiId={chaiId}
    ></StyledWalletButton>
  );
}

export default TinyLoginButton;

const StyledWalletButton = styled(WalletButton)``;
