import React, { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import di_2 from "src/images/di_2.png";
import di_3 from "src/images/di_3.png";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface Props extends HTMLAttributes<HTMLDivElement> {
  bgType?: 1 | 2;
}

const Card: React.FC<PropsWithChildren<Props>> = ({
  children,
  bgType = 1,
  ...rest
}) => (
  <StyledCard {...rest} bgType={bgType}>
    {children}
  </StyledCard>
);

const StyledCard = styled.div<{ bgType: 1 | 2 }>`
  position: relative;
  border-radius: 0.312vw;

  background: #2c2b2e;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    border-radius: 6px;
  }
`;

export default Card;
