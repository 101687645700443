import { useMemo } from "react";
import { styled } from "styled-components";
import { useTimerSecond } from "src/hooks/useTime";
import { toDDHHMM } from "src/utils";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";
import useBatchNumber from "src/hooks/useBatchNumber";

interface Props {
  isZkSyncTime: boolean | undefined;
  countDown: number | undefined;
}

export default function ActivityCountDown({ isZkSyncTime, countDown }: Props) {
  const { data: zkSyncDate, refetch: zkSyncDateRefetch } = useBatchNumber({
    open: isZkSyncTime,
  });

  const restSeconds = useTimerSecond({
    time: countDown,
    zkSyncNowDate: isZkSyncTime ? zkSyncDate : undefined,
  });

  const secondsItems = useMemo(() => {
    const arr = toDDHHMM({
      secs:
        typeof restSeconds === "number" && restSeconds >= 0 ? restSeconds : 0,
      isArr: true,
    });
    return arr as string[];
  }, [restSeconds]);

  return (
    <StyledContainer>
      <StyledContainerBox>
        {secondsItems.map((value, index) => {
          return (
            <StyledTimeBox key={index}>
              <Box>
                <StyledAnimationBox>{value}</StyledAnimationBox>
              </Box>
              {index % 2 === 1 && index !== secondsItems.length - 1 && (
                <StyledBoxColon>:</StyledBoxColon>
              )}
            </StyledTimeBox>
          );
        })}
      </StyledContainerBox>
      <StyledContainerDesc>
        <span>Days</span>
        <span>Hours</span>
        <span>Minutes</span>
      </StyledContainerDesc>
    </StyledContainer>
  );
}

const StyledTimeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainerDesc = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: calc(2.92vw * 2 + 0.416vw);
    text-align: center;
    ${FontSizeConfig.lx}
    color: #FFFFFF;
    margin-top: 0.31vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    span + span {
      margin-left: 6px;
    }

    span {
      width: calc(40px * 2 + 8px);
      ${FontSizeConfig.l}
    }
  }
`;

const StyledContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.42vw;
  font-size: 1.67vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    gap: 8px;
    font-size: 23px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledBoxColon = styled.span`
  color: #ffffff;
  margin-left: 0.42vw;
  margin-right: 0.42vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const Box = styled.div`
  position: relative;
  height: 4.165vw;
  width: 2.92vw;
  color: #ffffff;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    height: 56px;
    width: 40px;
  }
`;

const StyledAnimationBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  background-color: #171717;
  border-radius: 0.625vw;
  font-size: 1.67vw;

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    font-size: 23px;
    border-radius: 12px;
  }
`;

// const BackBox = styled(animated.div)`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   inset: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   backface-visibility: hidden;
//   background-color: #171717;
//   border-radius: 0.75rem;
// `;
