import { Chains, Configuration } from "src/config";
import { BscAbiConfig, getBscContractInfo } from "./Abis_Bsc";
import { getZkSyncContractInfo } from "./Abis_zkSync";
// import { ZkSyncAbiConfig, getZkSyncContractInfo } from "./Abis_zkSync";

export const getContractInfoMap = (chainId: Chains) => {
  let contractInfoMap: {
    [key: string]: {
      abi: any;
      address: string;
    };
  } = {};

  /* 
    各个链的合约来控制页面的展示
  */

  if (chainId === 97) {
    contractInfoMap = {
      ...getBscContractInfo(chainId),
    };

    return contractInfoMap;
  }

  if (chainId === 56) {
    contractInfoMap = {
      ...getBscContractInfo(chainId),
    };

    return contractInfoMap;
  }

  if (chainId === 280) {
    contractInfoMap = {
      ...getZkSyncContractInfo(chainId),
    };

    return contractInfoMap;
  }

  if (chainId === 324) {
    contractInfoMap = {
      ...getZkSyncContractInfo(chainId),
    };

    return contractInfoMap;
  }

  return contractInfoMap;
};
