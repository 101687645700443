import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { styled } from "styled-components";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface Props extends TabsProps {}

/* 
defaultActiveKey="1" items={items} onChange={onChange}
*/

export const TinyTabs: React.FC<Props> = (props) => <StyledTabs {...props} />;

const StyledTabs = styled(Tabs)`
  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 0.42vw 0;
  }

  &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  }

  &.ant-tabs .ant-tabs-tab {
    margin: 0.42vw;
    padding: 0;
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    background: rgb(0 0 0 / 40%);
    color: #ffffff;
    border-radius: 0.312vw;
  }

  &.ant-tabs > .ant-tabs-nav::before {
    border-bottom: 0;
  }

  &.ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: unset;
    ${FontSizeConfig.l}
    padding: 0.312vw;
    border-radius: 0.312vw;

    span {
      line-height: 0.833vw;
      ${FontSizeConfig.l}
      color: #FFFFFF;
    }

    label {
      line-height: 0.728vw;
      ${FontSizeConfig.m}
      color: #959595;
      cursor: pointer;
    }
  }

  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentcolor;

    span {
      color: #febe41 !important;
    }

    label {
      color: #febe41 !important;
    }
  }

  &.ant-tabs .ant-tabs-tab:hover {
    color: #febe41;
  }

  &.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #febe41;
    pointer-events: none;
  }

  &.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: none;
    pointer-events: none;
    bottom: 0.42vw;
    height: calc(100% - 0.833vw);
    border: 1px solid #febe41;
    border-radius: 0.312vw;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      background: none;
    }

    &.ant-tabs-top > .ant-tabs-nav {
      margin: 0 0 8px 0;
    }

    &.ant-tabs .ant-tabs-tab {
      margin: 8px;
      padding: 0;
    }

    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      border-radius: 6px;
    }

    &.ant-tabs .ant-tabs-tab-btn {
      padding: 6px;
      border-radius: 6px;

      span {
        line-height: 16px;
      }

      label {
        line-height: 14px;
      }
    }

    &.ant-tabs .ant-tabs-ink-bar {
      bottom: 8px;
      height: calc(100% - 16px);
      border: 1px solid #febe41;
      border-radius: 6px;
    }
  }
`;
