import clsx from "clsx";
import React, { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import SpecialFontText from "../SpecialFontText";
import { FontSizeConfig } from "src/configs/fontSizeConfig";
import { MEDIA_WIDTHS } from "src/configs/styledConfig";

interface ModalTitleProps {
  className?: string;
  text?: ReactNode;
  titleSize?: "sm" | "lg";
  style?: CSSProperties;
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  text,
  className,
  titleSize,
  style,
}) => {
  switch (titleSize) {
    case "lg":
      return (
        <StyledModalTitle
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitle>
      );
    case "sm":
      return (
        <StyledModalTitleSm
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitleSm>
      );
    default:
      return (
        <StyledModalTitle
          style={style}
          className={clsx(className, "special-ff")}
        >
          {text}
        </StyledModalTitle>
      );
  }
};

const StyledModalTitle = styled(SpecialFontText)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  margin-top: 0.625vw;
  margin-bottom: 0.833vw;
  ${FontSizeConfig.lx}

  @media (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
`;

const StyledModalTitleSm = styled(StyledModalTitle)`
  ${FontSizeConfig.l}
`;

export default ModalTitle;
