// import { detect } from "detect-browser";
// import { logErrorToService } from "src/services/util";

import { ChainsMaps, providerMap,Chains } from "src/config";
import useTiny from "src/hooks/useTiny";

// const browser = detect();

function isNeedAdd(error: any) {
  if (!error) {
    return;
  }
  const code: number = error.code;
  const message: string = error.message;
  if (code === 4902) {
    return true;
  }
  if (message && message.includes("Unrecognized chain ID")) {
    return true;
  }
}

function parseError(switchError: any) {
  const isNeed = isNeedAdd(switchError);
  if (isNeed) {
    return true;
  }
  const data: any = switchError.data;
  // firefox metamask
  if (data && data.originalError) {
    const isNeed = isNeedAdd(data.originalError);
    if (isNeed) {
      return true;
    }
  }
}

export default async function switchChain(
  connector: "Injected" | "WalletLink" | "okxwallet",
  chainId: Chains,
  callback?: () => void
) {
  const ethereum =
    connector === "Injected" || "WalletLink"
      ? window.ethereum
      : window.okxwallet;

  const chainIdToHax = "0x" + chainId.toString(16);

  if (ethereum) {
    let provider = ethereum;

    if (ethereum.providers?.length) {
      ethereum.providers.forEach(async (p) => {
        if (connector === "Injected" && p.isMetaMask) {
          provider = p;
        } else if (connector === "WalletLink" && p.isWalletLink) {
          provider = p;
        }
      });
    }
    try {
      // console.log("start wallet_switchEthereumChain");
      const response = await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdToHax }],
      });
      callback && callback();
      // console.log("wallet_switchEthereumChain response:", response);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (parseError(switchError as any)) {
        try {
          const response = await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainIdToHax,
                chainName: ChainsMaps[chainId].name,
                rpcUrls: providerMap[chainId as 56 | 97],
              },
            ],
          });
          callback && callback();
          // console.log("wallet_addEthereumChain response:", response);
        } catch (addError: any) {
          // handle "add" error
          console.error("addError:", addError);
          // const message =
          //   addError && addError.message ? addError.message : addError;
          // if (
          //   message.includes("already pending for") ||
          //   message.includes("User rejected")
          // ) {
          //   return;
          // }
          // logErrorToService({
          //   account: ethereum.selectedAddress,
          //   error: "wallet_addEthereumChain error:" + message,
          //   type: "tx",
          //   os: browser?.os,
          //   browsername: browser?.name,
          //   deviceType: browser?.type,
          // });
        }
      }
      // handle other "switch" errors
    }
  }
}
